import React from "react";
import Control from "./Controls/Control";

const DynamicList = ({
  label,
  uniqueKey,
  name,
  fields,
  value,
  btnLabel,
  change,
  error,
  dynamicTypes = false,
  labelClassname = "",
}) => {
  function addItem() {
    var newItem = {};
    fields.forEach((f) => {
      newItem[f.name] = "";
    });
    value.push(newItem);
    change({ target: { name: name, value: value } });
  }

  function changeItem(e) {
    value[e.target.k][e.target.name] = e.target.value;
    change({ target: { name: name, value: value } });
  }
  function removeItem(kk) {
    value = value.filter((i, k) => k !== kk);
    change({ target: { name: name, value: value } });
  }

  return (
    <div className="form-group">
      <label className={labelClassname}>
        <span className="label-text">{label}</span>
        <ul
          className="list-group list-unstyled"
          style={{ marginLeft: -30, marginRight: -30 }}
        >
          {value.map((it, k) => (
            <li key={"dyn" + name + uniqueKey + "_" + k}>
              <div
                className={
                  "d-flex flex-wrap align-items-center bg-default " +
                  (fields.length == 1 ? "" : "mb-3")
                }
              >
                {fields.map((fi, kk) => (
                  <Control
                    className={"mr-0 flex-fill"}
                    labelClassname="mb-0 mt-0 mr-2 text-black-50"
                    inputStyle={
                      kk == fields.length - 1
                        ? {
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                          }
                        : {}
                    }
                    k={k}
                    key={"dynlistinp" + kk}
                    label={fi.label}
                    name={fi.name}
                    type={
                      fi.isDynamic
                        ? dynamicTypes.find((d) => d.id == it.id)
                          ? dynamicTypes.find((d) => d.id == it.id).type
                          : fi.type
                        : fi.type
                    }
                    value={it[fi.name]}
                    change={changeItem}
                    id={fi.id ? fi.id : null}
                    suffix={fi.suffix ? fi.suffix : null}
                    datas={
                      fi.isDynamic
                        ? dynamicTypes.find((d) => d.id == it.id)
                          ? dynamicTypes.find((d) => d.id == it.id).datas
                          : fi.datas
                        : fi.datas
                    }
                    margin={fi.margin ? fi.margin : ""}
                    dataIndex={fi.dataIndex ? fi.dataIndex : "id"}
                    dataLabel={fi.dataLabel ? fi.dataLabel : "name"}
                    dataLabel2={fi.dataLabel2 ? fi.dataLabel2 : false}
                    autoCompleteDisplayIndex={
                      fi.autoCompleteDisplayIndex
                        ? fi.autoCompleteDisplayIndex
                        : false
                    }
                    autocomplete={fi.autocomplete}
                    error={error[name] ? error[name][k] : {}}
                  />
                ))}
                <div
                  style={{
                    height: 80,
                    borderTopRightRadius: window.borderRadius,
                    borderBottomRightRadius: window.borderRadius,
                  }}
                  className="d-flex align-items-center"
                >
                  <button
                    className="btn btn-danger btn-sm shadow-none h-100 px-3 rounded-0"
                    onClick={() => removeItem(k)}
                  >
                    <i className="fa fa-times" />
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </label>
      {btnLabel && (
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-default btn-sm "
            type="button"
            onClick={addItem}
          >
            <i className="fa fa-plus mr-2" />
            {btnLabel}
          </button>
        </div>
      )}
      {/* {error && (
        <small
          className={
            "form-text text-danger " + (error[name] ? "animated flash" : "")
          }
        >
          {error[name]}
        </small>
      )} */}
    </div>
  );
};

export default DynamicList;
