import React, { useState, useEffect } from "react"
import Page from "../../common/layout/Page"
import Control from "../../common/Controls/Control"
import useStore from "../../../context/useStore"
import Loader from "../../common/Loader"
import { DateTime } from "luxon"
import Axios from "axios"
import { API_URL } from "../../../config"
import PostalCode from "../../common/PostalCode"
import SaveBtn from "../../common/SaveBtn"
import { toast } from "react-toastify"
import dataService from "../../../helpers/dataService"
import ProspectorQualification from "./ProspectorQualification/ProspectorQualification"
import ProspectorForm from "./ProspectorForm/ProspectorForm"
import ProspectorMissions from "./ProspectorMissions/ProspectorMissions"
import ProspectorPayback from "./PropspectorPayback/ProspectorPayback"

const UserDetail = props => {
  const [state, dispatch] = useStore()

  const [user, setUser] = useState({
    pseudo: "",
    email: "",
    isActivated: false,
    isCGUAccepted: false,
    isFormation: false,
    isNewsletter: false,
    gender: 1,
    firstname: "",
    lastname: "",
    birthdate: new Date(),
    address: "",
    postalCode: "",
    cityId: "",
    phone: "",
    iban: "",
    rank: 0,
    balance: 0,
    rankConditions: {},
  })
  const [errors, setErrors] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingPwd, setIsLoadingPwd] = useState(false)
  const [modifyPassword, setModifyPassword] = useState(false)
  const [isResendingActivation, setIsResendingActivation] = useState(false)
  const itemId = props.prospectorId || props.match.params.id
  const [missions, setMissions] = useState([])
  const [payback, setPayback] = useState([])
  const constants = state.constants.items

  useEffect(() => {
    if (itemId != "create") {
      getUser()
    }

    dataService.get(`prospectors/${itemId}/missions`, setMissions, err =>
      console.log("Error:", err)
    )

    dataService.get(`prospectors/${itemId}/payments`, setPayback, err =>
      console.log("Error:", err)
    )
  }, [])

  const getUser = () => {
    dataService.get(`prospectors/${itemId}`, setUser, setErrors)
  }

  function updateData(e) {
    console.log(e.target.name, e.target.value)
    var value = e.target.value
    if (e.target.type == "checkbox") {
      if (e.target.checked) {
        value = true
      } else {
        value = false
      }
    }
    console.log(value)
    setUser({ ...user, [e.target.name]: value })
  }

  function save() {
    setIsLoading(true)
    setErrors({})
    if (itemId == "create") {
      //create
      Axios.post(API_URL + "prospectors", user)
        .then(res => {
          setUser(res.data)
          toast.success("Prospecteur créé avec succès")

          window.location.href = "/prospectors/" + res.data.id
        })
        .catch(err => {
          toast.error("Une erreur est survenue")
          if (err.response && err.response.data) {
            setErrors(err.response.data)
          }
        })
        .then(() => setIsLoading(false))
    } else {
      //update
      Axios.patch(API_URL + "prospectors/" + itemId, user)
        .then(res => {
          setUser(res.data)
          toast.success("Prospecteur modifié avec succès")
        })
        .catch(err => {
          toast.error("Une erreur est survenue")
          if (err.response && err.response.data) {
            setErrors(err.response.data)
          }
        })
        .then(() => setIsLoading(false))
    }
  }

  function savePassword() {
    setIsLoadingPwd(true)
    Axios.patch(API_URL + "prospectors/" + itemId + "/password", {
      password: user.password,
    })
      .then(res => {
        toast.success("Le mot de passe a bien été mis à jour")
      })
      .catch(err => {
        if (err.response && err.response.data) {
          setErrors(err.response.data)
        }
        toast.error("Une erreur est survenue")
      })
      .then(() => {
        setIsLoadingPwd(false)
      })
  }

  function resendActivation() {
    setIsResendingActivation(true)
    dataService.get(
      `prospectors/${itemId}/renew-activation`,
      datas => toast.success("L'email d'activation a bien été envoyé"),
      err => {},
      () => setIsResendingActivation(false)
    )
  }

  return (
    <Page
      container='container'
      title='Détail agent'
      errors={errors}
      back={!props.prospectorId && "/prospectors"}>
      <div className='row'>
        <ul className='nav nav-tabs mb-4'>
          <li className='nav-item'>
            <a
              className='nav-link active'
              id='profile-tab'
              data-toggle='tab'
              href='#profile'
              role='tab'
              aria-controls='home'
              aria-selected='true'>
              Profil
            </a>
          </li>
          <li className='nav-item'>
            <a
              className='nav-link'
              id='qualification-tab'
              data-toggle='tab'
              href='#qualification'
              role='tab'
              aria-controls='home'
              aria-selected='false'>
              Qualification
            </a>
          </li>
          <li className='nav-item'>
            <a
              className='nav-link'
              id='missions-tab'
              data-toggle='tab'
              href='#missions'
              role='tab'
              aria-controls='home'
              aria-selected='false'>
              Missions
            </a>
          </li>
          <li className='nav-item'>
            <a
              className='nav-link'
              id='payback-tab'
              data-toggle='tab'
              href='#payback'
              role='tab'
              aria-controls='home'
              aria-selected='false'>
              Rémunération
            </a>
          </li>
          <li className='nav-item'>
            <a
              className='nav-link'
              id='fidelity-tab'
              data-toggle='tab'
              href='#fidelity'
              role='tab'
              aria-controls='home'
              aria-selected='false'>
              Fidélité
            </a>
          </li>
        </ul>
      </div>{" "}
      <div className='tab-content' id='myTabContent'>
        <div
          className='tab-pane fade show active'
          id='profile'
          role='tabpanel'
          aria-labelledby='profile-tab'>
          <ProspectorForm
            user={user}
            setUser={setUser}
            updateData={updateData}
            isLoading={isLoading}
            isLoadingPwd={isLoadingPwd}
            save={save}
            savePassword={savePassword}
            isResendingActivation={isResendingActivation}
            modifyPassword={modifyPassword}
            setModifyPassword={setModifyPassword}
            itemId={itemId}
            resendActivation={resendActivation}
          />
        </div>
        <div
          className='tab-pane fade'
          id='qualification'
          role='tabpanel'
          aria-labelledby='qualification-tab'>
          <ProspectorQualification user={user} getUser={getUser} />
        </div>
        <div
          className='tab-pane fade'
          id='missions'
          role='tabpanel'
          aria-labelledby='missions-tab'>
          <ProspectorMissions missions={missions} constants={constants} />
        </div>
        <div
          className='tab-pane fade'
          id='payback'
          role='tabpanel'
          aria-labelledby='payback-tab'>
          <ProspectorPayback payback={payback} constants={constants} />
        </div>
        <div
          className='tab-pane fade'
          id='fidelity'
          role='tabpanel'
          aria-labelledby='fidelity-tab'>
          ...
        </div>
      </div>
    </Page>
  )
}

export default UserDetail
