import fileDownload from "js-file-download";
import React from "react";
import useStore from "../../../../../context/useStore";
import Control from "../../../../common/Controls/Control";

const FilterItem = ({ filter, missionFilters, updateFilters, error }) => {
  const [state, dispatch] = useStore();
  const conditions = state.constants.items.MISSION_FILTER_CONDITIONS;

  const toggleFilter = (isActivated) => {
    if (isActivated) {
      updateFilters([
        ...missionFilters,
        {
          attribute: filter.attribute,
          condition: "",
          values: [],
        },
      ]);
    } else {
      updateFilters([
        ...missionFilters.filter((fi) => fi.attribute !== filter.attribute),
      ]);
    }
  };

  const updateCondition = (e) => {
    const fil = [...missionFilters];
    fil.find((f) => f.attribute == filter.attribute).condition = e.target.value;
    updateFilters(fil);
  };

  const updateValue = (e) => {
    const fil = [...missionFilters];
    fil.find((f) => f.attribute == filter.attribute).values = e.target.value;
    updateFilters(fil);
  };

  const missionFilter = missionFilters.find(
    (fil) => fil.attribute == filter.attribute
  );
  const isActive = missionFilter;

  return (
    <li className="list-group-item">
      <strong>{filter.name}</strong>

      <div className="row">
        <div className="col-12 col-md-6">
          <Control
            label="Filtre activé ?"
            type="checkbox"
            name={`isactive${filter.name}`}
            checked={isActive ? true : false}
            change={(e) => toggleFilter(!isActive)}
          />
        </div>
        {missionFilter && (
          <>
            <div className="col-12 col-md-6">
              <Control
                label="Condition"
                type="select"
                name={`condition`}
                value={missionFilter.condition}
                datas={conditions}
                change={updateCondition}
              />
            </div>
            <div className="col-12 col-md-6 offset-md-6">
              {missionFilter.condition && (
                <Control
                  label="Valeurs"
                  type="checkBtnList"
                  value={missionFilter.values}
                  datas={filter.values}
                  change={updateValue}
                  btnInline
                  dataIndex="id"
                  dataLabel="name"
                  name={`val${filter.name}`}
                />
              )}
            </div>
          </>
        )}
        {error && <div className="col-12 alert alert-danger mb-0">{error}</div>}
      </div>
    </li>
  );
};

export default FilterItem;
