import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const ParticipationChart = ({ participations, totalExpected }) => {
  const { finished, progress } = participations;

  const [state, setState] = useState({
    series: [finished, progress, totalExpected - (finished + progress)],
    options: {
      labels: ["Terminées", "En cours", "Restant"],
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return Math.round(val * 100) / 100 + "%";
        },
      },
      responsive: [
        {
          breakpoint: 6000,
          options: {
            legend: {
              position: "bottom",
              horizontalAlign: "center",
            },
          },
        },
      ],
    },
  });

  return (
    <ReactApexChart
      options={state.options}
      series={state.series}
      type="donut"
      height={200}
    />
  );
};

export default ParticipationChart;
