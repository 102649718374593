import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import { Link, Redirect } from "react-router-dom";
import useStore from "../../../context/useStore";
import Control from "../../common/Controls/Control";
import AnswerList from "../../common/AnswerList";
import Axios from "axios";
import { API_URL } from "../../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SaveBtn from "../../common/SaveBtn";
import QuestionImagesUploader from "./QuestionImagesUploader";
import SubThemeSelectInput from "./SubThemeSelectInput";
import QuestionPreview from "./QuestionPreview";

var questionInitialState = {
  subTheme: "",
  type: "",
  question: {},
  description: {},
  answers: [],
  inputParams: {},
  coef: 0,
  isScoring: false,
  image: null,
  isGeneric: true,
};

const QuestionDetail = (props) => {
  const [state, dispatch] = useStore();
  const languages = state.constants.items.LANGUAGES;
  const themes = state.constants.items.THEMES;
  const inputTypes = state.constants.items.INPUT_TYPES;
  const [langActive, setLangActive] = useState("FR");
  const [userInput, setUserInput] = useState(false);
  const [question, setQuestion] = useState(questionInitialState);
  const [isCreated, setIsCreated] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const itemId = props.missionId
    ? props.missionId
    : props.match.params.id == "create"
    ? props.match.params.id
    : false;

  useEffect(() => {
    setQuestion(questionInitialState);
  }, [props.randomReset]);

  useEffect(() => {
    if (!props.isModal && props.match.params.id != "create") {
      Axios.get(API_URL + "questions/" + props.match.params.id)
        .then((res) => {
          setQuestion(res.data);
        })
        .catch((err) => {
          toast.error("Erreur lors de la récupération des données");
          if (err.response && err.response.data) {
            toast.error(err.response.data.other);
          }
        });
    }
  }, []);

  useEffect(() => {
    if (languages) {
      var q = JSON.parse(JSON.stringify(question));
      languages.forEach((lang) => {
        q.question[lang] = "";
        q.description[lang] = "";
      });
      setQuestion(q);
    }
  }, [languages]);

  useEffect(() => {
    if (userInput) {
      var q = JSON.parse(JSON.stringify(question));
      q.answers = [];
      q.inputParams = {};
      if (q.type == "6") {
        q.inputParams = {
          min: 0,
          max: 100,
          step: 1,
        };
      }
      if (q.type == "7") {
        q.inputParams = {
          count: 1,
        };
      }
      setQuestion(q);
    }
  }, [question.type]);

  function addAnswer() {
    var q = JSON.parse(JSON.stringify(question));
    var labels = {};
    languages.forEach((l) => (labels[l] = ""));
    q.answers.push({
      _id: Date.now(),
      value: q.answers.length,
      label: labels,
      score: "3",
    });
    setQuestion(q);
  }
  function removeAnswer(id) {
    var q = JSON.parse(JSON.stringify(question));

    q.answers = q.answers.filter((a) => a._id != id);
    setQuestion(q);
  }
  function updateDataAnswerValue(e, k) {
    var q = JSON.parse(JSON.stringify(question));
    q.answers[e.target.k].value = e.target.value;
    setQuestion(q);
  }
  function updateDataAnswerScore(e) {
    var q = JSON.parse(JSON.stringify(question));
    q.answers[e.target.k].score = e.target.value;
    setQuestion(q);
  }
  function updateDataAnswerLabel(e, k, lang) {
    var q = JSON.parse(JSON.stringify(question));

    q.answers[k].label[lang] = e.target.value;
    setQuestion(q);
  }
  function updateInputParams(e, k) {
    var q = JSON.parse(JSON.stringify(question));
    q.inputParams[k] = e.target.value;
    setQuestion(q);
  }
  function updateData(e) {
    var q = JSON.parse(JSON.stringify(question));
    var value = e.target.value;
    if (e.target.type == "checkbox") {
      if (e.target.checked) {
        value = true;
      } else {
        value = false;
      }
    }
    q[e.target.name] = value;
    setUserInput(true);
    setQuestion(q);
  }
  function updateDataLang(e) {
    var q = JSON.parse(JSON.stringify(question));
    var value = e.target.value;
    if (e.target.type == "checkbox") {
      if (e.target.checked) {
        value = true;
      } else {
        value = false;
      }
    }
    q[e.target.name][langActive] = value;
    setQuestion(q);
  }

  function save() {
    setIsLoading(true);

    if (props.isModal || props.match.params.id == "create") {
      Axios.post(API_URL + "questions/", question)
        .then((res) => {
          toast.success("La question a bien été créée");
          setIsCreated(res.data.id);
          if (props.isModal) {
            props.setNewQuestion(res.data);
            setQuestion(questionInitialState);
          } else {
            window.location.href = "/questions/" + res.data.id;
          }
        })
        .catch((err) => {
          toast.error("Une erreur est survenue");
          if (err.response && err.response.data) {
            setErrors(err.response.data);
          }
        })
        .then(() => setIsLoading(false));
    } else {
      Axios.patch(API_URL + "questions/" + props.match.params.id, question)
        .then((res) => {
          toast.success("La question a bien été mise à jour");
        })
        .catch((err) => {
          toast.error("Une erreur est survenue");

          if (err.response && err.response.data) {
            setErrors(err.response.data);
          }
        })
        .then(() => setIsLoading(false));
    }
  }

  const presetAnswer = (type) => {
    const ans = [];

    switch (type) {
      case "yesno":
        var labelsYes = {};
        var labelsNo = {};
        languages.forEach((l) => {
          labelsYes[l] = "Oui";
          labelsNo[l] = "Non";
        });
        ans.push(
          {
            _id: Date.now(),
            value: 0,
            label: labelsNo,
            score: "3",
          },
          {
            _id: parseInt(Date.now()) + 1,
            value: 1,
            label: labelsYes,
            score: "3",
          }
        );
        break;
    }

    setQuestion({ ...question, answers: ans });
  };

  return (
    <Page
      container="container"
      title={!props.isModal ? "Question" : false}
      back={!props.isModal ? "/questions" : false}
    >
      {isCreated && !props.isModal && (
        <Redirect to={"/questions/" + isCreated} />
      )}
      {languages && themes && inputTypes && (
        <>
          <ul className="nav nav-tabs mb-3">
            {languages.map((lang, k) => (
              <li className="nav-item" key={lang + k}>
                <a
                  onClick={() => setLangActive(lang)}
                  className={"nav-link " + (k == 0 ? "active" : "")}
                  id={lang + "btn"}
                  data-toggle="tab"
                  href={"#" + lang + "tab"}
                  role="tab"
                  aria-controls={lang + "tab"}
                  aria-selected="false"
                >
                  {lang}
                </a>
              </li>
            ))}
          </ul>
          <div
            className="tab-content question-detail-container"
            id="myTabContent"
          >
            {languages.map((lang, k) => (
              <div
                key={"qcontent" + k + "" + lang}
                className={"tab-pane fade " + (k == 0 ? "active show" : "")}
                id={lang + "tab"}
                role="tabpanel"
                aria-labelledby={lang + "btn"}
              >
                <Control
                  type="text"
                  label="Question"
                  name="question"
                  id={"question" + lang}
                  change={updateDataLang}
                  value={question.question[lang] || ""}
                  type="text"
                  error={errors}
                />
                <Control
                  type="text"
                  label="Description"
                  name="description"
                  id={"description" + lang}
                  change={updateDataLang}
                  value={question.description[lang] || ""}
                  type="text"
                  error={errors}
                />
                <Control
                  type="checkbox"
                  label="Cette question est générique ?"
                  name="isGeneric"
                  id={"isGeneric" + lang}
                  change={updateData}
                  checked={question.isGeneric}
                  error={errors}
                />
              </div>
            ))}
          </div>

          <SubThemeSelectInput
            updateData={updateData}
            value={question.subTheme}
            name="subTheme"
            errors={errors}
          />
          <Control
            label="La question compte pour la note globale du constat"
            type="checkbox"
            checked={question.isScoring}
            name="isScoring"
            change={(e) =>
              setQuestion({ ...question, isScoring: e.target.checked })
            }
            error={errors}
            setError={setErrors}
          />
          {question.isScoring && (
            <Control
              label="Coefficient"
              value={question.coef}
              type="btnList"
              btnInline={true}
              dataLabel="name"
              dataIndex="id"
              name="coef"
              datas={[
                { id: 1, name: "1 - Neutre" },
                { id: 2, name: "2 - Pertinant" },
                { id: 3, name: "3 - Important" },
                { id: 4, name: "4 - Très important" },
                { id: 5, name: "5 - Capital" },
              ]}
              change={(e) => setQuestion({ ...question, coef: e.target.value })}
              error={errors}
              setError={setErrors}
            />
          )}

          <Control
            label="Type de question"
            type="btnList"
            id="type"
            name="type"
            btnInline={true}
            change={updateData}
            value={question.type}
            datas={inputTypes}
            dataIndex="id"
            error={errors}
            dataLabel="name"
          />

          <AnswerList
            type={question.type}
            answers={question.answers}
            languages={languages}
            addAnswer={addAnswer}
            removeAnswer={removeAnswer}
            updateDataAnswerLabel={updateDataAnswerLabel}
            updateDataAnswerValue={updateDataAnswerValue}
            updateDataAnswerScore={updateDataAnswerScore}
            inputParams={question.inputParams}
            updateInputParams={updateInputParams}
            presetAnswer={presetAnswer}
            errors={errors}
          />
          <h4>Image de la question</h4>
          {question.id ? (
            <QuestionImagesUploader
              question={question}
              setQuestion={setQuestion}
            />
          ) : (
            <p>
              Enregistrez d'abord la question avant de pouvoir envoyer des
              images
            </p>
          )}

          <h4>Aperçu de la question</h4>
          <QuestionPreview question={question} />
          <div className="d-flex justify-content-end w-100">
            <SaveBtn
              save={save}
              isSaving={isLoading}
              text="Enregistrer"
              className="btn btn-success btn-save"
            />
          </div>
        </>
      )}
    </Page>
  );
};

export default QuestionDetail;
