import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Page from "../../common/layout/Page";
import Axios from "axios";
import { API_URL } from "../../../config";
import Control from "../../common/Controls/Control";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import Pagination from "../../common/Pagination";
import tools from "../../../helpers/tools";
import Table from "../../common/Table/Table";
import dataService from "../../../helpers/dataService";
import SubThemeSelectInput from "../QuestionDetail/SubThemeSelectInput";

var strTimeout = null;

const QuestionList = () => {
  const [state, dispatch] = useStore();
  const languages = state.constants.items.LANGUAGES;
  const inputTypes = state.constants.items.INPUT_TYPES;
  const constants = state.constants.items;

  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState({
    question: {
      subTheme: "",
    },
    page: 1,
  });
  const [nbPages, setNbPages] = useState(1);
  const [lang, setLang] = useState("FR");

  useEffect(() => {
    var lsState = tools.getState("QuestionList", { search: search });
    setSearch(lsState.search);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    clearTimeout(strTimeout);
    strTimeout = setTimeout(() => {
      searchQuestions();
    }, 1500);
    tools.saveState("QuestionList", "search", search);
  }, [search]);

  function searchQuestions() {
    setErrors(false);
    Axios.post(API_URL + "questions/search", search)
      .then((res) => {
        setQuestions(res.data.datas);
        setNbPages(Math.ceil(parseInt(res.data.count) / 25));
        setTotal(res.data.count);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }

        setQuestions([]);
        console.log(err.response.data);
      });
  }
  function updateQuestionSearch(e) {
    var value = e.target.value;
    var su = { ...search };
    if (value == "") {
      delete su.question[e.target.name];
    } else {
      su.question[e.target.name] = value;
    }
    console.log(su);

    setSearch(su);
  }

  function changePage(i) {
    setSearch((search) => {
      return { ...search, page: i };
    });
  }
  const deleteData = (id) => {
    if (!window.confirm("Êtes-vous sûr de vouloir supprimer cette entrée ?"))
      return false;
    setIsLoading(true);
    const onRemoveSuccess = (data) => {
      var dt = JSON.parse(JSON.stringify(questions));
      dt = dt.filter((d) => d.id != id);

      setQuestions(dt);
    };
    const onRemoveFinish = () => {
      setIsLoading(false);
    };
    dataService.remove(
      "questions/" + id,
      {},
      onRemoveSuccess,
      setErrors,
      onRemoveFinish
    );
  };

  console.log("search", search);
  return (
    <Page
      container="container"
      action={{ to: "/questions/create", text: "Créer une question" }}
      title="Liste des questions"
    >
      <div className="row mb-4 mx-0">
        <div className="col px-0">
          <Control
            type="text"
            label="Recherche"
            name="searchString"
            value={search.question.searchString}
            change={updateQuestionSearch}
            suffix={
              <i className="fa fa-search" style={{ lineHeight: "40px" }}></i>
            }
          />
        </div>
        <div className="col align-items-start justify-content-center d-flex px-0">
          <div className="form-group">
            <label>
              <span className="text-label">Langue</span>
              <ul className="nav nav-tabs mb-3">
                {languages &&
                  languages.map((ln, lk) => (
                    <li className="nav-item">
                      <a
                        className={
                          "nav-link " + (ln == lang ? "active" : "default")
                        }
                        onClick={() => setLang(ln)}
                        data-toggle="tab"
                        href="#FRtab"
                        role="tab"
                      >
                        {ln}
                      </a>
                    </li>
                  ))}
              </ul>
            </label>
          </div>
        </div>
        <div className="col align-items-start justify-content-center d-flex px-0">
          <SubThemeSelectInput
            updateData={updateQuestionSearch}
            value={search.question.subTheme}
            name="subTheme"
          />
        </div>
        <div className="col align-items-center justify-content-end d-flex px-0">
          {total} questions trouvées
        </div>
      </div>
      <div className="row mx-0">
        <div className="col-12 px-0">
          {errors && errors.other ? (
            <div className="text-danger m-auto text-center">{errors.other}</div>
          ) : isLoading || !languages ? (
            <Loader />
          ) : (
            <Table
              fields={[
                {
                  name: "Question",
                  path: "question",
                  render: (el) => el[lang],
                },
                {
                  name: "Type",
                  path: "type",
                  render: (el) => inputTypes.find((inp) => inp.id == el).name,
                },
                {
                  name: "Réponses",
                  path: "answers",
                  render: (el) =>
                    el.map((ans, ansk) => (
                      <div>
                        {ans.value} - {ans.label[lang]}
                      </div>
                    )),
                },
                {
                  name: "Sous-thème",
                  path: "subTheme",
                  render: (el) => {
                    var sub = "";
                    constants.THEMES.forEach((th) => {
                      var sth = th.subThemes.find((th) => th._id == el);
                      if (sth) sub = sth.name;
                    });
                    return sub;
                  },
                },
                {
                  name: "Actions",
                  path: "id",
                  render: (el) => (
                    <>
                      <Link
                        className=" btn btn-default  d-center d-inline-flex"
                        to={"/questions/" + el}
                      >
                        <i className="fa fa-edit"></i>
                      </Link>
                      <button
                        className="btn btn-danger d-inline-flex"
                        onClick={() => deleteData(el)}
                      >
                        <i className="fa fa-times"></i>
                      </button>
                    </>
                  ),
                },
              ]}
              datas={questions}
            />
          )}
          <Pagination
            nbPages={nbPages}
            page={search.page}
            changePage={changePage}
          />
        </div>
      </div>
    </Page>
  );
};

export default QuestionList;
