import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { image_url } from "../../../../../config";
import { modalActions } from "../../../../../context/actions/modalActions";
import useStore from "../../../../../context/useStore";
import dataService from "../../../../../helpers/dataService";
import tools from "../../../../../helpers/tools";
import Picture from "../../../../common/Picture/Picture";

const ReportPhotos = ({ missionId, ...props }) => {
  const [state, dispatch] = useStore();
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const modal_actions = modalActions(state, dispatch);

  useEffect(() => {
    getImages();
  }, []);

  const getImages = () => {
    if (props.isInternal) {
      dataService.get(
        `missions/${missionId}/images/rogue`,
        setImages,
        setErrors,
        () => setIsLoading(false)
      );
    } else {
      dataService.get(
        `missions/${missionId}/images`,
        setImages,
        setErrors,
        () => setIsLoading(false)
      );
    }
  };

  const openImage = (url) => {
    window.open(url);
  };

  return (
    <div className="row mx-0">
      {images.map((i, ik) => {
        let imageUrl = image_url + i.fileName;
        return (
          <div
            className="col-12 col-md-4 col-lg-3 text-center my-2 px-1"
            onClick={() => openImage(imageUrl)}
            key={`image${i.id}`}
          >
            <div className="border p-2">
              <div className="font-weight-bold">
                {tools.getFullName(i.uploadedBy)}
              </div>
              <div className="font-weight-bold">
                le {DateTime.fromISO(i.createdAt).toFormat("dd/MM/yyyy hh:mm")}
              </div>
              <Picture
                img={{
                  url: imageUrl,
                  width: 200,
                  height: 200,
                  alt: "",
                }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ReportPhotos;
