import { useElementScroll } from "framer-motion";
import React, { useEffect, useState } from "react";
import dataService from "../../../../helpers/dataService";
import Control from "../../../common/Controls/Control";

const ProspectorQualification = ({ user, getUser }) => {
  const [qualification, setQualification] = useState(user.rankConditions);

  useEffect(() => {
    dataService.get(`prospectors/${user.id}/qualification`, setQualification);
  }, []);

  useEffect(() => {
    setQualification(user.rankConditions);
  }, [user]);

  const updateIsFormation = () => {
    dataService.patch(
      `prospectors/${user.id}/formation`,
      { isFormation: true },
      (datas) => getUser()
    );
  };

  return (
    <>
      <ul className="list-group">
        <li className="list-group-item">
          <strong
            className={`${user.rank >= 1 ? "text-success" : "text-muted"}`}
          >
            <i
              className={`fa mr-2 ${user.rank >= 1 ? "fa-check" : "fa-times"}`}
            />
            NIVEAU 1
          </strong>
        </li>
        <li className="list-group-item">
          <div className="d-flex align-items-center justify-content-start font-weight-bold text-primary">
            <i className="fa fa-check mr-2" /> Inscription réalisée
          </div>
        </li>

        <li className="list-group-item">
          <strong
            className={`${user.rank >= 2 ? "text-success" : "text-muted"}`}
          >
            <i
              className={`fa mr-2 ${user.rank >= 2 ? "fa-check" : "fa-times"}`}
            />
            NIVEAU 2
          </strong>
        </li>
        <li className="list-group-item">
          <div
            className={`d-flex align-items-center justify-content-start font-weight-bold ${
              qualification.isAptitudeTest ? "text-primary" : "text-muted"
            }`}
          >
            <i
              className={`fa mr-2 ${
                qualification.isAptitudeTest ? "fa-check" : "fa-times"
              }`}
            />
            Formulaire d'aptitude complété
          </div>
        </li>
        <li className="list-group-item">
          <div
            className={`d-flex align-items-center justify-content-start font-weight-bold ${
              qualification.isFormation ? "text-primary" : "text-muted"
            }`}
          >
            <i
              className={`fa mr-2 ${
                qualification.isFormation ? "fa-check" : "fa-times"
              }`}
            />
            Formation suivie
            {!qualification.isFormation && (
              <button
                className="btn btn-secondary btn-sm ml-2"
                onClick={updateIsFormation}
              >
                Cliquez ici si la formation a été effectuée
              </button>
            )}
          </div>
        </li>

        <li className="list-group-item">
          <strong
            className={`${user.rank >= 3 ? "text-success" : "text-muted"}`}
          >
            <i
              className={`fa mr-2 ${user.rank >= 3 ? "fa-check" : "fa-times"}`}
            />
            NIVEAU 3
          </strong>
        </li>
        <li className="list-group-item">
          <div
            className={`d-flex align-items-center justify-content-start font-weight-bold ${
              qualification.nbMissionsFinished >= 5
                ? "text-primary"
                : "text-muted"
            }`}
          >
            <i
              className={`fa mr-2 ${
                qualification.nbMissionsFinished >= 5 ? "fa-check" : "fa-times"
              }`}
            />
            {qualification.nbMissionsFinished}/5 constats complétés
          </div>
        </li>
        <li className="list-group-item">
          <div
            className={`d-flex align-items-center justify-content-start font-weight-bold ${
              qualification.nbSurveysFinished >= 5
                ? "text-primary"
                : "text-muted"
            }`}
          >
            <i
              className={`fa mr-2 ${
                qualification.nbSurveysFinished >= 5 ? "fa-check" : "fa-times"
              }`}
            />
            {qualification.nbSurveysFinished}/5 sondages complétés
          </div>
        </li>
      </ul>
    </>
  );
};

export default ProspectorQualification;
