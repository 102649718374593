import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import useStore from "../../../context/useStore";
import Table from "../../common/Table/Table";
import { DateTime } from "luxon";
import dataService from "../../../helpers/dataService";
import themeListController from "./themeListController";
import ThemeListFilters from "./ThemeListFilters";
import { Link } from "react-router-dom";
import Pagination from "../../common/Pagination";
import tools from "../../../helpers/tools";

var strTimeout = null;

const ThemeList = () => {
  const [state, dispatch] = useStore();
  const [search, setSearch] = useState({
    theme: {
      searchString: "",
    },
    page: 1,
  });
  const [errors, setErrors] = useState({});
  const [datas, setDatas] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [nbPages, setNbPages] = useState(1);

  useEffect(() => {
    let lsState = tools.getState("ThemeList", { search: search });
    setSearch(lsState.search);
  }, []);

  useEffect(() => {
    clearTimeout(strTimeout);
    strTimeout = setTimeout(() => {
      getDatas();
    }, 1500);
    tools.saveState("ThemeList", "search", search);
  }, [search]);

  // useEffect(() => {
  //   if (state.constants.items && search) {
  //     getDatas();
  //   }
  // }, [state.constants.items, search]);

  useEffect(() => {
    if (isLoading) setErrors({});
  }, [isLoading]);

  const getDatas = () => {
    setIsLoading(true);
    setDatas(false);
    // const onDataSuccess = (datas) => {
    //   setDatas(themeListController.formatData(datas.datas));
    // };
    const onDataSuccess = (res) => {
      setDatas(res.datas);
    };
    const onDataFinish = () => {
      setIsLoading(false);
    };
    dataService.post(
      "themes/search",
      search,
      onDataSuccess,
      setErrors,
      onDataFinish
    );
  };
  const deleteData = (id) => {
    if (!window.confirm("Êtes-vous sûr de vouloir supprimer cette entrée ?"))
      return false;
    setIsLoading(true);
    const onRemoveSuccess = (data) => {
      var dt = JSON.parse(JSON.stringify(datas));
      dt = dt.filter((d) => d.id != id);
      console.log("DT", dt);
      setDatas(dt);
    };
    const onRemoveFinish = () => {
      setIsLoading(false);
    };
    dataService.remove(
      "themes/" + id,
      {},
      onRemoveSuccess,
      setErrors,
      onRemoveFinish
    );
  };

  return (
    <Page
      title="Liste des thèmes"
      errors={errors}
      isLoading={isLoading}
      action={{ to: "/themes/create", text: "Créer un thème" }}
    >
      <div className="row">
        <ThemeListFilters
          search={search}
          setSearch={setSearch}
          errors={errors}
        />
      </div>
      <div className="row ">
        <div className="col-12">
          <Table
            exportOptions={{
              excludedFields: ["Actions"],
            }}
            fields={[
              { name: "Nom", path: "name" },
              {
                name: "Nb sous-thèmes",
                path: "subThemes",
                render: (el) => el.length,
              },
              {
                name: "Actions",
                path: "id",
                render: (el) => (
                  <>
                    <Link
                      to={`/themes/${el}`}
                      className="btn btn-default d-center d-inline-flex"
                    >
                      <i className="fa fa-edit" />
                    </Link>
                    <button
                      className="btn btn-danger d-inline-flex"
                      onClick={() => deleteData(el)}
                    >
                      <i className="fa fa-times" />
                    </button>
                  </>
                ),
              },
            ]}
            datas={datas}
          />
          <Pagination
            nbPages={nbPages}
            page={search.page}
            changePage={(p) => setSearch({ ...search, page: p })}
          />
        </div>
      </div>
    </Page>
  );
};

export default ThemeList;
