import React, { Fragment, useEffect, useState } from "react";
import useStore from "../../../../../context/useStore";
import tools from "../../../../../helpers/tools";
import Loader from "../../../../common/Loader";

import QuestionGenerator from "./QuestionGenerator";

const FormGenerator = ({ mission, questions, save, formErrors }) => {
  const [state, dispatch] = useStore();
  const { isConnected } = state.auth;
  const [step, setStep] = useState(0);
  const [answers, setAnswers] = useState(false);
  const [uploadedBase64, setUploadedBase64] = useState({});
  const [prevStep, setPrevStep] = useState(0);
  const lsRef = "mission_" + mission.id;

  useEffect(() => {
    var ans = {};
    var bs64 = {};
    var ansValid = false;

    if (!ansValid) {
      questions.forEach((q) => {
        ans[q._id] = [];
        if (q.type == 7) {
          for (var i = 0; i < q.inputParams.count; i++) {
            bs64[q._id] = bs64[q._id] ? bs64[q._id] : [];
            bs64[q._id][i] = [];
          }
        }
      });
    }
    console.log("ANSWERS", ans);
    setUploadedBase64(bs64);
    setAnswers(ans);
  }, [questions]);

  useEffect(() => {
    if (step > 0 && tools.shouldSkipQuestion(answers, step, questions)) {
      if (prevStep < step) {
        next();
      } else {
        prev();
      }
    }
  }, [step]);

  useEffect(() => {
    if (Object.keys(formErrors).length) {
      let firstErrorFound = false;
      questions.forEach((qs, qsk) => {
        if (formErrors[qs._id]) {
          console.log("errors at ", qs._id, qsk);
          if (!firstErrorFound) {
            setStep(qsk);
            firstErrorFound = true;
          }
        }
      });
    }
  }, [formErrors]);

  useEffect(() => {
    console.log("ANSWERS", answers);
    if (answers) {
      localStorage.setItem(lsRef, JSON.stringify(answers));
    }
  }, [answers]);

  const prev = () => {
    if (step > 0) {
      setPrevStep(step);
      setStep(step - 1);
    }
  };
  const next = () => {
    if (step < questions.length - 1) {
      setPrevStep(step);
      setStep(step + 1);
    }
  };

  return (
    <Fragment>
      {answers ? (
        <Fragment>
          {step > 0 && (
            <div className="mt-2">
              <button className="btn btn-default" onClick={prev}>
                Question précédente
              </button>
            </div>
          )}
          {questions.map((qs, qsk) => {
            return qsk == step ? (
              <QuestionGenerator
                question={qs}
                key={"qs" + qsk}
                step={step}
                answers={answers}
                setAnswers={setAnswers}
                prev={prev}
                next={next}
                prevStep={prevStep}
                formErrors={formErrors}
                uploadedBase64={uploadedBase64}
                setUploadedBase64={setUploadedBase64}
              />
            ) : null;
          })}
          {step < questions.length - 1 && (
            <div>
              <button className="btn btn-primary" onClick={next}>
                Question suivante
              </button>
            </div>
          )}
          {step == questions.length - 1 && (
            <div>
              <i>Fin du formulaire</i>
            </div>
          )}
        </Fragment>
      ) : (
        <Loader fullPage />
      )}
    </Fragment>
  );
};

export default FormGenerator;
