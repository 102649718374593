import Axios from "axios";
import fileDownload from "js-file-download";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { API_URL } from "../../../../../config";
import dataService from "../../../../../helpers/dataService";
import Loader from "../../../../common/Loader";
import ReportUploader from "./ReportUploader";

const ReportFile = ({ missionId }) => {
  const [report, setReport] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isGettingReport, setIsGettingReport] = useState(false);

  useEffect(() => {
    getReport();
  }, []);

  const getReport = () => {
    setIsGettingReport(true);
    dataService.get(
      `missions/${missionId}/reportfile`,
      (datas) => setReport(datas[0]),
      (err) => {},
      () => setIsGettingReport(false)
    );
  };

  const downloadFile = () => {
    if (isDownloading) return false;
    setIsDownloading(true);
    Axios({
      url: `${API_URL}missions/${missionId}/reportfile/manual`,
      method: "GET",
      responseType: "blob",
    })
      .then((res) => {
        // var filename = res.headers["content-disposition"].split("=")[1];
        fileDownload(res.data, "Rapport.pdf");
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          toast.error(err.response.data.other);
        }
      })
      .then(() => {
        setIsDownloading(false);
      });
  };

  return (
    <div className="row mt-5">
      <div className="col-12 col-md-6 mb-3">
        <div>
          <strong>Rapport uploadé</strong>
        </div>
        {report ? (
          <button
            onClick={downloadFile}
            className="btn btn-success d-center mt-2"
          >
            {isDownloading || isGettingReport ? (
              <Loader color="white" />
            ) : (
              <>
                {" "}
                <i className="fa fa-file-download mr-2" />
                Uploadé le{" "}
                {DateTime.fromISO(report.uploadedAt).toFormat(
                  "dd/MM/yyyy à HH'h'mm"
                )}
              </>
            )}
          </button>
        ) : (
          <p>Aucun rapport trouvé</p>
        )}
      </div>
      <div className="col-12  col-md-6">
        <div>
          <strong>Uploader le rapport final</strong>
        </div>
        <ReportUploader getReport={getReport} missionId={missionId} />
      </div>
    </div>
  );
};

export default ReportFile;
