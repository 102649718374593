import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Page from "../../common/layout/Page";
import Axios from "axios";
import { API_URL } from "../../../config";
import Control from "../../common/Controls/Control";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import Pagination from "../../common/Pagination";
import tools from "../../../helpers/tools";
import Table from "../../common/Table/Table";
import { DateTime } from "luxon";
import dataService from "../../../helpers/dataService";

var strTimeout = null;

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [state, dispatch] = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [errors, setError] = useState(false);
  const [search, setSearch] = useState({
    user: { searchString: "" },
    page: 1,
  });
  const [nbPages, setNbPages] = useState(1);
  const [allProspects, setAllProspects] = useState([]);

  useEffect(() => {
    let lsState = tools.getState("ProspectList", { search: search });
    setSearch(lsState.search);
    getAllUsers();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    clearTimeout(strTimeout);
    strTimeout = setTimeout(() => {
      searchUsers();
    }, 1500);
    tools.saveState("ProspectList", "search", search);
  }, [search]);

  const getAllUsers = () => {
    dataService.get("prospects", setAllProspects);
  };

  function searchUsers() {
    setError(false);
    Axios.post(API_URL + "prospects/search", search)
      .then((res) => {
        setUsers(res.data.datas);
        setTotal(res.data.count);
        setNbPages(Math.ceil(parseInt(res.data.count) / 25));
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.response.data);
        setUsers([]);
        console.log(err.response.data);
      });
  }
  function updateUserSearch(e) {
    var value = e.target.value;
    var su = { ...search };
    if (value == "") {
      delete su.user[e.target.name];
    } else {
      su.user[e.target.name] = value;
    }
    console.log(su);

    setSearch(su);
  }

  function changePage(i) {
    setSearch((search) => {
      return { ...search, page: i };
    });
  }

  console.log("search", search);

  return (
    <Page
      container="container"
      action={{ to: "/prospects/create", text: "Créer un prospect" }}
      title={"Liste des prospects"}
    >
      <div className="row mb-4">
        <div className="col">
          <Control
            type="text"
            label="Recherche"
            name="searchString"
            value={search.user.searchString}
            change={updateUserSearch}
            suffix={
              <i
                className="fa fa-search ml-2"
                style={{ lineHeight: "40px" }}
              ></i>
            }
          />
        </div>
        <div className="col align-items-center justify-content-end d-flex">
          {total} utilisateurs trouvés
        </div>
      </div>
      <div className="row">
        <div className="col-12 p-4">
          {errors && errors.other ? (
            <div className="text-danger m-auto text-center">{errors.other}</div>
          ) : isLoading ? (
            <Loader />
          ) : (
            <Table
              fields={[
                {
                  name: "Société",
                  path: "companyName",
                },
                { name: "Email", path: "email" },
                {
                  name: "Créé le",
                  path: "createdAt",
                  render: (el) => DateTime.fromISO(el).toFormat("dd/MM/yyyy"),
                },
                {
                  name: "Actions",
                  path: "id",
                  render: (el) => {
                    return (
                      <Link className="btn btn-primary" to={`/prospects/${el}`}>
                        <i className="fa fa-edit" />
                      </Link>
                    );
                  },
                },
              ]}
              datas={users}
              exportOptions={{
                overrideDatas: allProspects,
                formatDatasFn: (datas) => datas,
                excludedFields: ["Actions"],
                objectFields: {},
                additionalFields: [
                  { name: "Prénom", path: "firstname" },
                  { name: "Nom", path: "lastname" },
                  { name: "SIRET", path: "siret" },
                  { name: "Téléphone", path: "phone" },
                  { name: "Adresse", path: "address" },
                  { name: "Adresse compl.", path: "additionalAddress" },
                ],
              }}
            />
          )}
          <Pagination
            nbPages={nbPages}
            page={search.page}
            changePage={changePage}
          />
        </div>
      </div>
    </Page>
  );
};

export default UserList;
