import React, { useEffect, useState } from "react";
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import logo from "./logo.svg";
import PrivateRoute from "./components/common/PrivateRoute";
import tools from "./helpers/tools";
import jwt_decode from "jwt-decode";
// Pages
import useStore from "./context/useStore";
import { authActions } from "./context/actions/authActions";
import { AUTH_TYPES } from "./context/reducers/authReducers";
import Sidenav from "./components/common/layout/Sidenav";
import Login from "./components/pages/Login/Login";
import Dashboard from "./components/pages/Dashboard/Dashboard";
import { constantActions } from "./context/actions/constantActions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RenewPassword from "./components/pages/RenewPassword/RenewPassword";
import ActivateAccount from "./components/pages/ActivateAccount/ActivateAccount";
import TopHeader from "./components/common/layout/TopHeader";
import $ from "jquery";
import Colors from "./theme/variables.scss";
import { loadReCaptcha } from "react-recaptcha-v3";
import { recaptcha_token, API_URL } from "./config";
import Axios from "axios";
import Register from "./components/pages/Register/Register";
import Profile from "./components/pages/Profile/Profile";
import CustomModal from "./components/common/CustomModal/CustomModal";
import Test from "./components/pages/Test";
import Logout from "./components/pages/Logout/Logout";
import { AnimatePresence } from "framer-motion";
import BaseList from "./components/pages/BaseList/BaseList";
import Index from "./components/pages/Index/Index";
import MissionList from "./components/pages/MissionList/MissionList";
import MissionDetail from "./components/pages/MissionDetail/MissionDetail";

import QuestionList from "./components/pages/QuestionList/QuestionList";
import QuestionDetail from "./components/pages/QuestionDetail/QuestionDetail";
import MissionReport from "./components/pages/MissionReport/MissionReport";
import MissionPreview from "./components/pages/MissionDetail/MissionPreview";

import ManagerList from "./components/pages/ManagerList/ManagerList";
import ManagerDetail from "./components/pages/ManagerDetail/ManagerDetail";

import ProspectList from "./components/pages/ProspectList/ProspectList";
import ProspectDetail from "./components/pages/ProspectDetail/ProspectDetail";

import ThemeList from "./components/pages/ThemeList/ThemeList";
import ProspectorList from "./components/pages/ProspectorList/ProspectorList";
import ProspectorDetail from "./components/pages/ProspectorDetail/ProspectorDetail";
import ThemeDetail from "./components/pages/ThemeDetail/ThemeDetail";
import Postulations from "./components/pages/Postulations/Postulations";
import IBANValidation from "./components/pages/IBANValidation/IBANValidation";
import Payments from "./components/pages/Payments/Payments";
import Faqs from "./components/pages/Faqs/Faqs";
import AspiringProspectorList from "./components/pages/AspiringProspectorList/AspiringProspectorList";
import AspiringProspectorDetail from "./components/pages/AspiringProspectorDetail/AspiringProspectorDetail";
import ProductList from "./components/pages/ProductList/ProductList";
import ProductDetail from "./components/pages/ProductDetail/ProductDetail";
import ProductCategoryDetail from "./components/pages/ProductDetail/ProductCategoryDetail";
import ProductSubCategoryDetail from "./components/pages/ProductDetail/ProductSubCategoryDetail";
import PriceStatement from "./components/pages/PriceStatement/PriceStatement";

window.borderRadius = Colors.borderRadius;

var firstLoad = true;
const App = () => {
  const [state, dispatch] = useStore();
  const [sideNavOpen, setSivenavOpen] = useState(true);
  var actions = authActions(state, dispatch);
  var c_actions = constantActions(state, dispatch);
  Axios.defaults.withCredentials = true;
  if (localStorage.jwtToken && firstLoad) {
    firstLoad = false;
    // Set auth token header auth
    //tools.setAuthToken(localStorage.jwtToken);
    // Decode token and get user info and exp
    const decoded = jwt_decode(localStorage.jwtToken);

    // Set user and isAuthenticated
    dispatch({
      type: AUTH_TYPES.SET_CURRENT_USER,
      payload: decoded,
    });

    // Check for expired token
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      // Logout user

      actions.logoutUser();
      // Clear current Profile
      // store.dispatch(clearCurrentProfile())
      // Redirect to login
    }
  }

  useEffect(() => {
    if (!localStorage.jwtToken) {
      Axios.get(API_URL + "auth/me")
        .then((res) => {
          const decoded = jwt_decode(res.data);
          dispatch({
            type: AUTH_TYPES.SET_CURRENT_USER,
            payload: decoded,
          });
        })
        .catch((err) => {
          actions.logoutUser();
        });
    }
    c_actions.getConstants();
    loadReCaptcha(recaptcha_token, () => {
      console.log("captcha loaded !");
    });
  }, []);

  const sidenavItems = [
    {
      name: "Accueil",
      path: "/dashboard",
      icon: "home",
    },
    {
      name: "Constats",
      path: "/constats",
      icon: "clipboard",
    },
    {
      name: "Questions",
      path: "/questions",
      icon: "question-circle",
    },
    {
      name: "Administrateurs",
      path: "/managers",
      icon: "user-tie",
    },
    {
      name: "Prospects",
      path: "/prospects",
      icon: "user-tag",
    },
    {
      name: "Agents",
      path: "/prospectors",
      icon: "user",
    },
    {
      name: "Thèmes",
      path: "/themes",
      icon: "drafting-compass",
    },
    {
      name: "Validation IBAN",
      path: "/ibans",
      icon: "barcode",
    },
    {
      name: "Paiements",
      path: "/payments",
      icon: "credit-card",
    },
    {
      name: "FAQs",
      path: "/faqs",
      icon: "question",
    },
    {
      name: "Formation",
      path: "/formation",
      icon: "calendar-alt",
    },
    {
      name: "Produits",
      path: "/products",
      icon: "tags",
    },
    {
      name: "Relevé prix",
      path: "/price",
      icon: "hand-holding-usd"
    }
  ];

  return (
    <>
      <Router>
        <div className="wrapper">
          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          />
          <Sidenav
            sidenavItems={sidenavItems}
            isOpen={sideNavOpen}
            setSivenavOpen={setSivenavOpen}
          />
          <div id="content" className="position-relative">
            <TopHeader />
            <button
              type="button"
              id="sidebarCollapse"
              className={`navbar-btn ${sideNavOpen ? "active" : ""}`}
              onClick={() => {
                setSivenavOpen((s) => !s);
              }}
              style={{
                opacity: state.auth.isAuthenticated ? "1" : "0",
              }}
            >
              <i className="fa fa-chevron-left" />
            </button>

            {/* Same as */}
            {Object.keys(state.constants.items).length && (
              <AnimatePresence exitBeforeEnter={true}>
                <Switch>
                  <Route path="/list" exact component={BaseList} />
                  <Route
                    path="/renew-password/:token"
                    exact
                    component={RenewPassword}
                  />

                  {process.env.NODE_ENV == "development" && (
                    <Route path="/test" exact component={Test} />
                  )}
                  <Route
                    path="/activate-account/:token/:email"
                    exact
                    component={ActivateAccount}
                  />
                  <Route
                    path="/renew-password/:token/:email"
                    exact
                    component={RenewPassword}
                  />
                  <Route path="/logout" exact component={Logout} />
                  <Route path="/register" exact component={Register} />

                  <PrivateRoute path="/" exact component={Dashboard} />
                  <PrivateRoute path="/themes" exact component={ThemeList} />
                  <PrivateRoute
                    path="/themes/:id"
                    exact
                    component={ThemeDetail}
                  />
                  <PrivateRoute path="/dashboard" exact component={Dashboard} />

                  <PrivateRoute path="/profile" exact component={Profile} />
                  <PrivateRoute
                    path="/constats"
                    exact
                    component={MissionList}
                  />
                  <PrivateRoute
                    path="/constats/:id"
                    exact
                    component={MissionDetail}
                  />
                  <PrivateRoute
                    path="/constats/:id/report"
                    exact
                    component={MissionReport}
                  />
                  <PrivateRoute
                    path="/constats/:id/preview"
                    exact
                    component={MissionPreview}
                  />
                  <PrivateRoute
                    path="/managers"
                    exact
                    component={ManagerList}
                  />
                  <PrivateRoute
                    path="/managers/:id"
                    exact
                    component={ManagerDetail}
                  />
                  <PrivateRoute
                    path="/prospects"
                    exact
                    component={ProspectList}
                  />
                  <PrivateRoute
                    path="/prospects/:id"
                    exact
                    component={ProspectDetail}
                  />
                  <PrivateRoute
                    path="/prospectors"
                    exact
                    component={ProspectorList}
                  />
                  <PrivateRoute
                    path="/prospectors/:id"
                    exact
                    component={ProspectorDetail}
                  />
                  <PrivateRoute
                    path="/questions"
                    exact
                    component={QuestionList}
                  />
                  <PrivateRoute
                    path="/questions/:id"
                    exact
                    component={QuestionDetail}
                  />
                  <PrivateRoute
                    path="/postulations"
                    exact
                    component={Postulations}
                  />
                  <PrivateRoute
                    path="/postulations/:id"
                    exact
                    component={QuestionDetail}
                  />
                  <PrivateRoute
                    path="/ibans"
                    exact
                    component={IBANValidation}
                  />
                  <PrivateRoute path="/payments" exact component={Payments} />
                  <PrivateRoute path="/faqs" exact component={Faqs} />
                  <PrivateRoute
                    path="/formation"
                    exact
                    component={AspiringProspectorList}
                  />
                  <PrivateRoute
                    path="/formation/:id"
                    exact
                    component={AspiringProspectorDetail}
                  />
                  <PrivateRoute
                    path="/products"
                    exact
                    component={ProductList}
                  />
                  <PrivateRoute
                    path="/products/:id"
                    exact
                    component={ProductDetail}
                  />
                  <PrivateRoute
                    path={"/product-categories/:id"}
                    exact
                    component={ProductCategoryDetail}
                  />
                  <PrivateRoute
                    path={"/product-subcategories/:id"}
                    exact
                    component={ProductSubCategoryDetail}
                  />
                  <PrivateRoute
                    path="/price"
                    exact
                    component={PriceStatement}
                  />
                </Switch>
              </AnimatePresence>
            )}
          </div>
        </div>
      </Router>
      <CustomModal />
    </>
  );
};

export default App;
