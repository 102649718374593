import React, { useState } from "react";
import dataService from "../../../helpers/dataService";

const useMissionChart = (url, year) => {
  const [isLoading, setIsLoading] = useState(false);
  const [chartState, setChartState] = useState(false);
  const [total, setTotal] = useState(0);

  const months = [
    "Jan",
    "Fev",
    "Mar",
    "Avr",
    "Mai",
    "Juin",
    "Juil",
    "Aou",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const getDatas = () => {
    setIsLoading(true);

    dataService.post(
      `${url}`,
      { year },
      (datas) => {
        setChartState({
          pie1: {
            series: [datas.survey, datas.mission, datas.template],
            options: {
              labels: ["Sondages", "Constats", "Template"],
              legend: { position: "bottom" },
            },
          },
          pie2: {
            series: [datas.status[0].Brouillon, datas.status[1].Actif],
            options: {
              labels: ["Brouillon", "Actif"],
              legend: { position: "bottom" },
            },
          },
          pie3: {
            series: [datas.progress, datas.finished, datas.future],
            options: {
              labels: ["En cours", "Terminé", "Programmé"],
              legend: { position: "bottom" },
            },
          },
        });
        setTotal(datas.total);
      },
      (e) => {},
      () => setIsLoading(false)
    );
  };

  var hasDatas = chartState;

  return {
    isLoading,
    getDatas,
    chartState,
    hasDatas,
    total,
  };
};

export default useMissionChart;
