import Axios from "axios";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { API_URL } from "../../../config";
import Control from "../../common/Controls/Control";
import Page from "../../common/layout/Page";
import SaveBtn from "../../common/SaveBtn";

const AspiringProspectorDetail = (props) => {
    const [user, setUser] = useState({
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        location: {
            cityName: "",
            postalCode: "",
        },
        birthdate: new Date(),
        formation: {
            formationDate: "",
            isFormationOk: false,
        },
        status: "",
        isCGUAccepted: false,
        isNewsletter: false,
    });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const itemId = props.userId || props.match.params.id;

    useEffect(() => {
        if (itemId !== "create") {
            Axios.get(API_URL + "formation/" + itemId)
                .then((res) => {
                    setUser(res.data);
                })
                .catch((err) => {
                    console.log(err);
                    setErrors(err.response.data);
                });
        }
    }, []);

    const updateData = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        if (e.target.type === "checkbox") {
            console.log(e.target.checked);
            if (e.target.checked) {
                value = true;
            } else {
                value = false;
            }
        }

        if (name === "cityName" || name === "postalCode") {
            setUser({ ...user, location: { ...user.location, [name]: value } });
            return;
        }
        if (name === "formationDate") {
            setUser({
                ...user,
                formation: { ...user.formation, [name]: value },
            });
            return;
        }
        setUser({ ...user, [name]: value });
    };

    const save = () => {
        setIsLoading(true);
        setErrors({});
        if (itemId === "create") {
            // Create
            Axios.post(`${API_URL}formation`, user)
                .then((res) => {
                    setUser(res.data);
                    toast.success("Nouvelle inscription créée avec succès");
                })
                .catch((err) => {
                    toast.error("Une erreur est survenue");
                    if (err.response && err.response.data) {
                        setErrors(err.response.data);
                    }
                })
                .then(() => setIsLoading(false));
        } else {
            // Update
            Axios.patch(`${API_URL}formation/${itemId}`, user)
                .then((res) => {
                    setUser(res.data);
                    toast.success("Informations modifiées avec succès");
                })
                .catch((err) => {
                    toast.error("Une erreur est survenue");
                    if (err.response && err.response.data) {
                        setErrors(err.response.data);
                    }
                })
                .then(() => setIsLoading(false));
        }
    };

    return (
        <Page
            container='container'
            title="Détail demande d'inscription"
            errors={errors}
            back={"/formation"}>
            <div className='row'>
                <div className='col-12 col-md-4 p-0'>
                    <Control
                        label='Prénom'
                        name='firstname'
                        type='text'
                        value={user.firstname}
                        change={updateData}
                        error={errors}
                    />
                </div>
                <div className='col-12 col-md-4 p-0'>
                    <Control
                        label='Nom'
                        name='lastname'
                        type='text'
                        value={user.lastname}
                        change={updateData}
                        error={errors}
                    />
                </div>
                <div className='col-12 col-md-4 p-0'>
                    <Control
                        label='Date de naissance'
                        name='birthdate'
                        type='date'
                        value={user.birthdate}
                        change={updateData}
                        error={errors}
                    />
                </div>
                <div className='col-12 col-md-4 p-0'>
                    <Control
                        label='Email'
                        name='email'
                        type='email'
                        value={user.email}
                        change={updateData}
                        error={errors}
                    />
                </div>
                <div className='col-12 col-md-4 p-0'>
                    <Control
                        label='Téléphone'
                        name='phone'
                        type='tel'
                        value={user.phone}
                        change={updateData}
                        error={errors}
                    />
                </div>
                <div className='col-12 col-md-4 p-0'>
                    <Control
                        label='Ville'
                        name='cityName'
                        type='text'
                        value={user.location.cityName}
                        change={updateData}
                        error={errors}
                    />
                </div>
                <div className='col-12 col-md-4 p-0'>
                    <Control
                        label='Code postal'
                        name='postalCode'
                        type='text'
                        value={user.location.postalCode}
                        change={updateData}
                        error={errors}
                    />
                </div>
                <div className='col-12 col-md-4 p-0'>
                    <Control
                        label='Assigner une date'
                        name='formationDate'
                        type='date'
                        value={user.formation.formationDate}
                        change={updateData}
                        error={errors}
                    />
                </div>
                <div className='col-12 col-md-4 p-0'>
                    <Control
                        label='Formation effectuée ?'
                        name='isFormationOk'
                        type='checkbox'
                        checked={user.formation.isFormationOk}
                        change={(e) =>
                            setUser({
                                ...user,
                                formation: {
                                    ...user.formation,
                                    isFormationOk: e.target.checked,
                                },
                            })
                        }
                    />
                </div>
                {/* <div className='col-12 col-md-4 p-0'>
                    <Control
                        label='CGU acceptées ?'
                        name='isCGUAccepted'
                        type='checkbox'
                        checked={user.isCGUAccepted}
                        change={(e) =>
                            setUser({
                                ...user,
                                isCGUAccepted: e.target.checked,
                            })
                        }
                    />
                </div> */}
                <div className='col-12 col-md-4 p-0'>
                    <Control
                        label='Souscription à la newsletter ?'
                        name='isNewsletter'
                        type='checkbox'
                        checked={user.isNewsletter}
                        change={(e) =>
                            setUser({ ...user, isNewsletter: e.target.checked })
                        }
                    />
                </div>
                <div className='d-flex justify-content-end w-100'>
                    <SaveBtn
                        save={save}
                        isSaving={isLoading}
                        text='Enregistrer'
                        className='btn btn-success btn-save'
                    />
                </div>
            </div>
        </Page>
    );
};

export default AspiringProspectorDetail;
