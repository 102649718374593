import React from "react";
import ReactApexChart from "react-apexcharts";
import Loader from "../../common/Loader";
import SaveBtn from "../../common/SaveBtn";
import Colors from "../../../theme/variables.scss";
import useMissionChart from "./useMissionChart";

const MissionChart = ({ url, year, displayTotal }) => {
  const { isLoading, chartState, getDatas, hasDatas, total } = useMissionChart(
    url,
    year
  );

  return (
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-end">
        <SaveBtn
          type={hasDatas ? "btn-default d-center" : "btn-primary d-center"}
          loaderColor={hasDatas ? Colors.primary : Colors.default}
          margin="m-0 get-stat-btn"
          save={getDatas}
          isSaving={isLoading}
          text={
            hasDatas ? (
              <>
                <i className="fa fa-sync mr-2" />
                Actualiser les données
              </>
            ) : (
              <>
                <i className="fa fa-cloud-download-alt mr-2" />
                Charger les données
              </>
            )
          }
        />
      </div>
      {!isLoading && hasDatas ? (
        <>
          <div className="col-12 col-md-4">
            <ReactApexChart
              options={chartState.pie1.options}
              series={chartState.pie1.series}
              type="pie"
              height={350}
            />
          </div>
          <div className="col-12 col-md-4">
            <ReactApexChart
              options={chartState.pie2.options}
              series={chartState.pie2.series}
              type="pie"
              height={350}
            />
          </div>
          <div className="col-12 col-md-4">
            <ReactApexChart
              options={chartState.pie3.options}
              series={chartState.pie3.series}
              type="pie"
              height={350}
            />
          </div>

          <div className="col-12 text-center font-weight-bold">
            TOTAL : {total}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default MissionChart;
