import React from "react";
import { toast } from "react-toastify";

const CopyToClipboard = ({
    children,
    target,
    toastMessage = "Copié dans le presse-papier !",
}) => {
    const copy = () => {
        navigator.clipboard.writeText(target).then(toast.success(toastMessage));
    };

    return (
        <div style={{ cursor: "pointer" }}>
            {children}
            <i
                className='fas fa-clipboard text-info'
                onClick={copy}
                style={{
                    position: "absolute",
                    right: "37px",
                    top: "35px",
                    height: "30px",
                    width: "30px",
                    padding: "0px 5px",
                    minHeight: "0px",
                    borderRadius: "50%",
                    zIndex: "9",
                    display: "grid",
                    placeItems: "center",
                }}
            />
        </div>
    );
};

export default CopyToClipboard;
