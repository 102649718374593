import React from "react";
const SectionSkeleton = ({ actionBtn }) => {
  return (
    <div className="position-relative w-100 skeleton">
      <div
        className="d-flex align-items-center justify-content-center"
        // style={{
        //   position: "absolute",
        //   width: "100%",
        //   height: "100%",
        //   left: 0,
        //   top: 0,
        //   zIndex: 9,
        // }}
      >
        {actionBtn}
      </div>
    </div>
  );
};

export default SectionSkeleton;
