import React, { useState, useEffect, useRef } from "react";
import Control from "../../common/Controls/Control";
import Axios from "axios";
import { API_URL } from "../../../config";
import Loader from "../../common/Loader";
import useStore from "../../../context/useStore";
import { Scrollbars } from "react-custom-scrollbars";
import QuestionDetail from "../QuestionDetail/QuestionDetail";
import tools from "../../../helpers/tools";
import SectionSkeleton from "../../common/layout/SectionSkeleton";
import QuestionEditor from "./QuestionEditor";
import ConditionDisplay from "./ConditionDisplay";
import { toast } from "react-toastify";
import FormGenerator from "./FormGenerator/FormGenerator";
import SaveBtn from "../../common/SaveBtn";
import SubThemeSelectInput from "../QuestionDetail/SubThemeSelectInput";
import toolsFormGen from "./toolsFormGen";
import { type } from "jquery";
import MissionFormPreview from "./MissionPreview/MissionFormPreview";
import { DateTime } from "luxon";

var qcount = 0;
const FormEditor = ({ missionId }) => {
  const [state, dispatch] = useStore();
  const constants = state.constants.items;
  const [questionOrigins, setQuestionOrigins] = useState([]);
  const [sections, setSections] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(-1);
  const [selectedSection, setSelectedSection] = useState(null);
  const [newQuestion, setNewQuestion] = useState(false);
  const [lang, setLang] = useState(false);
  const [count, setCount] = useState(0);
  const [originItem, setOriginItem] = useState(-1);
  const [originSection, setOriginSection] = useState(-1);
  const qlist = useRef();
  const [allSectionsToggle, setAllSectionsToggle] = useState(false);
  const [canRemoveQuestion, setCanRemoveQuestion] = useState(-1);
  const [canRemoveSection, setCanRemoveSection] = useState(-1);
  const [mode, setMode] = useState("create");
  const [formId, setFormId] = useState(false);
  const [refreshTog, setRefreshTog] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [subTheme, setSubTheme] = useState("");
  const [errors, setErrors] = useState({});
  const [randomReset, setRandomReset] = useState(false);
  const [canAddQuestion, setCanAddQuestion] = useState(true);
  const [defaultInitialOrder, setDefaultInitialOrder] = useState(1);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getQuestions();
    getProducts();
    if (missionId != "create") {
      getForm();
    }
  }, [missionId]);

  const getProducts = () => {
    Axios.get(API_URL + "products")
      .then((res) => {
        setProducts(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function getForm() {
    Axios.get(API_URL + "missions/" + missionId + "/form")
      .then((res) => {
        setLang(res.data.lang);
        setFormId(res.data.id);
        setSections(res.data.sections);
        setQuestions(res.data.questions);
        setDefaultInitialOrder(res.data.questions.length + 1);
        setMode("patch");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    var anchorCol = document.querySelector("#anchor-col");
    var scrollCol = document.querySelector("#scroll-col");
    var qsel = document.querySelector(".q-selected");
    if (qsel) {
      var rect = qsel.getBoundingClientRect();
      var anch = anchorCol.getBoundingClientRect();
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      scrollCol.style.top =
        rect.top - (anch.top + scrollTop) + scrollTop + "px";
    }
    //scrollCol.style.top =
  }, [selectedQuestion]);
  useEffect(() => {
    if (count > 0) {
      //setSelectedQuestion(count - 1);
      // qlist.current.scrollToBottom();
    }
  }, [count]);
  useEffect(() => {
    setCanRemoveQuestion(-1);
  }, [sections, questions, selectedQuestion, originItem, originSection]);
  useEffect(() => {
    if (newQuestion) {
      var qs = JSON.parse(JSON.stringify(questions));
      qs[selectedQuestion] = {
        ...qs[selectedQuestion],
        subTheme: newQuestion.subTheme,
        type: parseInt(newQuestion.type),
        question: newQuestion.question[lang],
        questionOrigin: newQuestion.id,
        description: newQuestion.description[lang],
        answers: newQuestion.answers,
        condition: "",
        inputParams: newQuestion.inputParams,
        image: undefined,
      };
      setSubTheme(newQuestion.subTheme);
      qs[selectedQuestion].answers.map((a) => {
        a.label = a.label[lang];
        return a;
      });
      setQuestions(qs);
      getQuestions();
      setNewQuestion(false);
      document.querySelector("#close-modal").click();
    }
  }, [newQuestion]);
  useEffect(() => {
    if (originItem != -1) setOriginSection(-1);
  }, [originItem]);
  useEffect(() => {
    if (originSection != -1) setOriginItem(-1);
  }, [originSection]);

  function getQuestions() {
    Axios.get(API_URL + "questions/generic")
      .then((res) => {
        setQuestionOrigins(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function addQuestion(sec) {
    if (!canAddQuestion) {
      alert("Une des question n'a pas été complètement configurée");
      return false;
    }
    tools.saveState(`Questions_${missionId}`, "questions", questions)
    var qs = JSON.parse(JSON.stringify(questions));

    qs.push({
      subTheme: sections[sec].subTheme,
      question: "",
      type: "",
      questionOrigin: null,
      description: "",
      answers: [],
      condition: "",
      section: sec,
      coef: "",
      isScoring: false,
      initialOrder: defaultInitialOrder + 1,
      image: "",
      product: "",
    });
    setDefaultInitialOrder(defaultInitialOrder + 1);
    qs = tools.sortQuestions(qs);

    setQuestions(qs);
    setCount((c) => c + 1);
    setOriginItem(-1);
    setSelectedQuestion(-1);
  }
  function removeQuestion(e, k) {
    e.stopPropagation();

    if (canRemoveQuestion == k) {
      var qs = JSON.parse(JSON.stringify(questions));
      qs.splice(k, 1);
      qs = tools.sortQuestions(qs);
      setQuestions(qs);
      setCount((c) => c - 1);
      setOriginItem(-1);
      setCanRemoveQuestion(-1);
      setSelectedQuestion(-1);
      setErrors({});
    } else {
      setCanRemoveQuestion(k);
    }
  }
  function populateSelectedQuestion(e, k) {
    var qs = JSON.parse(JSON.stringify(questions));
    var id = e.target.value;
    var qsel = JSON.parse(
      JSON.stringify(questionOrigins.find((q) => q.id == id))
    );

    qs[selectedQuestion] = {
      ...qs[selectedQuestion],
      subTheme: qsel.subTheme,
      type: qsel.type,
      question: qsel.question[lang],
      questionOrigin: qsel.id,
      description: qsel.description[lang],
      answers: qsel.answers,
      condition: qsel.condition || "",
      inputParams: qsel.inputParams,
      isScoring: qsel.isScoring,
      coef: qsel.coef,
    };
    qs[selectedQuestion].answers.map((a) => {
      a.label = a.label[lang];
      a.score = a.score ? a.score : 3;
      return a;
    });
    delete qs[selectedQuestion].image;
    setSubTheme(qsel.subTheme);

    setQuestions(qs);
  }
  function updateQuestion(e) {
    var qs = JSON.parse(JSON.stringify(questions));

    var { value, name, type, k } = e.target;
    if (type == "checkbox") {
      value = e.target.checked;
    }
    qs[k][name] = value;

    setQuestions(qs);
  }
  function updateQuestionAnswer(e, lk) {
    var qs = JSON.parse(JSON.stringify(questions));
    qs[e.target.k].answers[lk].label = e.target.value;
    setQuestions(qs);
  }
  function updateQuestionAnswerScore(e, lk) {
    var qs = JSON.parse(JSON.stringify(questions));
    qs[e.target.k].answers[lk].score = e.target.value;
    setQuestions(qs);
  }
  function updateInputParams(e, qk, key) {
    var qs = JSON.parse(JSON.stringify(questions));
    qs[qk].inputParams[key] = e.target.value;
    setQuestions(qs);
  }
  function addSection() {
    var sc = JSON.parse(JSON.stringify(sections));
    sc.push({
      name: "Section " + (sc.length + 1),
      subTheme: "",
    });

    setSections(sc);
    setSelectedSection(sc.length - 1);
  }
  function updateSection(e) {
    var sc = JSON.parse(JSON.stringify(sections));
    sc[e.target.k][e.target.name] = e.target.value;
    setSections(sc);
  }
  function removeSection(k) {
    if (canRemoveSection == k) {
      var sc = JSON.parse(JSON.stringify(sections));
      var qs = JSON.parse(JSON.stringify(questions));

      qs = qs.filter((q) => q.section != parseInt(k));
      qs = qs.map((q) => {
        if (q.section > k) {
          q.section = q.section - 1;
        }
        return q;
      });

      sc.splice(k, 1);
      setErrors({});
      setQuestions(qs);
      setSections(sc);

      setCanRemoveSection(-1);
    } else {
      setCanRemoveSection(k);
    }
  }
  function updatePosition(e, targetPos, isTargetSection = false) {
    e.stopPropagation();
    var qs = JSON.parse(JSON.stringify(questions));

    if (!isTargetSection) {
      if (targetPos == qs.length || targetPos < 0) {
        return;
      }
      var sectionTo = qs[targetPos].section;
      qs[originItem].section = sectionTo;

      var qsAfterMove = tools.arrayMove(qs, originItem, targetPos);
      var newQsConditions = tools.calculateNewConditions(qsAfterMove);

      if (newQsConditions) {
        qs = newQsConditions ? newQsConditions : questions;
        setQuestions(qs);
        setSelectedQuestion(targetPos);
      }
    } else {
      var sec = JSON.parse(JSON.stringify(sections));

      if (targetPos == sec.length || targetPos < 0) {
        return;
      }

      qs[originItem].section = targetPos;
      var newQsConditions = tools.calculateNewConditions(qs);
      qs = newQsConditions ? newQsConditions : questions;
      if (newQsConditions) {
        setQuestions(qs);
      }
      //setSelectedQuestion(qs.indexOf);
    }
    setOriginItem(-1);
  }
  function updateSectionPosition(e, targetPos) {
    e.stopPropagation();
    var qs = JSON.parse(JSON.stringify(questions));
    var sec = JSON.parse(JSON.stringify(sections));

    if (targetPos == sec.length || targetPos < 0) {
      return;
    }

    let targSecs = JSON.parse(JSON.stringify(qs)).filter(
      (q) => q.section == originSection
    );

    targSecs = targSecs.map((t) => {
      t.section = targetPos;
      return t;
    });

    let origSecs = JSON.parse(JSON.stringify(qs)).filter(
      (q) => q.section != originSection
    );

    origSecs = origSecs.map((q) => {
      if (q.section > originSection) {
        q.section = q.section - 1;
      }
      return q;
    });

    origSecs = origSecs.map((q) => {
      if (q.section >= targetPos) {
        q.section = q.section + 1;
      }
      return q;
    });

    qs = origSecs.concat(targSecs);

    sec = tools.arrayMove(sec, originSection, targetPos);

    var newQsConditions = tools.calculateNewConditions(qs);
    qs = newQsConditions ? newQsConditions : questions;
    if (newQsConditions) {
      setQuestions(qs);
      setSections(sec);
      setSelectedQuestion(-1);
    }

    setOriginSection(-1);
  }
  function toggleSection(sk) {
    document.querySelector("#section" + sk).classList.toggle("listHidden");
  }
  function toggleAllSections() {
    var sectionElems = document.querySelectorAll(".section-item");
    [].forEach.call(sectionElems, function (div) {
      // do whatever
      if (allSectionsToggle) div.classList.remove("listHidden");
      else div.classList.add("listHidden");

      setAllSectionsToggle(!allSectionsToggle);
    });
  }
  function save() {
    setRefreshTog(false);
    var quests = toolsFormGen.formatForApi(
      JSON.parse(JSON.stringify(questions))
    );
    var data = {
      lang: lang,
      mission: missionId,
      questions: quests,
      sections: sections,
    };
    setIsSaving(true);
    if (mode == "create") {
      data.id = formId;
      Axios.post(API_URL + "missions/" + missionId + "/form", data)
        .then((res) => {
          setMode("patch");
          setRefreshTog(true);
          setIsSaving(false);
          setErrors({});
          getForm();

          toast.success("Le questionnaire a bien été enregistré");
        })
        .catch((err) => {
          setIsSaving(false);
          if (err.response && err.response.data) {
            setErrors(err.response.data);
            toast.error(err.response.data.other);
          }
        });
    } else {
      data.id = formId;
      Axios.patch(API_URL + "missions/" + missionId + "/form", data)
        .then((res) => {
          setMode("patch");
          setRefreshTog(true);
          setIsSaving(false);
          setErrors({});
          getForm();
          toast.success("Le questionnaire a bien été enregistré");
        })
        .catch((err) => {
          setIsSaving(false);

          if (err.response && err.response.data) {
            setErrors(err.response.data);
            toast.error(err.response.data.other);
          }
        });
    }
  }

  useEffect(() => {
    var canAddNew = true;
    questions.forEach((q) => {
      if (!q.question) {
        canAddNew = false;
      }
    });
    setCanAddQuestion(canAddNew);
  }, [questions]);

  useEffect(() => {
    if (questions[selectedQuestion]) {
      setSubTheme(questions[selectedQuestion].subTheme);
    }
  }, [selectedQuestion]);
  
  function loadLastQuestionsState() {
    if (window.confirm("Attention, vous allez revenir à l'état du formulaire avant le dernier enregistrement.")) {
      let qs = tools.getState(`Questions_${missionId}`, { questions })
      setQuestions(qs.questions)
    }
  }

  return !questionOrigins || !constants.LANGUAGES ? (
    <Loader />
  ) : missionId == "create" ? (
    <p className="mt-4">Enregistrez d'abord les paramètres</p>
  ) : !lang ? (
    <div className="form-group mt-4">
      <label>
        Langue
        <ul className="nav nav-tabs mb-3">
          {constants.LANGUAGES.map((ln, lk) => (
            <li className="nav-item" key={"cstlan" + lk}>
              <a
                className={"nav-link " + (ln == lang ? "active" : "default")}
                onClick={() => setLang(ln)}
                data-toggle="tab"
                href="#FRtab"
                role="tab"
              >
                {ln}
              </a>
            </li>
          ))}
        </ul>
      </label>
    </div>
  ) : (
    <div className="row my-4 position-relative form-editor">
      <div
        className="col-5 text-center"
        id="anchor-col"
        style={{ borderRight: "1px solid #eaeaea" }}
      >
        {/* <div style={{ position: "relative", height: 46 }}>
          <button
            className="btn btn-default nobx"
            style={{
              position: "absolute",

              right: "3%",
              zIndex: 999,
            }}
            onClick={() => toggleAllSections()}
          >
            <i className="fab fa-buffer"></i>
          </button>
        </div> */}
        <ul className="nav nav-tabs flex-column" id="qlist">
          {sections.map((s, sk) => (
            <li
              key={"section" + sk}
              className="section-item bx-outset p-3 mb-3 animated fadeInUp fast"
              id={"section" + sk}
            >
              {originSection > -1 && originItem == -1 ? (
                originSection != sk ? (
                  <button
                    className="btn btn-success animated fadeInLeft fast position-absolute"
                    style={{ left: "-13%", padding: "8px 13px" }}
                    onClick={(e) => updateSectionPosition(e, sk)}
                  >
                    <i className="fa fa-chevron-right"></i>
                  </button>
                ) : (
                  <button
                    className="btn animated fadeInLeft fast position-absolute"
                    style={{ left: "-13%", padding: "8px 13px" }}
                    onClick={(e) => setOriginSection(-1)}
                  >
                    <i className="fa fa-times"></i>
                  </button>
                )
              ) : (
                ""
              )}

              {originItem > -1 && originSection == -1 && (
                <button
                  className="btn animated fadeInLeft fast position-absolute"
                  style={{ left: "-13%", padding: "8px 13px" }}
                  onClick={(e) => updatePosition(e, sk, true)}
                >
                  <i className="fa fa-chevron-right"></i>
                </button>
              )}

              <button
                className={
                  "btn position-absolute " +
                  (originSection == sk ? "btn-primary" : "btn-light")
                }
                style={{
                  left: "4%",
                  fontSize: "14px",
                  height: 38,
                  lineHeight: "20px",
                  padding: "0 20px",
                  zIndex: 9,
                  textTransform: "uppercase",
                }}
                onClick={() => setOriginSection(sk)}
              >
                {String.fromCharCode(96 + sk + 1)}
              </button>

              <Control
                k={sk}
                type="text"
                value={s.name}
                name="name"
                label="Nom de la section"
                change={updateSection}
                style={{ fontWeight: "bold", paddingLeft: "17% !important" }}
                containerStyle={{ paddingLeft: "14%" }}
                suffix={
                  <button
                    className="btn btn-sm btn-danger position-absolute"
                    style={{ right: 0, height: "100%" }}
                    onClick={() => removeSection(sk)}
                  >
                    <i
                      className={
                        "fa fa-" + (canRemoveSection == sk ? "check" : "times")
                      }
                    ></i>
                  </button>
                }
              />
              <SubThemeSelectInput
                k={sk}
                updateData={updateSection}
                value={s.subTheme}
                name="subTheme"
                style={{ paddingLeft: "14%" }}
              />
              <span className="question-count d-block">
                {questions.filter((q) => q.section == sk).length} question(s)
              </span>
              <ul style={{ padding: 0, listStyle: "none" }}>
                {questions.map((q, qk) => {
                  if (q.section == sk) {
                    return (
                      <li
                        key={"q" + qk}
                        className={
                          "nav-item my-2 position-relative animated fadeInUp faster "
                        }
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSelectedQuestion(qk);
                          setSelectedSection(q.section);
                        }}
                      >
                        {errors.questions ? (
                          errors.questions[qk] ? (
                            <i
                              className="fa fa-exclamation-triangle text-danger"
                              onClick={() => alert(errors.questions[qk])}
                              title={errors.questions[qk]}
                              style={{
                                position: "absolute",
                                left: -25,
                                top: 12,
                              }}
                            />
                          ) : null
                        ) : null}
                        {originItem > -1 ? (
                          originItem != qk ? (
                            <button
                              className="btn btn-success animated fadeInLeft fast position-absolute"
                              style={{ left: "-13%", padding: "8px 13px" }}
                              onClick={(e) => updatePosition(e, qk)}
                            >
                              <i className="fa fa-chevron-right"></i>
                            </button>
                          ) : (
                            <button
                              className="btn animated fadeInLeft fast position-absolute"
                              style={{ left: "-13%", padding: "8px 13px" }}
                              onClick={(e) => setOriginItem(-1)}
                            >
                              <i className="fa fa-times"></i>
                            </button>
                          )
                        ) : (
                          ""
                        )}

                        <a
                          className={
                            "nav-link text-left " +
                            (selectedQuestion == qk ? "active  q-selected" : "")
                          }
                        >
                          <button
                            className={
                              "btn position-absolute bg-light " +
                              (originItem == qk ? "btn-primary" : "")
                            }
                            style={{
                              left: 0,
                              top: 0,
                              fontSize: "14px",
                              height: 36,
                              lineHeight: "20px",
                              padding: "0 10px",
                              minHeight: 0,
                            }}
                            onClick={() => setOriginItem(qk)}
                          >
                            {qk + 1}
                          </button>
                          <span
                            style={{
                              width: "70%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {q.question != "" ? (
                              q.question
                            ) : (
                              <i>nouvelle questions</i>
                            )}
                          </span>
                          <ConditionDisplay
                            condition={q.condition}
                            questions={questions}
                          />
                          <button
                            onClick={(e) => removeQuestion(e, qk)}
                            className="btn btn-danger position-absolute"
                            style={{
                              right: 0,
                              top: 0,
                              fontSize: "14px",
                              height: 36,
                              lineHeight: "20px",
                              padding: "0 10px",
                              minHeight: 0,
                            }}
                          >
                            <i
                              className={
                                "fa fa-" +
                                (canRemoveQuestion == qk ? "check" : "times")
                              }
                            ></i>
                          </button>
                        </a>
                      </li>
                    );
                  } else {
                    return false;
                  }
                })}
              </ul>
              <button
                className="btn btn-default my-2"
                onClick={() => addQuestion(sk)}
              >
                <i className="fa fa-plus mr-2" />
                Ajouter une question
              </button>
              {/* <button
                className="btn btn-default my-2 nobx"
                style={{
                  position: "absolute",
                  bottom: 3,
                  right: "3%",
                  zIndex: 999,
                  width: 60,
                }}
                onClick={() => toggleSection(sk)}
              >
                <i className="fab fa-buffer"></i>
              </button> */}
            </li>
          ))}
        </ul>
        <SectionSkeleton
          actionBtn={
            <button
              className={
                "btn w-100 h-100 nobx w-100 d-flex justify-content-center align-items-center"
              }
              style={{
                opacity: ".43",
                color: "#4a4a4a",
              }}
              onClick={addSection}
            >
              <i className="fa fa-plus mr-3"></i>
              <span>Ajouter une section</span>
            </button>
          }
        />
      </div>
      <div
        className="col-7 position-absolute mb-5"
        style={{
          right: 0,
          top: 0,
          transition: " all 0.4s ease-in-out 0s",
          opacity: questions.length > 0 ? "1" : "0",
        }}
        id="scroll-col"
      >
        <div className="bg-white bx-outset p-4 border">
          {questions.map((q, qk) => {
            return (
              <div
                key={"qdet" + qk}
                className={qk == selectedQuestion ? "d-block" : "d-none"}
              >
                {!q.questionOrigin && (
                  <>
                    {" "}
                    <button
                      type="button"
                      style={{ height: 40, lineHeight: "16px" }}
                      className="btn btn-success mb-3 bx-inset px-3 ml-3"
                      data-toggle="modal"
                      data-target="#createQuestion"
                      onClick={() => setRandomReset(DateTime.local())}
                    >
                      <i className="fa fa-plus mr-2"></i>
                      Créer une nouvelle question
                    </button>
                    <SubThemeSelectInput
                      updateData={(e) => setSubTheme(e.target.value)}
                      value={subTheme}
                      name="subtheme"
                    />
                    <Control
                      label="Autocomplete me"
                      type="autocomplete"
                      value={q.questionOrigin}
                      name="questionOrigin"
                      autoCompleteDisplayIndex="name"
                      change={populateSelectedQuestion}
                      datas={questionOrigins
                        .filter(
                          (q) =>
                            (subTheme == "" ||
                              !subTheme ||
                              q.subTheme == subTheme) &&
                            q.isGeneric
                        )
                        .filter((qu) => {
                          return questions.find(
                            (qs) => qs.questionOrigin === qu.id
                          )
                            ? false
                            : true;
                        })
                        .map((q) => {
                          q.name = q.question[lang];
                          q.theme = tools.getSubThemeFromId(
                            constants.THEMES,
                            q.subTheme
                          );
                          return q;
                        })}
                      dataIndex="id" //default
                      dataLabel="name" //default (sera inclut dans la recherche)
                      dataLabel2="theme"
                      required={true}
                      btnInline={false}
                      hideAutocompleteOnFocusOut={false} //buggé
                      noResults={false} //si aucun résultat api
                      autoCompleteHasApiDatas={false} // true si afficher tous les résultats api
                    />
                  </>
                )}
                {q.questionOrigin && (
                  <QuestionEditor
                    key={`qe${qk}${q.id}`}
                    formId={formId}
                    THEMES={constants.THEMES}
                    INPUT_TYPES={constants.INPUT_TYPES}
                    updateQuestion={updateQuestion}
                    updateQuestionAnswer={updateQuestionAnswer}
                    updateQuestionAnswerScore={updateQuestionAnswerScore}
                    updateInputParams={updateInputParams}
                    qk={qk}
                    q={q}
                    questions={questions}
                    products={products}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className="w-100 mt-5 d-flex align-items-center justify-content-start">
        <button
          type="button"
          className="btn btn-danger d-inline-flex"
          onClick={loadLastQuestionsState}
        >
          <i className="fa fa-history mr-2"></i>
          Annuler
        </button>
        <button
          type="button"
          className="btn btn-default d-inline-flex"
          data-toggle="modal"
          data-target="#previewForm"
        >
          <i className="fa fa-eye mr-2"></i>
          Prévisualiser
        </button>
        <SaveBtn className=" d-inline-flex" save={() => save(false)} isSaving={isSaving} />
      </div>
      <div
        className="modal fade"
        id="createQuestion"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="createQuestionLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ maxWidth: "80%" }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="createQuestionLabel">
                Créer une nouvelle question
              </h5>
              <button
                id="close-modal"
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <QuestionDetail
                isModal={true}
                setNewQuestion={setNewQuestion}
                missionId={missionId}
                randomReset={randomReset}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="previewForm"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="previewFormLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ maxWidth: "80%" }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="previewFormLabel">
                Prévisualiser le questionnaire
              </h5>
              <button
                id="close-modal"
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {mode == "patch" && refreshTog && (
                <div
                  style={{
                    position: "relative",
                    margin: "0 auto",
                  }}
                >
                  <MissionFormPreview missionId={missionId} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormEditor;
