import React from "react";
import useApiDatas from "../../common/Hooks/useApiDatas";
import Page from "../../common/layout/Page";
import PostulationsController from "./PostulationsController";
import PostulationsFilters from "./PostulationsFilters";

const Postulations = () => {
  const {
    state,
    constants,
    errors,
    setErrors,
    isLoading,
    setIsLoading,
    searchDatas,
    setSearchDatas,
    nbPages,
    setNbPages,
    getSearchDatas,
    deleteData,
    isInit,
    search,
    setSearch,
    otherDatas,
    setOtherDatas,
  } = useApiDatas({
    resourcePath: "postulations",
    formatSearchDatasFn: PostulationsController.formatData,
    searchParams: {
      postulation: { searchString: "" },
      page: 1,
    },
  });
  return (
    <Page
      title="Liste des postulations"
      errors={errors}
      isLoading={isLoading}
      isInit={isInit}
    >
      <div className="row">
        <PostulationsFilters
          search={search}
          setSearch={setSearch}
          errors={errors}
        />
      </div>
    </Page>
  );
};

export default Postulations;
