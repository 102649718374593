import React, { useState } from "react";
import { toast } from "react-toastify";
import useStore from "../../../context/useStore";
import dataService from "../../../helpers/dataService";
import SaveBtn from "../../common/SaveBtn";
import FilterItem from "./FormGenerator/Filters/FilterItem";

const MissionFilters = ({ mission, setMission }) => {
  const [state, dispatch] = useStore();
  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const filters = state.constants.items.MISSION_FILTERS;

  const updateFilters = (filters) => {
    setMission({ ...mission, filters });
  };

  const save = () => {
    setIsSaving(true);
    dataService.patch(
      `missions/${mission.id}/filters`,
      { filters: mission.filters },
      (data) => {
        setMission(data);
        toast.success("Les filtres ont bien été enregistrés");
      },
      setErrors,
      () => setIsSaving(false)
    );
  };

  return (
    <div>
      <ul className="list-group">
        {filters.map((f, fk) => (
          <FilterItem
            filter={f}
            key={`fil${fk}`}
            missionFilters={mission.filters}
            updateFilters={updateFilters}
            error={errors.filters ? errors.filters[fk] : false}
          />
        ))}
      </ul>
      <div className="d-flex w-100 align-items-center justify-content-end">
        <SaveBtn save={save} isSaving={isSaving} text="Enregistrer" />
      </div>
    </div>
  );
};

export default MissionFilters;
