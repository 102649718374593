import React, { useState } from "react";
import Chart from "react-apexcharts";

const BarChart = ({ categories, data, name }) => {
    const [chartState, setChartState] = useState({
        options: {
            chart: {
                id: "bar-chart"
            },
            xaxis: {
                categories: categories,
            },
            yaxis: {
                labels: {
                    formatter: (value) => `${Number(value).toFixed(2)}€`
                },
            },
        },
        series: [
            {
                name: name,
                data: data,
            }
        ]
    })

    return (
        <div className="row mt-4">
            <Chart
                options={chartState.options}
                series={chartState.series}
                type="bar"
                width="500"
            />
        </div>
    )
}

export default BarChart