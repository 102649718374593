import React from "react";
import Control from "../../common/Controls/Control";

const AspiringProspectorListFilter = ({ search, setSearch }) => {
    return (
        <div className="row">
            <div className="col">
                <Control
                    label="Statut"
                    value={search.user.status}
                    type="btnList"
                    btnInline
                    datas={[
                        { id: "waiting", name: "En attente" },
                        { id: "progress", name: "Date attribuée" },
                        { id: "finished", name: "Formation terminée" },
                    ]}
                    change={(e) =>
                        setSearch({
                            ...search,
                            user: { ...search.user, status: e.target.value },
                        })
                    }
                />
            </div>
            <div className="col">
                <Control
                    label="Paiement"
                    value={search.user.isFormationPaid}
                    type="btnList"
                    btnInline
                    datas={[
                        { id: "true", name: "Paiement effectué" },
                        { id: "false", name: "Paiement en attente" },
                    ]}
                    change={(e) =>
                        setSearch({
                            ...search,
                            user: {
                                ...search.user,
                                isFormationPaid: e.target.value,
                            },
                        })
                    }
                />
            </div>
        </div>
    );
};

export default AspiringProspectorListFilter;
