import { DateTime } from "luxon";
import React from "react";
import { image_url } from "../../../config";
import tools from "../../../helpers/tools";
import Picture from "../Picture/Picture";

const ImageGrid = ({ image, containerClassname = "" }) => {
  let imageUrl = image_url + image.fileName;

  const openImage = (url) => {
    window.open(url);
  };

  return (
    <div
      className={`cursor-pointer ${containerClassname}`}
      onClick={() => openImage(imageUrl)}
    >
      <div className="border p-2">
        {image.uploadedBy.lastname && (
          <div className="font-weight-bold">
            {tools.getFullName(image.uploadedBy)}
          </div>
        )}
        <div className="font-weight-bold">
          le {DateTime.fromISO(image.createdAt).toFormat("dd/MM/yyyy hh:mm")}
        </div>
        <Picture
          img={{
            url: imageUrl,
            width: "100%",
            height: "100%",
            alt: "",
          }}
        />
      </div>
    </div>
  );
};

export default ImageGrid;
