import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import tools from "../../../../helpers/tools";
import ImageGrid from "../../../common/ImageGrid/ImageGrid";
import Loader from "../../../common/Loader";
import SaveBtn from "../../../common/SaveBtn";
import Stars from "../../../common/Stars/Stars";

const NotationItem = ({
  notation,
  scores,
  isSaving,
  actions = {
    openProfile: () => {},
    openAnswers: () => {},
    updateScore: () => {},
    updatePhotoScore: () => {},
    saveNotation: () => {},
  },
  frozen = false,
  isLoadingAnswers,
}) => {
  const [commentVisible, setCommentVisible] = useState(frozen ? false : true);
  const [photosVisible, setPhotosVisible] = useState(frozen ? false : true);

  const gloalPhotosScore = tools.calcPhotosScore(notation.photos);

  return (
    <div className="row border p-2 m-0">
      <div className="col-12 col-md-8 d-center">
        <strong style={{ fontSize: 24 }}>
          {tools.getFullName(notation.userProspector)}
        </strong>
      </div>
      <div className="col-12 col-md-4 d-center flex-column">
        <button
          className={`btn btn-primary ${frozen ? "btn-sm" : ""}`}
          onClick={() => actions.openProfile(notation.userProspector.id)}
        >
          Voir le profil
        </button>
        {isLoadingAnswers ? (
          <Loader />
        ) : (
          <button
            className={`btn btn-secondary mt-2  ${frozen ? "btn-sm" : ""}`}
            onClick={() => actions.openAnswers(notation.id)}
          >
            Voir les réponses
          </button>
        )}
      </div>
      <div className="col-12">
        <div className="row mt-3">
          <div className="col-12 col-md-8 ">
            <strong>
              Commentaire{" "}
              {frozen && (
                <i
                  className="fa fa-eye cursor-pointer ml-2"
                  onClick={() => setCommentVisible(!commentVisible)}
                />
              )}
            </strong>
            {frozen && !commentVisible ? null : (
              <p className="alert alert-info">{notation.comment}</p>
            )}
          </div>
          <div className="col-12 col-md-4 d-center flex-column">
            <strong className="mb-2">Note du commentaire</strong>
            <Stars
              score={scores[notation.id]["commentScore"]}
              setScore={(score) =>
                frozen
                  ? null
                  : actions.updateScore(notation.id, "commentScore", score)
              }
              size={2}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 col-md-8">
            <strong>
              Photos{" "}
              {frozen && (
                <i
                  className="fa fa-eye cursor-pointer ml-2"
                  onClick={() => setPhotosVisible(!photosVisible)}
                />
              )}
            </strong>
            {frozen && !photosVisible ? null : (
              <div
                className="row w-100 mx-0"
                style={{ maxHeight: 500, overflow: "scroll" }}
              >
                {notation.photos.map((p, pk) => (
                  <div
                    className="col-4 p-0"
                    key={`photosans${notation.id}${p.id}`}
                  >
                    <div className="col-12">
                      Note :
                      <Stars
                        score={p.score}
                        setScore={(score) =>
                          frozen
                            ? null
                            : actions.updatePhotoScore(notation.id, p.id, score)
                        }
                        size={1}
                      />
                    </div>
                    <ImageGrid image={p} containerClassname={"col-12 p-0"} />
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="col-12 col-md-4 d-center flex-column">
            <strong className="mb-2">Note des photos</strong>
            <Stars
              score={gloalPhotosScore}
              setScore={(score) => null}
              size={2}
            />
          </div>
        </div>
      </div>
      {frozen && (
        <div className="col-12 col-md-4 offset-8 text-center mt-4">
          <div>
            évalué par <strong>{tools.getFullName(notation.ratedBy)}</strong>
          </div>
          <div>
            le{" "}
            <strong>
              {DateTime.fromISO(notation.ratedAt).toFormat(
                "dd/MM/yyyy à hh:mm"
              )}
            </strong>
          </div>
        </div>
      )}
      {frozen ? null : (
        <div className="col-12">
          <SaveBtn
            className="w-100"
            save={() => actions.saveNotation(notation)}
            isSaving={isSaving}
          />
        </div>
      )}
    </div>
  );
};

export default NotationItem;
