import React from "react";
import Colors from "../../../theme/variables.scss";

const Stars = ({ score, setScore, size }) => {
  const getScore = () => {
    var stars = [];
    for (let i = 1; i <= 5; i++) {
      if (i <= score) {
        stars.push(
          <i
            key={`stiscore${i}${size}`}
            onClick={() => setScore(i)}
            style={{ color: Colors.primary }}
            className={`fa fa-star cursor-pointer ${size ? `fa-${size}x` : ""}`}
          />
        );
      } else {
        stars.push(
          <i
            key={`stiscore${i}${size}`}
            onClick={() => setScore(i)}
            style={{ color: Colors.primary }}
            className={`far fa-star cursor-pointer  ${
              size ? `fa-${size}x` : ""
            }`}
          />
        );
      }
    }
    return stars;
  };
  return <div>{getScore()}</div>;
};

export default Stars;
