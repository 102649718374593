import React, { useEffect, useState } from "react";
import Page from "../../common/layout/Page";
import FormEditor from "./FormEditor";
import Axios from "axios";
import MissionParameters from "./MissionParameters";
import { API_URL } from "../../../config";
import { toast } from "react-toastify";
import Control from "../../common/Controls/Control";
import SaveBtn from "../../common/SaveBtn";
import { DateTime } from "luxon";
import Postulations from "./Postulations";
import MissionFilters from "./MissionFilters";
import Report from "./Report/Report";
import Notation from "./Notation/Notation";
import MissionFormRogue from "./MissionRogue/MissionFormRogue";

const missionInitialState = {
  name: "",
  userProspect: "",
  isAttributionAuto: false,
  isSurvey: false,
  typeReward: 1,
  rewards: [0, 0, 0],
  nbReport: 0,
  isRewardAuto: false,
  beginAt: DateTime.local().toFormat("yyyy-MM-dd"),
  endAt: DateTime.local().toFormat("yyyy-MM-dd"),
  answerMaxDelay: 24,
  address: "",
  addressDescription: "",
  location: { type: "Point", coordinates: [0, 0] },
  radius: 0,
  description: "",
  help: "",
  images: [],
  status: "",
  isTemplate: false,
  filters: [],
};

const AVAILABLE_TABS = [
  "parameters",
  "questions",
  "postulations",
  "filters",
  "report",
  "notation",
  "rogue",
];

const MissionDetail = (props) => {
  const [missionId, setMissionId] = useState(props.match.params.id);

  const [tab, setTab] = useState(() => {
    const search = props.location.search;
    const params = new URLSearchParams(search);
    const tab = params.get("tab");

    return AVAILABLE_TABS.includes(tab) ? tab : "parameters"; // defaulting to parameters tab
  });

  const [form, setForm] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [missionTemplate, setMissionTemplate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mission, setMission] = useState(missionInitialState);

  useEffect(() => {
    Axios.get(API_URL + "missions/templates")
      .then((res) => {
        setTemplates(res.data);
      })
      .catch((err) => {
        toast.error("Erreur lors de la récupération des templates de mission");
      });
  }, []);

  const setQueryParam = (tab) => {
    props.history.push({
      search: `?${new URLSearchParams({ tab }).toString()}`,
    });
    setTab(tab);
  };
  const createFromTemplate = () => {
    setIsLoading(true);
    Axios.post(API_URL + "missions/from/" + missionTemplate, {
      isTemplate: false,
    })
      .then((res) => {
        toast.success("La mission a été créée depuis le template");
        window.location.href = "/constats/" + res.data.id;
      })
      .catch((err) => {
        toast.error(
          "Une erreur s'est produite lors de la création de la mission depuis le template"
        );
      })
      .then(() => setIsLoading(false));
  };

  const isEdit = props.match.params.id != "create";

  return (
    <Page title="Constat" back="/constats" style={{ minHeight: 1200 }}>
      {mission.isDeleted ? (
        <div className="alert alert-danger">Ce constat a été annulé</div>
      ) : null}
      {!isEdit && (
        <div className="mb-5">
          <Control
            label="Charger depuis un template"
            type="select"
            value={missionTemplate}
            change={(e) => setMissionTemplate(e.target.value)}
            datas={templates}
            dataLabel="name"
            dataIndex="id"
          />
          <SaveBtn
            text={"Créer la mission depuis le template"}
            isSaving={isLoading}
            save={createFromTemplate}
          />
        </div>
      )}
      {mission.isTemplate && (
        <div className="alert alert-warning">Ce constat est un template</div>
      )}
      <ul className="nav nav-tabs no-print">
        <li className="nav-item" onClick={() => setQueryParam("parameters")}>
          <a
            className={`nav-link ${tab === "parameters" ? "active" : ""}`}
            id="parameters-tab"
            data-toggle="tab"
            href="#parameters"
            role="tab"
            aria-controls="parameters"
            aria-selected={tab === "parameters"}
          >
            Paramètres
          </a>
        </li>
        <li className="nav-item" onClick={() => setQueryParam("questions")}>
          <a
            className={`nav-link ${tab === "questions" ? "active" : ""}`}
            id="questions-tab"
            data-toggle="tab"
            href="#questions"
            role="tab"
            aria-controls="questions"
            aria-selected={tab === "questions"}
          >
            Questions
          </a>
        </li>
        <li className="nav-item" onClick={() => setQueryParam("filters")}>
          <a
            className={`nav-link ${tab === "filters" ? "active" : ""}`}
            id="filters-tab"
            data-toggle="tab"
            href="#filters"
            role="tab"
            aria-controls="filters"
            aria-selected="false"
          >
            Filtres
          </a>
        </li>
        {!mission.isInternal && (
          <li
            className="nav-item"
            onClick={() => setQueryParam("postulations")}
          >
            <a
              className={`nav-link ${tab === "postulations" ? "active" : ""}`}
              id="postulations-tab"
              data-toggle="tab"
              href="#postulations"
              role="tab"
              aria-controls="postulations"
              aria-selected={tab === "postulations"}
            >
              Postulations
            </a>
          </li>
        )}
        <li className="nav-item" onClick={() => setQueryParam("report")}>
          <a
            className={`nav-link ${tab === "report" ? "active" : ""}`}
            id="report-tab"
            data-toggle="tab"
            href="#report"
            role="tab"
            aria-controls="report"
            aria-selected={tab === "report"}
          >
            Rapport
          </a>
        </li>
        {!mission.isInternal && (
          <li className="nav-item" onClick={() => setQueryParam("notation")}>
            <a
              className={`nav-link ${tab === "notation" ? "active" : ""}`}
              id="notation-tab"
              data-toggle="tab"
              href="#notation"
              role="tab"
              aria-controls="notation"
              aria-selected={tab === "notation"}
            >
              Notation
            </a>
          </li>
        )}
        {mission.isInternal && (
          <li className="nav-item" onClick={() => setQueryParam("rogue")}>
            <a
              className={`nav-link ${tab === "rogue" ? "active" : ""}`}
              id="rogue-tab"
              data-toggle="tab"
              href="#rogue"
              role="tab"
              aria-controls="rogue"
              aria-selected={tab === "rogue"}
            >
              Répondre au constat
            </a>
          </li>
        )}
      </ul>
      <div className="tab-content" id="myTabContent">
        <div
          className={`tab-pane fade ${
            tab === "parameters" ? "show active" : ""
          }`}
          id="parameters"
          role="tabpanel"
          aria-labelledby="parameters-tab"
        >
          <MissionParameters
            missionTemplate={missionTemplate}
            missionId={missionId}
            isEdit={isEdit}
            setMissionId={setMissionId}
            mission={mission}
            setMission={setMission}
          />
        </div>
        <div
          className={`tab-pane fade ${
            tab === "questions" ? "show active" : ""
          }`}
          id="questions"
          role="tabpanel"
          aria-labelledby="questions-tab"
        >
          <FormEditor missionId={missionId} />
        </div>
        <div
          className="tab-pane fade "
          id="filters"
          role="tabpanel"
          aria-labelledby="filters-tab"
        >
          {mission.id ? (
            <MissionFilters mission={mission} setMission={setMission} />
          ) : (
            <p>Enregistrez d'abord la mission</p>
          )}
        </div>
        <div
          className={`tab-pane fade ${
            tab === "postulations" ? "show active" : ""
          }`}
          id="postulations"
          role="tabpanel"
          aria-labelledby="postulations-tab"
        >
          {mission.id ? (
            <Postulations mission={mission} tab={tab} />
          ) : (
            <p>Enregistrez d'abord la mission</p>
          )}
        </div>
        <div
          className={`tab-pane fade ${tab === "report" ? "show active" : ""}`}
          id="report"
          role="tabpanel"
          aria-labelledby="report-tab"
        >
          {mission.id && tab === "report" ? (
            <Report mission={mission} tab={tab} />
          ) : (
            <p>Enregistrez d'abord la mission</p>
          )}
        </div>
        <div
          className={`tab-pane fade ${tab === "notation" ? "show active" : ""}`}
          id="notation"
          role="tabpanel"
          aria-labelledby="notation-tab"
        >
          {mission.id && tab === "notation" ? (
            <Notation mission={mission} tab={tab} />
          ) : (
            <p>Enregistrez d'abord la mission</p>
          )}
        </div>
        <div
          className={`tab-pane fade ${tab === "rogue" ? "show active" : ""}`}
          id="rogue"
          role="tabpanel"
          aria-labelledby="rogue-tab"
        >
          {mission.id && tab === "rogue" ? (
            <div>
              <MissionFormRogue missionId={missionId} />
            </div>
          ) : (
            <p>Enregistrez d'abord la mission</p>
          )}
        </div>
      </div>
    </Page>
  );
};

export default MissionDetail;
