import React, { Component, Fragment, useEffect, useState } from "react";

import dataService from "../../../../helpers/dataService";
import Loader from "../../../common/Loader";
import Axios from "axios";
import { API_URL, image_url as IMAGE_URL } from "../../../../config";
import FormGenerator from "./FormGeneratorPreview/FormGenerator";
import useStore from "../../../../context/useStore";

import Framework7 from "framework7/framework7-bundle";

// Import Framework7-React plugin
import Framework7React from "framework7-react";
import Page from "../../../common/layout/Page";

Framework7.use(Framework7React);

const MissionFormPreview = (props) => {
  const missionId = props.missionId;
  const [state, dispatch] = useStore();
  const constants = state.constants.items;
  const { isConnected } = state.auth;
  const [mission, setMission] = useState({});
  const [errors, setErrors] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const [formComplete, setFormComplete] = useState(false);

  useEffect(() => {
    getMission();
  }, []);

  const getMission = () => {
    setIsLoading(true);
    dataService.get(
      "missions/" + missionId + "/form",
      setMission,
      setErrors,
      () => setIsLoading(false)
    );
  };

  useEffect(() => {
    console.log("MISSION", mission);
    if (mission.missionForm) {
      mission.missionForm.questions.forEach((q) => {
        if (q.image) {
          var img = new Image();
          img.src = IMAGE_URL + q.image.fileName;
        }
      });
    }
  }, [mission]);

  const save = (answers) => {
    if (isSaving) return false;
    setIsSaving(true);
    const formData = new FormData();
    console.log("send ans", answers);
    mission.missionForm.questions.forEach((ques) => {
      if (ques.type == 7 && answers[ques._id].length) {
        answers[ques._id].forEach((img) => {
          formData.append(ques._id + "[]", img);
        });
      } else {
        formData.append(ques._id, answers[ques._id]);
      }
    });

    dataService.patch(
      "participations/" + mission.participation.id + "/finish",
      formData,
      setFormComplete,
      setFormErrors,
      () => setIsSaving(false)
    );
  };

  const uploadPhotos = (answers) => {
    const formData = new FormData();
    mission.missionForm.questions.forEach((ques) => {
      if (ques.type == 7 && answers[ques._id].length) {
        formData.append(
          ques._id,
          answers[ques._id][0],
          answers[ques._id][0].name
        );
      }
    });
  };

  return (
    <Page container="container form-preview">
      {isLoading ? (
        <Loader fullPage />
      ) : (
        <>
          {isSaving ? (
            <Loader
              fullPage
              text={
                "Veuillez patienter pendant l'enregistrement de votre réponses. Cela peut prendre quelques minutes."
              }
            />
          ) : formComplete ? null : (
            <FormGenerator
              mission={mission}
              questions={mission.questions}
              save={save}
              formErrors={formErrors}
            />
          )}
        </>
      )}
    </Page>
  );
};

export default MissionFormPreview;
