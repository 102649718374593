import React, { useState } from "react";
import useStore from "../../../context/useStore";
import Control from "../../common/Controls/Control";
import SaveBtn from "../../common/SaveBtn";

const StatusChoice = ({ saveStatus, selectedPayments }) => {
  const [state, dispatch] = useStore();
  const statuses = state.constants.items.PAYMENT_STATUS;
  const [selectedStatus, setSelectedStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const save = () => {
    setIsLoading(true);
    saveStatus(selectedStatus);
  };

  return (
    <div>
      <Control
        label={`Sélectionnez le statut dans lequel vous souhaitez passer les ${selectedPayments.length} paiement(s) sélectionné(s)`}
        type="btnList"
        value={selectedStatus}
        datas={statuses.map((s) => ({
          ...s,
          icon: `caret-right text-${s.color}`,
        }))}
        dataIcon="icon"
        dataIndex="id"
        dataLabel="name"
        change={(e) => setSelectedStatus(e.target.value)}
      />
      <SaveBtn
        save={save}
        isSaving={isLoading}
        text="Mettre à jour les status"
      />
    </div>
  );
};

export default StatusChoice;
