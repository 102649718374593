import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Page from "../../common/layout/Page";
import Axios from "axios";
import { API_URL } from "../../../config";
import Control from "../../common/Controls/Control";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import Pagination from "../../common/Pagination";
import tools from "../../../helpers/tools";
import Table from "../../common/Table/Table";
import { DateTime } from "luxon";
import dataService from "../../../helpers/dataService";

var strTimeout = null;

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [state, dispatch] = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [errors, setErrors] = useState({});
  const [search, setSearch] = useState({
    user: {
      role: "",
      searchString: "",
    },
    page: 1,
  });
  const [nbPages, setNbPages] = useState(1);

  useEffect(() => {
    let lsState = tools.getState("ManagerList", { search: search });
    setSearch(lsState.search);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    clearTimeout(strTimeout);
    strTimeout = setTimeout(() => {
      searchUsers();
    }, 1500);
    tools.saveState("ManagerList", "search", search);
  }, [search]);

  function searchUsers() {
    setErrors(false);
    Axios.post(API_URL + "managers/search", search)
      .then((res) => {
        setUsers(res.data.datas);
        setTotal(res.data.count);
        setNbPages(Math.ceil(parseInt(res.data.count) / 25));
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response && err.response.data) {
          setErrors(err.response.data);
          setUsers([]);
        }
      });
  }
  function updateUserSearch(e) {
    var value = e.target.value;
    var su = { ...search };
    if (value == "") {
      delete su.user[e.target.name];
    } else {
      su.user[e.target.name] = value;
    }
    console.log(su);

    setSearch(su);
  }

  function changePage(i) {
    setSearch((search) => {
      return { ...search, page: i };
    });
  }

  const deleteData = (id) => {
    if (!window.confirm("Êtes-vous sûr de vouloir supprimer cette entrée ?"))
      return false;
    setIsLoading(true);
    const onRemoveSuccess = (data) => {
      var dt = JSON.parse(JSON.stringify(users));
      dt = dt.filter((d) => d.id != id);
      console.log("DT", dt);
      setUsers(dt);
    };
    const onRemoveFinish = () => {
      setIsLoading(false);
    };
    dataService.remove(
      "managers/" + id,
      {},
      onRemoveSuccess,
      setErrors,
      onRemoveFinish
    );
  };

  return (
    <Page
      container="container"
      action={{ to: "/managers/create", text: "Créer un manager" }}
      title={"Liste des administrateurs"}
    >
      <div className="row mb-4">
        {state.constants.items.length == 0 ? (
          <Loader />
        ) : (
          <div className="col-5">
            <Control
              label="Rôle"
              type="btnList"
              dataIndex="id" //default
              dataLabel="name" //default
              value={search.user.role}
              datas={state.constants.items.ROLES_MANAGER}
              name="role"
              change={(e) =>
                setSearch({ ...search, user: { role: e.target.value } })
              }
            />
          </div>
        )}
        <div className="col">
          <Control
            type="text"
            label="Recherche"
            name="searchString"
            value={search.user.searchString}
            change={updateUserSearch}
            suffix={<i className="fa fa-search ml-2"></i>}
          />
        </div>
        <div className="col align-items-center justify-content-end d-flex">
          {total} administrateurs trouvés
        </div>
      </div>
      <div className="row">
        <div className="col-12 p-4">
          {errors && errors.other ? (
            <div className="text-danger m-auto text-center">{errors.other}</div>
          ) : isLoading ? (
            <Loader />
          ) : (
            <>
              <Table
                fields={[
                  {
                    name: "Rôle",
                    path: "role",
                    render: (el) =>
                      state.constants.items.ROLES_MANAGER.find(
                        (r) => r.id === el
                      ).name,
                  },
                  { name: "Prénom", path: "firstname" },
                  { name: "Nom", path: "lastname" },
                  { name: "Email", path: "email" },
                  { name: "Téléphone", path: "phone" },
                  {
                    name: "Créé le",
                    path: "createdAt",
                    render: (el) => DateTime.fromISO(el).toFormat("dd/MM/yyyy"),
                  },
                  {
                    name: "Actions",
                    path: "id",
                    render: (el) => {
                      return (
                        <>
                          <Link
                            className="btn btn-default d-center d-inline-flex"
                            to={`/managers/${el}`}
                          >
                            <i className="fa fa-edit" />
                          </Link>
                          <button
                            className="btn btn-danger d-inline-flex"
                            onClick={() => deleteData(el)}
                          >
                            <i className="fa fa-times" />
                          </button>
                        </>
                      );
                    },
                  },
                ]}
                datas={users}
              />
            </>
          )}
          <Pagination
            nbPages={nbPages}
            page={search.page}
            changePage={changePage}
          />
        </div>
      </div>
    </Page>
  );
};

export default UserList;
