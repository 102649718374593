import React, { useEffect, useRef, useState } from "react";
import SignaturePad from "react-signature-canvas";
import LabelContainer from "../../LabelContainer";
import "./styles.css";

function SignaturePadInput({
    value,
    k = -1,
    id = null,
    uploadedBase64,
    setUploadedBase64,
    answers,
    setAnswers,
    ...props
}) {
    const signaturePadRef = useRef();

    const [isSigned, setIsSigned] = useState(false);
    const [shouldAnimate, setShouldAnimate] = useState(false);

    const handleClear = () => {
        const signaturePad = signaturePadRef.current;

        if (signaturePad) {
            signaturePad.clear();
        }
    };

    const handleSave = () => {
        const signaturePad = signaturePadRef.current;

        if (!signaturePad) {
            return;
        }
        if (!signaturePad.isEmpty()) {
            setShouldAnimate(true);
            setTimeout(() => {
                setIsSigned(true);
            }, 800);

            setUploadedBase64({
                ...uploadedBase64,
                [props.name]: [
                    signaturePad.getTrimmedCanvas().toDataURL("image/png"),
                ],
            });

            let file = dataURLtoFile(
                signaturePad.getTrimmedCanvas().toDataURL("image/png"),
                "signature.png"
            );
            setAnswers({ ...answers, [props.name]: [file] });
        }
    };

    function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = Buffer.from(arr[1], "base64");

        return new File(bstr, filename, { type: mime });
    }

    return (
        <LabelContainer {...props} value={value}>
            {!isSigned ? (
                <div
                    className={`container d-center flex-column ${
                        shouldAnimate ? "animated zoomOut" : ""
                    }`}
                >
                    <div className={`sigContainer`}>
                        <SignaturePad
                            redrawOnResize
                            ref={signaturePadRef}
                            canvasProps={{
                                id:
                                    k != -1
                                        ? `${props.name}-${k}`
                                        : id
                                        ? id
                                        : props.name,
                                className: "sigPad",
                                width: "400px",
                                height: "200px",
                            }}
                        />
                    </div>
                    <div className="d-flex">
                        <button
                            className="btn btn-primary mr-2"
                            onClick={handleClear}
                        >
                            Effacer
                            <i class="fas fa-eraser ml-2" />
                        </button>
                        <button
                            className="btn btn-primary mr-2"
                            onClick={handleSave}
                        >
                            Enregistrer
                        </button>
                    </div>
                </div>
            ) : (
                <div className="d-center w-100">
                    <h4 className="animated zoomIn faster">
                        <i class="fas fa-check mr-2" />
                        Votre signature à été enregistrée
                    </h4>
                </div>
            )}
        </LabelContainer>
    );
}

export default SignaturePadInput;
