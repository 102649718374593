import React, { useState, useEffect } from "react";

import Page from "../../common/layout/Page";
import MissionAnswer from "./MissionAnswer";
import Axios from "axios";
import { API_URL } from "../../../config";
import Loader from "../../common/Loader";

const MissionReport = (props) => {
  const [isLoadingMission, setIsLoadingMission] = useState(true);
  const [isLoadingParticipations, setIsLoadingParticipations] = useState(true);
  const [mission, setMission] = useState({});
  const [participations, setParticipations] = useState([]);

  console.log(props);

  useEffect(() => {
    getMission();
    getParticipations();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      getMission();
    }, 8000);
  }, [mission]);
  useEffect(() => {
    setTimeout(() => {
      getParticipations();
    }, 8000);
  }, [participations]);

  function refresh() {
    getMission();
    getParticipations();
  }

  function getMission() {
    //setIsLoadingMission(true);
    Axios.get(
      API_URL + "demo/missions/" + props.match.params.id + "/stats"
    ).then((res) => {
      setMission(res.data);
      setIsLoadingMission(false);
    });
  }
  function getParticipations() {
    //setIsLoadingParticipations(true);
    Axios.post(
      API_URL + "demo/missions/" + props.match.params.id + "/participations"
    ).then((res) => {
      setParticipations(res.data);
      setIsLoadingParticipations(false);
    });
  }

  return (
    <Page container="container" title={mission.name} back="/missions">
      {/* <div className="row">
        <div className="col-12 text-right">
          <button className="btn btn-primary" onClick={refresh}>
            Rafraichir
          </button>
        </div>
      </div> */}
      {isLoadingMission || isLoadingParticipations ? (
        <Loader />
      ) : (
        <>
          <h4>Résumé</h4>
          <div className="row mb-4">
            {mission.detail.map((q, k) => (
              <MissionAnswer question={q} k={k} />
            ))}
          </div>
        </>
      )}
      {isLoadingParticipations || isLoadingMission ? (
        <Loader />
      ) : (
        <>
          <h4>Les 5 dernières réponses</h4>
          <div className="row mb-4">
            <table className="table">
              <thead>
                <tr>
                  {mission.detail.map((q, k) => (
                    <th
                      style={{ verticalAlign: "top" }}
                      key={"question-th-k-" + k}
                    >
                      {q.question}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {participations.map((a, k) => {
                  return (
                    <tr>
                      {mission.detail.map((q, kq) => {
                        var answerValue = a.answers[q.name];
                        console.log("answerValue", answerValue);
                        if (q.type == "btnList") {
                          return (
                            <td>
                              {
                                q.options.find((qo) => qo.value == answerValue)
                                  .name
                              }
                            </td>
                          );
                        } else if (q.type == "checkList") {
                          return (
                            <td>
                              {answerValue
                                .map(
                                  (aq, k) =>
                                    q.options.find((qo) => qo.value == aq).name
                                )
                                .join(", ")}
                            </td>
                          );
                        } else if (q.type == "range") {
                          return <td>{answerValue}</td>;
                        } else {
                          return <td>{answerValue}</td>;
                        }
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      )}
    </Page>
  );
};

export default MissionReport;
