import React, { useState, useEffect } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import useStore from "../../../context/useStore";
import { authActions } from "../../../context/actions/authActions";
import { ToastContainer } from "react-toastify";
import "./sidenav.scss";
import { AUTH_TYPES } from "../../../context/reducers/authReducers";
import Avatar from "../Avatar";
import { motion } from "framer-motion";
import Colors from "../../../theme/variables.scss";

const Header = withRouter((props) => {
  const [state, dispatch] = useStore();
  const actions = authActions(state, dispatch);
  const [avatarVisible, setAvatarVisible] = useState(true);
  const [showSub, setShowSub] = useState(false);

  useEffect(() => {
    if (state.auth.user.newImage) {
      setAvatarVisible(false);
      console.log("NEW IMAGE !");
      setTimeout(() => {
        setAvatarVisible(true);
        dispatch({
          type: AUTH_TYPES.SET_CURRENT_USER,
          payload: { ...state.auth.user, newImage: false },
        });
      }, 200);
    }
  }, [state.auth]);

  // useEffect(() => {
  //   window.$("#sidebar").addClass("active");
  // }, [props.location.pathname]);

  var authData = state.auth;
  return authData.isAuthenticated && !authData.user.mustRenewPassword ? (
    <>
      <nav
        id="sidebar"
        className={props.isOpen ? "active no-print" : "no-print"}
        onMouseEnter={() => props.setSivenavOpen(false)}
        onMouseLeave={() => props.setSivenavOpen(true)}
      >
        <div className="sidebar-header text-center">
          <div className="animated pulse slow d-flex align-items-center justify-content-center flex-column">
            <img height="65" src="/images/logo.svg" />
          </div>
        </div>

        <ul className="list-unstyled mt-3 components position-relative">
          {props.sidenavItems.map((m, mk) => (
            <li key={"menuitemside" + mk}>
              {m.path !== "#" ? (
                <NavLink
                  className={`nav-link d-flex align-items-center`}
                  to={m.path}
                >
                  <span
                    className="d-center"
                    style={{
                      position: "absolute",
                    }}
                  >
                    <i className={`mr-3 fa fa-${m.icon}`} />

                    <span>{m.name}</span>
                  </span>
                </NavLink>
              ) : (
                <a
                  href="#"
                  style={{ color: "#b3b3b3" }}
                  className={`nav-link d-flex align-items-center`}
                  onClick={() =>
                    showSub == mk ? setShowSub(false) : setShowSub(mk)
                  }
                >
                  <span
                    className="d-center"
                    style={{
                      position: "absolute",
                    }}
                  >
                    <i className="mr-3 fa fa-grip-lines" />
                    <span>{m.name}</span>
                  </span>
                </a>
              )}
              {m.items && (
                <ul
                  className="list-unstyled components position-relative"
                  style={{
                    borderLeft: "2px solid" + Colors.primary,
                  }}
                >
                  {m.items.map((it, itk) => (
                    <motion.li
                      exit={{ opacity: 0, y: -50 }}
                      initial={{ opacity: 0, y: -50 }}
                      animate={{ opacity: 1, y: 0 }}
                      key={"menuitemside" + mk + itk}
                    >
                      <NavLink
                        className="nav-link d-flex align-items-center"
                        to={it.path}
                      >
                        <span
                          className="d-center"
                          style={{
                            position: "absolute",
                          }}
                        >
                          <i className={`mr-3 fa fa-${it.icon}`} />

                          <span>{it.name}</span>
                        </span>
                      </NavLink>
                    </motion.li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </nav>
    </>
  ) : (
    <></>
  );
});

export default withRouter(Header);
