import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import useStore from "../../../context/useStore";
import { authActions } from "../../../context/actions/authActions";
import Avatar from "../Avatar";
import { AUTH_TYPES } from "../../../context/reducers/authReducers";
import $ from "jquery";

const TopHeader = () => {
  const [state, dispatch] = useStore();
  const actions = authActions(state, dispatch);
  const [avatarVisible, setAvatarVisible] = useState(true);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  useEffect(() => {
    if (state.auth.user.newImage) {
      setAvatarVisible(false);
      console.log("NEW IMAGE !");
      setTimeout(() => {
        setAvatarVisible(true);
        dispatch({
          type: AUTH_TYPES.SET_CURRENT_USER,
          payload: { ...state.auth.user, newImage: false },
        });
      }, 200);
    }
  }, [state.auth]);

  useEffect(() => {
    $(".top-dropdown").toggleClass("show");
  }, [dropdownVisible]);

  var authData = state.auth;

  return authData.isAuthenticated && !authData.user.mustRenewPassword ? (
    <div
      className="top-header d-flex align-items-center justify-content-end "
      style={{ maxWidth: "80%" }}
    >
      <div className="btn-group top-dropdown">
        <button
          type="button"
          className="border-0 avatar-dropdown"
          onClick={() => {
            setDropdownVisible((v) => !v);
          }}
        >
          {avatarVisible && (
            <Avatar width={50} height={50} id={authData.user.id} />
          )}
          <span>
            {state.auth.user.firstname} {state.auth.user.lastname}
          </span>
        </button>
        <div className="top-dropdown-menu shadow-lg">
          <Link className="dropdown-item" to="/profile">
            <i className="fa fa-palette mr-3" />
            Mon compte
          </Link>

          <button className="dropdown-item" onClick={actions.logoutUser}>
            <i className="fa fa-power-off mr-3" />
            Déconnexion
          </button>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default TopHeader;
