import React, { useEffect, useState } from "react";
import { modalActions } from "../../../../context/actions/modalActions";
import useStore from "../../../../context/useStore";
import dataService from "../../../../helpers/dataService";
import tools from "../../../../helpers/tools";
import Loader from "../../../common/Loader";
import ProspectorDetail from "../../ProspectorDetail/ProspectorDetail";
import AnswersViewer from "../../AnswersViewer/AnswersViewer";
import Collapse from "../../../common/Collapse/Collapse";
import NotationItem from "./NotationItem";

const Notation = ({ mission, tab }) => {
  const [state, dispatch] = useStore();
  const modal = modalActions(state, dispatch);
  const [notations, setNotations] = useState(false);
  const [errors, setErrors] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [scores, setScores] = useState(false);
  const [isLoadingAnswers, setIsLoadingAnswers] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    getNotations();
  }, []);

  useEffect(() => {
    if (notations && !scores) {
      var sc = {};
      notations.forEach((n) => {
        sc[n.id] = {
          commentScore: n.scores?.commentScore || 0,
          photosScore: n.scores?.photosScore || 0,
        };
      });
      setScores(sc);
    }
  }, [notations]);

  const getNotations = () => {
    dataService.get(
      `missions/${mission.id}/participations/rating`,
      setNotations
    );
  };

  const updateScore = (id, item, score) => {
    var sc = { ...scores };
    scores[id][item] = score;
    setScores(sc);
  };

  const openProfile = (id) => {
    modal.updateModal({
      isOpen: true,
      content: <ProspectorDetail prospectorId={id} />,
    });
  };

  const updatePhotoScore = (notationId, photoId, score) => {
    var ps = [...notations];
    ps
      .find((p) => p.id == notationId)
      .photos.find((p) => p.id == photoId).score = score;
    setNotations(ps);
  };

  const openAnswers = (participationId) => {
    setIsLoadingAnswers(true);
    dataService.get(
      `participations/${participationId}`,
      (datas) => {
        modal.updateModal({
          isOpen: true,
          content: <AnswersViewer mission={mission} answers={datas.answers} />,
        });
      },
      () => {},
      () => setIsLoadingAnswers(false)
    );
  };

  const saveNotation = (notation) => {
    var sc = {
      commentScore: scores[notation.id].commentScore,
      photosScore: tools.calcPhotosScore(notation.photos),
      photos: notation.photos.map((p) => ({ id: p.id, score: p.score })),
    };
    console.log("SAVE", sc);
    setIsSaving(true);
    dataService.patch(
      `participations/${notation.id}/rate`,
      sc,
      (datas) => {
        getNotations();
      },
      () => {},
      () => setIsSaving(false)
    );
  };

  const ratedNotations = notations ? notations.filter((n) => n.scores) : [];
  const toRateNotations = notations ? notations.filter((n) => !n.scores) : [];

  return !notations || !scores ? (
    <Loader />
  ) : (
    <>
      <Collapse title={`NOTÉS (${ratedNotations.length})`}>
        {ratedNotations.map((n, nk) => (
          <NotationItem
            key={"anot" + nk}
            notation={n}
            scores={scores}
            isLoading={isLoading}
            isLoadingAnswers={isLoadingAnswers}
            actions={{ openProfile, openAnswers, updateScore }}
            frozen={true}
          />
        ))}
      </Collapse>
      <Collapse title={`À NOTER (${toRateNotations.length})`} type="secondary">
        {toRateNotations.map((n, nk) => (
          <NotationItem
            key={"anot" + nk}
            notation={n}
            scores={scores}
            isSaving={isSaving}
            isLoadingAnswers={isLoadingAnswers}
            actions={{
              openProfile,
              openAnswers,
              updateScore,
              updatePhotoScore,
              saveNotation,
            }}
          />
        ))}
      </Collapse>
    </>
  );
};

export default Notation;
