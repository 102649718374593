import React, { useEffect, useState } from "react";
import tools from "../../../../helpers/tools";
import Loader from "../../../common/Loader";
import SaveBtn from "../../../common/SaveBtn";
import dataService from "../../../../helpers/dataService";
import { toast } from "react-toastify";

import QuestionGenerator from "../MissionPreview/FormGeneratorPreview/QuestionGenerator";

const FormRogue = ({
  mission,
  questions,
  formErrors,
  isSaving,
  setIsSaving,
  setFormErrors,
  setFormComplete,
  participation,
  report,
  setReport,
}) => {
  const [step, setStep] = useState(0);
  const [prevStep, setPrevStep] = useState(0);
  const [answers, setAnswers] = useState(false);
  const [uploadedBase64, setUploadedBase64] = useState({});

  const rogueParticipationId = participation.id;
  const lsRef = `mission_${mission.id}&${rogueParticipationId}`;

  useEffect(() => {
    let ans = {};
    let bs64 = {};

    questions.forEach((q) => {
      ans[q._id] = [];
      if (q.type === 7) {
        for (let i = 0; i < q.inputParams.count; i++) {
          bs64[q._id] = bs64[q._id] ? bs64[q._id] : [];
          bs64[q._id][i] = [];
        }
      }
    });
    setUploadedBase64(bs64);

    if (!tools.isEmpty(localStorage.getItem(lsRef))) {
      setAnswers(JSON.parse(localStorage.getItem(lsRef)));
    } else {
      setAnswers(ans);
    }
    console.log("LS", localStorage.getItem(lsRef));
  }, [questions]);

  useEffect(() => {
    if (step > 0 && tools.shouldSkipQuestion(answers, step, questions)) {
      if (prevStep < step) {
        next();
      } else {
        prev();
      }
    }
  }, [step]);

  useEffect(() => {
    if (Object.keys(formErrors).length) {
      let firstErrorFound = false;
      questions.forEach((qs, qsk) => {
        if (formErrors[qs._id]) {
          console.log("errors at ", qs._id, qsk);
          if (!firstErrorFound) {
            setStep(qsk);
            firstErrorFound = true;
          }
        }
      });
    }
  }, [formErrors]);

  useEffect(() => {
    if (answers) {
      localStorage.setItem(lsRef, JSON.stringify(answers));
    }
  }, [answers]);

  const save = (answers) => {
    if (isSaving) return false;
    setIsSaving(true);

    const formData = new FormData();
    mission.questions.forEach((ques) => {
      if (ques.type === 7 && answers[ques._id].length) {
        answers[ques._id].forEach((img) => {
          formData.append(`${ques._id}[]`, img);
        });
      } else {
        formData.append(ques._id, answers[ques._id]);
      }
    });

    dataService.patch(
      `rogueparticipations/${rogueParticipationId}/finish`,
      formData,
      () => {
        setFormComplete(true);
        setReport({
          ...report,
          [report.participations.finished]: report.participations.finished++,
        });
        toast.success("Votre réponse à bien été envoyée");
      },
      (err) => {
        setFormErrors(err);
      },
      () => setIsSaving(false)
    );
  };

  const prev = () => {
    if (step > 0) {
      setPrevStep(step);
      setStep(step - 1);
    }
  };
  const next = () => {
    if (step < questions.length - 1) {
      setPrevStep(step);
      setStep(step + 1);
    }
  };
  return (
    <>
      {answers ? (
        <>
          {step > 0 && (
            <div className="mt-2">
              <button className="btn btn-default" onClick={prev}>
                Question précédente
              </button>
            </div>
          )}
          {questions.map((qs, qsk) => {
            return qsk === step ? (
              <QuestionGenerator
                question={qs}
                key={`qs${qsk}`}
                step={step}
                answers={answers}
                setAnswers={setAnswers}
                prev={prev}
                next={next}
                prevStep={prevStep}
                formErrors={formErrors}
                uploadedBase64={uploadedBase64}
                setUploadedBase64={setUploadedBase64}
              />
            ) : null;
          })}
          {step < questions.length - 1 && (
            <div>
              <button className="btn btn-primary" onClick={next}>
                Qestion suivante
              </button>
            </div>
          )}
          {step === questions.length - 1 && (
            <SaveBtn save={() => save(answers)} typeBtn="submit" />
          )}
        </>
      ) : (
        <Loader fullPage />
      )}
    </>
  );
};

export default FormRogue;
