import React, { useEffect, useState } from "react";
import Page from "../../common/layout/Page";
import Control from "../../common/Controls/Control";
import SaveBtn from "../../common/SaveBtn";
import dataService from "../../../helpers/dataService";
import { toast } from "react-toastify";
import useStore from "../../../context/useStore";

const ProductDetail = (props) => {
    const [state, dispatch] = useStore()
    const constants = state.constants.items
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [product, setProduct] = useState({
        name: "", origin: "", subCategory: "", category: "", updatedAt: new Date()
    });
    const [subCategories, setSubCategories] = useState([]);
    const [categories, setCategories] = useState([]);
    const itemId = props.match.params.id;
    const isEdit = itemId !== "create";

    useEffect(() => {
            if (isEdit) {
                getProduct(itemId);
            }
            getAllSubCategories();
            getAllCategories();
        },
        []);

    const getProduct = (id) => {
        dataService.get(`products/${id}`,
            (res) => {
                let name = res.name;
                let subCategory = res.subCategory.id;
                let category = res.category.id;
                let origin = res.origin;

                setProduct({
                    name, subCategory, category, origin
                });
            },
            setErrors,
            () => setIsLoading(false));
    };

    const getAllSubCategories = () => {
        dataService.get("product-subcategories",
            setSubCategories,
            setErrors,
            () => setIsLoading(false));
    };

    const getAllCategories = () => {
        dataService.get("product-categories",
            setCategories,
            setErrors,
            () => setIsLoading(false));
    };

    const save = () => {
        const onError = (error) => {
            toast.error(error);
            setErrors(error);
        };

        setIsLoading(true);
        if (isEdit) {
            const onSuccess = () => {
                toast.success("Produit enregistré avec succès");
            };

            dataService.patch(`products/${itemId}`,
                { ...product },
                onSuccess,
                onError,
                () => setIsLoading(false));
        } else {
            const onSuccess = (data) => {
                toast.success("Produit créé avec succès");
                window.location.href = `/products/${data.id}`;
            };

            dataService.post("products",
                product,
                onSuccess,
                onError,
                () => setIsLoading(false));
        }
    };

    return (<Page
        container="container"
        back="/products"
        title="Détail du produit"
        errors={errors}
    >
        <div className="row">
            <div className="col-6 p-0">
                <Control
                    label="Nom"
                    name="name"
                    type="text"
                    value={product.name}
                    change={(e) => setProduct({ ...product, name: e.target.value })}
                    required={true}
                />
            </div>
            <div className="col-6 p-0">
                <Control
                    label="Origine"
                    name="origin"
                    type="btnList"
                    btnInline={true}
                    datas={constants.PRODUCT_ORIGIN}
                    value={product.origin}
                    change={(e) => setProduct({ ...product, origin: e.target.value })}
                    required={true}
                />
            </div>
            <div className="col-12 p-0">
                <Control
                    label="Sous-famille"
                    name="subCategory"
                    type="select"
                    value={product.subCategory}
                    datas={subCategories}
                    dataIndex="id"
                    dataLabel="name"
                    change={(e) => setProduct({
                        ...product,
                        subCategory: e.target.value,
                        category: subCategories.filter((el) => el.id === e.target.value)[0].category,
                    })}
                />
            </div>
            <div className="col-12 p-0">
                <Control
                    label="Famille"
                    name="category"
                    type="select"
                    value={product.category}
                    datas={categories}
                    dataIndex="id"
                    dataLabel="name"
                    change={(e) => setProduct({
                        ...product, category: e.target.value,
                    })}
                    required={true}
                />
            </div>
            <div className="d-flex justify-content-end w-100">
                <SaveBtn
                    save={save}
                    isSaving={isLoading}
                    text={isEdit ? "Enregistrer" : "Créer"}
                    className="btn btn-success btn-save"
                />
            </div>
        </div>
    </Page>)
        ;
};

export default ProductDetail;
