import { DateTime } from "luxon";
import React from "react";
import useStore from "../../../../context/useStore";
import tools from "../../../../helpers/tools";
import Control from "../../../common/Controls/Control";
import CopyToClipboard from "../../../common/Controls/CopyToClipboard";
import PostalCode from "../../../common/PostalCode";
import SaveBtn from "../../../common/SaveBtn";

const ProspectorForm = ({
  user,
  setUser,
  isLoading,
  isLoadingPwd,
  updateData,
  save,
  savePassword,
  isResendingActivation,
  modifyPassword,
  setModifyPassword,
  itemId,
  resendActivation = { resendActivation },
}) => {
  const [state, dispatch] = useStore();

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-4 p-0">
          <Control
            label="Pseudo"
            name="pseudo"
            type="text"
            value={user.pseudo}
            change={updateData}
          />
        </div>
        <div className="col-12 col-md-4 p-0">
          <Control
            label="Email"
            name="email"
            type="email"
            value={user.email}
            change={updateData}
          />
        </div>
        <div className="col-12 col-md-4 p-0">
          <Control
            label="Téléphone"
            name="phone"
            type="tel"
            value={user.phone}
            change={updateData}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-4 p-0">
          {" "}
          <Control
            label="Civilité"
            type="select"
            name="gender"
            value={user.gender}
            datas={state.constants.items.GENDERS}
            dataIndex="id"
            dataLabel="name"
            change={updateData}
          />
        </div>
        <div className="col-12 col-md-4 p-0">
          <Control
            label="Prénom"
            name="firstname"
            type="text"
            value={user.firstname}
            change={updateData}
          />
        </div>
        <div className="col-12 col-md-4 p-0">
          {" "}
          <Control
            label="Nom"
            name="lastname"
            type="text"
            value={user.lastname}
            change={updateData}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-4 p-0">
          <Control
            label="Adresse"
            name="address"
            type="text"
            value={user.address}
            change={updateData}
          />
        </div>
        <div className="col-12 col-md-4 p-0">
          <PostalCode
            value={user.postalCode}
            updateData={updateData}
            location={user.location}
          />
        </div>
        <div className="col-12 col-md-4 p-0">
          {" "}
          <Control
            label="Date de naissance"
            type="date"
            name="birthdate"
            value={DateTime.fromISO(user.birthdate).toFormat("yyyy-MM-dd")}
            change={updateData}
          />
        </div>
        {(user.bankAccount && user.bankAccount.iban) && (
          <div className="col-12 p-0">
            <div className="col-12 col-md-4 p-0">
              <CopyToClipboard target={tools.chunk(user.bankAccount.iban, 4)}>
                <Control
                  label="IBAN"
                  type="text"
                  name="iban"
                  value={tools.chunk(user.bankAccount.iban, 4)}
                  emptyButton={false}
                  disabled
                />
              </CopyToClipboard>
            </div>
          </div>
        )}
        <div className="col-12 col-md-4 p-0">
          <Control
            label="Est activé ?"
            name="isActivated"
            type="checkbox"
            checked={user.isActivated}
            change={(e) => setUser({ ...user, isActivated: e.target.checked })}
          />
        </div>
        <div className="col-12 col-md-4 p-0">
          <Control
            label="CGU acceptées ?"
            name="isCGUAccepted"
            type="checkbox"
            checked={user.isCGUAccepted}
            change={(e) =>
              setUser({ ...user, isCGUAccepted: e.target.checked })
            }
          />
        </div>

        <div className="col-12 col-md-4 p-0">
          <Control
            label="Souscription à la newsletter ?"
            name="isNewsletter"
            type="checkbox"
            checked={user.isNewsletter}
            change={(e) => setUser({ ...user, isNewsletter: e.target.checked })}
          />
        </div>
        <div className="d-flex justify-content-end w-100">
          <SaveBtn
            save={save}
            isSaving={isLoading}
            text="Enregistrer"
            className="btn btn-success btn-save"
          />
        </div>
        {itemId !== "create" && (
          <div className="row">
            <div className="col-12 p-0">
              <SaveBtn
                type="light"
                save={resendActivation}
                isSaving={isResendingActivation}
                text="Renvoyer l'email d'activation"
              />
              <button
                className="btn btn-primary mt-2"
                onClick={() => setModifyPassword((p) => !p)}
              >
                Modifier le mot de passe
              </button>

              {modifyPassword && (
                <>
                  <Control
                    label="Nouveau mot de passe"
                    name="password"
                    type="password"
                    value={user.password}
                    change={updateData}
                  />

                  <SaveBtn
                    save={savePassword}
                    isSaving={isLoadingPwd}
                    text="Enregistrer le mot de passe"
                  />
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ProspectorForm;
