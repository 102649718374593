import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Page from "../../common/layout/Page";
import Axios from "axios";
import { API_URL } from "../../../config";
import Control from "../../common/Controls/Control";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import Pagination from "../../common/Pagination";
import tools from "../../../helpers/tools";
import Table from "../../common/Table/Table";
import { DateTime } from "luxon";
import { modalActions } from "../../../context/actions/modalActions";
import RejectIbanComment from "./RejectIbanComment";
import dataService from "../../../helpers/dataService";

var strTimeout = null;

const IBANValidation = () => {
  const [state, dispatch] = useStore();
  const modal_actions = modalActions(state, dispatch);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [errors, setErrors] = useState(false);
  const [search, setSearch] = useState({
    user: { bankAccountStatus: 2, searchString: "" },
    page: 1,
  });
  const [nbPages, setNbPages] = useState(1);

  useEffect(() => {
    let lsState = tools.getState("IBANValidation", { search: search });
    setSearch(lsState.search);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    clearTimeout(strTimeout);
    strTimeout = setTimeout(() => {
      searchUsers();
    }, 1500);
    tools.saveState("IBANValidation", "search", search);
  }, [search]);

  function searchUsers() {
    setErrors(false);
    Axios.post(API_URL + "prospectors/search", search)
      .then((res) => {
        setUsers(res.data.datas);
        setTotal(res.data.count);
        setNbPages(Math.ceil(parseInt(res.data.count) / 25));
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setErrors(err.response.data);
        setUsers([]);
        console.log(err.response.data);
      });
  }
  function updateUserSearch(e) {
    var value = e.target.value;
    var su = { ...search };

    su.user[e.target.name] = value;

    console.log(su);

    setSearch(su);
  }

  function changePage(i) {
    setSearch((search) => {
      return { ...search, page: i };
    });
  }

  const updateStatus = (id, status, comment) => {
    if (!comment && status == 3) {
      modal_actions.updateModal({
        isOpen: true,
        content: (
          <RejectIbanComment
            updateStatus={updateStatus}
            params={{ id, status }}
          />
        ),
      });
    } else {
      if (
        status == 4 &&
        !window.confirm("Confirmez-vous la validation de cet IBAN ?")
      )
        return false;
      let user = users.find((u) => u.id == id);

      user.bankAccount.comment = comment;
      user.bankAccount.status = status;

      modal_actions.updateModal({ isOpen: false, content: null });
      dataService.patch(
        `prospectors/${id}/bank-account`,
        user,
        (datas) => {
          searchUsers();
        },
        setErrors
      );
    }
  };

  console.log("search", search);

  return (
    <Page
      container="container"
      // action={{ to: "/prospectors/create", text: "Créer un prospect" }}
      errors={errors}
      title={"Liste des IBAN"}
    >
      <div className="row mb-4">
        <div className="col">
          <Control
            type="btnList"
            label="Statut"
            name="bankAccountStatus"
            value={search.user.bankAccountStatus}
            change={updateUserSearch}
            datas={[
              { id: 1, name: "Absent", color: "white" },
              { id: 2, name: "À valider", color: "yellow" },
              { id: 3, name: "Refusé", color: "red" },
              { id: 4, name: "Validé", color: "green" },
            ]}
            dataIndex="id"
            dataLabel="name"
            btnInline
          />
        </div>
      </div>
      <div className="row mb-4">
        <div className="col">
          <Control
            type="text"
            label="Recherche"
            name="searchString"
            value={search.user.searchString}
            change={updateUserSearch}
            suffix={
              <i
                className="fa fa-search ml-2"
                style={{ lineHeight: "40px" }}
              ></i>
            }
          />
        </div>
        <div className="col align-items-center justify-content-end d-flex">
          {total} utilisateurs trouvés
        </div>
      </div>
      <div className="row">
        <div className="col-12 p-4">
          {errors && errors.other ? (
            <div className="text-danger m-auto text-center">{errors.other}</div>
          ) : isLoading ? (
            <Loader />
          ) : (
            <Table
              fields={[
                {
                  name: "Nom",
                  path: "*",
                  render: (e) => `${tools.getFullName(e)} (${e.pseudo})`,
                },

                { name: "Email", path: "email" },
                {
                  name: "IBAN",
                  path: "bankAccount",
                  render: (el) => (
                    <>
                      <div
                        className={`d-center ${
                          el.status == 4
                            ? "text-success"
                            : el.status == 3
                            ? "text-danger"
                            : el.status == 2
                            ? "text-primary"
                            : ""
                        }`}
                      >
                        {tools.chunk(el.iban, 4)}
                        <i
                          className={`fa ml-2 ${
                            el.status == 4
                              ? "fa-check"
                              : el.status == 3
                              ? "fa-times"
                              : "fa-question"
                          }`}
                        />
                      </div>
                      <p
                        className="text-muted mb-0"
                        style={{ fontSize: 10, lineHeight: "11px" }}
                      >
                        {el.status == 3 ? el.comment : null}
                      </p>
                    </>
                  ),
                },
                {
                  name: "Créé le",
                  path: "updatedAt",
                  render: (el) => DateTime.fromISO(el).toFormat("dd/MM/yyyy"),
                },
                {
                  name: "Actions",
                  path: "*",
                  render: (el) => {
                    return (
                      <div className="d-center">
                        {el.bankAccount.status != 4 && (
                          <button
                            className="btn btn-success"
                            onClick={(e) => updateStatus(el.id, 4)}
                          >
                            <i className="fa fa-check" />
                          </button>
                        )}
                        <button
                          className="btn btn-danger"
                          onClick={(e) => updateStatus(el.id, 3)}
                        >
                          <i className="fa fa-times" />
                        </button>
                      </div>
                    );
                  },
                },
              ]}
              datas={users}
            />
          )}
          <Pagination
            nbPages={nbPages}
            page={search.page}
            changePage={changePage}
          />
        </div>
      </div>
    </Page>
  );
};

export default IBANValidation;
