import React, {useEffect, useState} from "react";
import Page from "../../common/layout/Page";
import Control from "../../common/Controls/Control";
import SaveBtn from "../../common/SaveBtn";
import dataService from "../../../helpers/dataService";
import {toast} from "react-toastify";

const ProductSubCategoryDetail = (props) => {
    const [errors, setErrors] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [subCategory, setSubCategory] = useState({
        name: "", category: "", updatedAt: new Date()
    })
    const [categories, setCategories] = useState([])
    const itemId = props.match.params.id
    const isEdit = itemId !== 'create'

    useEffect(() => {
            if (isEdit) {
                getSubCategory(itemId)
            }
            getAllCategories()
        },
        [])

    useEffect(() => {
            console.log(subCategory)
        },
        [subCategory])

    const getSubCategory = (id) => {
        dataService.get(`product-subcategories/${id}`,
            setSubCategory,
            setErrors,
            () => setIsLoading(false))
    }

    const getAllCategories = () => {
        dataService.get('product-categories',
            setCategories,
            setErrors,
            () => setIsLoading(false))
    }

    const save = () => {
        const onError = (error) => {
            toast.error(error)
            setErrors(error)
        }

        setIsLoading(true)
        if (isEdit) {
            const onSuccess = () => {
                toast.success("Sous-famille enregistrée avec succès")
            }

            dataService.patch(`product-subcategories/${itemId}`,
                {...subCategory},
                onSuccess,
                onError,
                () => setIsLoading(false))
        } else {
            const onSuccess = (data) => {
                toast.success('Sous-famille créée avec succès')
                window.location.href = `/product-subcategories/${data.id}`
            }

            dataService.post('product-subcategories',
                subCategory,
                onSuccess,
                onError,
                () => setIsLoading(false))
        }
    }

    return (<Page
            container={"container"}
            back={"/products"}
            title={"Détail de la sous-famille"}
            errors={errors}>
            <div className={"row"}>
                <div className={"col-12 p-0"}>
                    <Control
                        label={"Nom"}
                        name={"name"}
                        type={"text"}
                        value={subCategory.name}
                        change={(e) => setSubCategory({
                            ...subCategory, name: e.target.value
                        })}
                        required={true}
                    />
                </div>
                <div className={"col-12 p-0"}>
                    <Control
                        label={"Famille"}
                        name={"category"}
                        type={"select"}
                        value={subCategory.category}
                        datas={categories}
                        dataIndex={"id"}
                        dataLabel={'name'}
                        change={(e) => setSubCategory({
                            ...subCategory, category: e.target.value
                        })}
                        required={true}
                    />
                </div>
                <div className={"d-flex justify-content-end w-100"}>
                    <SaveBtn
                        save={save}
                        isSaving={isLoading}
                        text={isEdit ? "Enregistrer" : "Créer"}
                        className={"btn btn-success btn-save"}
                    />
                </div>
            </div>
        </Page>)
}

export default ProductSubCategoryDetail