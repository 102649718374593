import React, { useState } from "react";
import dataService from "../../../helpers/dataService";

const useProspectorStats = (url, year) => {
  const [isLoading, setIsLoading] = useState(false);
  const [chartState, setChartState] = useState(false);
  const [total, setTotal] = useState(0);
  const [rankings, setRankings] = useState({
    1: 0,
    2: 0,
    3: 0,
  });
  const months = [
    "Jan",
    "Fev",
    "Mar",
    "Avr",
    "Mai",
    "Juin",
    "Juil",
    "Aou",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const getDatas = () => {
    setIsLoading(true);

    dataService.post(
      `${url}`,
      { year },
      (datas) => {
        setChartState({
          pie: {
            series: [datas.active, datas.deleted, datas.inactive],
            options: {
              labels: ["Actifs", "Supprimés", "Inactifs"],
              legend: { position: "bottom" },
            },
          },
        });
        setTotal(datas.total);
        setRankings(datas.rankings);
      },
      (e) => {},
      () => setIsLoading(false)
    );
  };

  var hasDatas = chartState;

  return {
    isLoading,
    getDatas,
    chartState,
    hasDatas,
    total,
    rankings,
  };
};

export default useProspectorStats;
