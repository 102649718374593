import React, { useEffect, useState } from "react";
import Control from "../../common/Controls/Control";
import SaveBtn from "../../common/SaveBtn";

const CategoryForm = ({ saveCategory, form }) => {
  const [name, setName] = useState(form.name);

  const save = () => {
    saveCategory({
        ...form,
        name,
    });
  };

  return (
    <div>
      <Control
        label="Nom de la catégorie"
        type="text"
        value={name}
        change={(e) => setName(e.target.value)}
      />

      <SaveBtn save={save} isSaving={false} />
    </div>
  );
};

export default CategoryForm;
