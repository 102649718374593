import React, { useState, useEffect, createRef } from "react";

import useStore from "../../../context/useStore";
import Axios from "axios";
import { API_URL } from "../../../config";
import Control from "../../common/Controls/Control";
import Loader from "../../common/Loader";
import { Map, TileLayer, Marker, Popup, Circle } from "react-leaflet";
import Geocode from "react-geocode";
// import TinyMCE from "react-tinymce";
import { Editor } from "@tinymce/tinymce-react";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import { DateTime } from "luxon";
import ImagesUploader from "./ImagesUploader";
import SaveBtn from "../../common/SaveBtn";

function deleteMission(
  mission,
  isDeleted,
  successCallback,
  errorCallback,
  finallyCallback
) {
  Axios.patch(API_URL + "missions/" + mission.id + "/isDeleted", {
    isDeleted,
  })
    .then(successCallback)
    .catch(errorCallback)
    .finally(finallyCallback);
}

const MissionParameters = ({
  mission,
  setMission,
  missionId,
  setMissionId,
  isEdit,
}) => {
  const [state, dispatch] = useStore();
  const [isLoading, setIsLoading] = useState(true);
  const [canRedirect, setCanRedirect] = useState(false);
  const [prospects, setProspects] = useState(false);
  const mapRef = createRef();
  const [zoom, setZoom] = useState(12);
  const constants = state.constants.items;
  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [isTemplating, setIsTemplating] = useState(false);
  const missionLocation = mission.location
    ? [mission.location.coordinates[1], mission.location.coordinates[0]]
    : [0, 0];

  useEffect(() => {
    getProspects();
    if (missionId != "create") {
      getMission();
    }
  }, [missionId]);

  function getMission() {
    Axios.get(API_URL + "missions/" + missionId)
      .then((res) => {
        res.data.beginAt = DateTime.fromJSDate(
          new Date(res.data.beginAt)
        ).toFormat("yyyy-MM-dd");
        res.data.endAt = DateTime.fromJSDate(new Date(res.data.endAt), {
          zone: "Indian/Reunion",
        })
          .toUTC()
          .toFormat("yyyy-MM-dd");
        setMission(res.data);
      })
      .catch((err) => {});
  }
  function getProspects() {
    setIsLoading(true);
    Axios.get(API_URL + "prospects/")
      .then((res) => {
        setProspects(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setProspects(false);
        toast.error("Erreur lors de la récupération des prospects");
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      });
  }
  function updateMission(e) {
    var miss = JSON.parse(JSON.stringify(mission));
    var value = e.target.value;

    if (e.target.type == "checkbox") {
      if (e.target.checked) {
        value = true;
      } else {
        value = false;
      }
    }
    miss[e.target.name] = value;
    setMission(miss);
    setErrors(() => Object.keys(errors).filter(err => err === e.target.name));
  }
  function changeDescription(e) {
    var miss = JSON.parse(JSON.stringify(mission));

    miss.description = e;
    setMission(miss);
  }
  function changeHelp(e) {
    var miss = JSON.parse(JSON.stringify(mission));

    miss.help = e;
    setMission(miss);
  }
  function updateMissionRewards(e, k) {
    var miss = JSON.parse(JSON.stringify(mission));
    miss.rewards[e.target.k] = e.target.value;
    setMission(miss);
  }
  function mapClick(e) {
    var miss = JSON.parse(JSON.stringify(mission));
    miss.location = {
      type: "Point",
      coordinates: [e.latlng.lng, e.latlng.lat],
    };
    setMission(miss);
  }
  function getPosFromAddress() {
    var miss = JSON.parse(JSON.stringify(mission));

    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);
    Geocode.fromAddress(mission.address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        mapClick({ latlng: { lat: lat, lng: lng } });
      },
      (error) => {}
    );
  }
  function save() {
    setIsSaving(true);
    if (missionId == "create") {
      Axios.post(API_URL + "missions", mission)
        .then((res) => {
          toast.success("Le constat a bien été créée");
          setMissionId(res.data.id);
          window.location.href = "/constats/" + res.data.id;
          setIsSaving(false);
        })
        .catch((err) => {
          setIsSaving(false);
          if (err.response && err.response.data) {
            setErrors(err.response.data);
          }
          if (err.response?.data?.other) {
            toast.error(err.response.data.other);
          }
        });
    } else {
      Axios.patch(API_URL + "missions/" + missionId, mission)
        .then((res) => {
          toast.success("Le constat a bien été enregistrée");
          setIsSaving(false);
        })
        .catch((err) => {
          setIsSaving(false);

          if (err.response && err.response.data) {
            setErrors(err.response.data);
          }
          if (err.response.data.other) {
            toast.error(err.response.data.other);
          }
        });
    }
  }
  function duplicateMission() {
    setIsDuplicating(true);
    Axios.post(API_URL + "missions/from/" + mission.id, {
      isTemplate: false,
    })
      .then((res) => {
        toast.success("Mission dupliquée avec succès");
        window.location.href = "/constats/" + res.data.id;
      })
      .catch((err) => {
        toast.error(
          "Une erreur s'est produite lors de la duplication du constat"
        );
      })
      .then(() => setIsDuplicating(false));
  }

  function cancelMission() {
    if (
      !window.confirm(
        "Confirmez l'annulation de la mission (Un email sera envoyé à chaque prospecteur)."
      )
    )
      return false;

    setIsCancelling(true);

    deleteMission(
      mission,
      true,
      (res) => {
        toast.success("Mission annulée avec succès");
        window.location.href = "/constats/" + res.data.id;
      },
      (err) => {
        toast.error(
          "Une erreur s'est produite lors de l'annulation du constat"
        );
      },
      () => setIsPublishing(false)
    );
  }

  function republishMission() {
    if (
      !window.confirm(
        "Confirmez la re-publication de la mission ? (Un email sera envoyé à " +
          "tous les prospecteurs pour les informer de cette re-publication)"
      )
    )
      return false;

    setIsPublishing(true);

    deleteMission(
      mission,
      false,
      (res) => {
        toast.success("Mission republiée avec succès");
        window.location.href = "/constats/" + res.data.id;
      },
      (err) => {
        toast.error(
          "Une erreur s'est produite lors de la republication du constat"
        );
      },
      () => setIsPublishing(false)
    );
  }

  function createTemplateFromMission() {
    setIsTemplating(true);

    Axios.post(API_URL + "missions/from/" + mission.id, {
      isTemplate: true,
    })
      .then((res) => {
        toast.success("Le template a été créé depuis le constat");
        window.location.href = "/constats/" + res.data.id;
      })
      .catch((err) => {
        toast.error(
          "Une erreur s'est produite lors de la création d'un template depuis le constat"
        );
      })
      .then(() => setIsTemplating(false));
  }

  return canRedirect ? (
    <Redirect to={"/missions/" + canRedirect} />
  ) : isLoading || !mission || !prospects ? (
    <Loader />
  ) : (
    <div className="row my-4 position-relative">
      <div className="col-12">
        <h4>Réglages</h4>
      </div>
      <div className="col-4 animated fadeInUp">
        <Control
          label="Statut"
          type="btnList"
          name="status"
          datas={state.constants.items.MISSION_STATUS}
          dataIndex="id"
          dataLabel="name"
          change={updateMission}
          value={mission.status}
          error={errors}
        />
        <Control
          label="Prospect"
          type="select"
          name="userProspect"
          datas={prospects}
          dataIndex="id"
          dataLabel="companyName"
          change={updateMission}
          value={mission.userProspect}
          error={errors}
        />
        <Control
          type="text"
          label="Nom du constat"
          name="name"
          value={mission.name}
          change={updateMission}
          error={errors}
        />
        <Control
          label="Est un sondage ?"
          type="checkbox"
          name="isSurvey"
          checked={mission.isSurvey}
          change={updateMission}
          error={errors}
        />
        <Control
          label="Possible de prévisualiser les questions ?"
          type="checkbox"
          name="isPreviewVisible"
          checked={mission.isPreviewVisible}
          change={updateMission}
          error={errors}
        />
        <Control
          label="Attribution automatique ?"
          type="checkbox"
          name="isAttributionAuto"
          checked={mission.isAttributionAuto}
          change={updateMission}
          error={errors}
        />
        <Control
          label="Mission interne ?"
          type="checkbox"
          name="isInternal"
          checked={mission.isInternal}
          change={updateMission}
          error={errors}
        />
      </div>
      <div className="col-4 animated fadeInUp">
        <div className="row">
          <div className="col-12">
            <Control
              label="Type de rémunération"
              value={mission.typeReward}
              name="typeReward"
              type="btnList"
              btnInline={true}
              datas={constants.MISSION_REWARD_TYPES}
              dataIndex="id"
              dataLabel="name"
              change={updateMission}
              error={errors}
            />
            <Control
              label="Rémunération automatique ?"
              name="isRewardAuto"
              type="checkbox"
              checked={mission.isRewardAuto}
              change={updateMission}
              error={errors}
            />
          </div>
          {mission.rewards.map((r, rk) => (
            <div className={"col-12 "} key={"missrew" + rk}>
              <Control
                type="number"
                label={"Niv." + (rk + 1)}
                name="rewards"
                k={rk}
                value={r}
                change={updateMissionRewards}
                suffix={
                  mission.typeReward == 1 ? (
                    <span className="h-100" style={{ lineHeight: "35px" }}>
                      €
                    </span>
                  ) : (
                    <span className="h-100" style={{ lineHeight: "35px" }}>
                      XP
                    </span>
                  )
                }
                error={errors}
              />
            </div>
          ))}
        </div>
        <Control
          label="Nombre de rapports souhaités"
          name="nbReport"
          type="number"
          value={mission.nbReport}
          change={updateMission}
          error={errors}
        />
      </div>
      <div className="col-4 animated fadeInUp">
        <Control
            label="Type de mission"
            type="btnList"
            name="type"
            datas={state.constants.items.MISSION_TYPES}
            dataIndex="id"
            dataLabel="name"
            change={updateMission}
            value={mission.type}
            error={errors}
          />
          {mission.type === "SPECIAL" ? (
            <Control
              label="Catégorie"
              type="select"
              name="specialType"
              datas={state.constants.items.MISSION_SPECIAL_TYPES}
              dataIndex="id"
              dataLabel="name"
              change={updateMission}
              value={mission.specialType}
              error={errors}
            />
          ) : mission.type === "PRICE_STATEMENT" && (
              <Control
                label="Magasin"
                type="select"
                name="store"
                datas={state.constants.items.MISSION_STORES}
                dataIndex="id"
                dataLabel="name"
                change={updateMission}
                value={mission.store}
                error={errors}
              />
          )}
        <Control
          label="Début de publication"
          type="date"
          name="beginAt"
          value={mission.beginAt}
          change={updateMission}
          error={errors}
        />
        <Control
          label="Fin de publication"
          type="date"
          name="endAt"
          value={mission.endAt}
          change={updateMission}
          error={errors}
        />
        <Control
          label="Délais max de réponse"
          name="answerMaxDelay"
          type="number"
          value={mission.answerMaxDelay}
          change={updateMission}
          suffix={
            <span className="h-100" style={{ lineHeight: "35px" }}>
              Heures
            </span>
          }
          error={errors}
        />
      </div>

      <div className="col-12">
        <h4>Localisation</h4>
      </div>
      <div className="col-12 animated fadeInUp">
        <Control
          name="address"
          type="text"
          label="Adresse du constat"
          value={mission.address}
          change={updateMission}
          error={errors}
        />
        <Control
          type="text"
          name="addressDescription"
          label="Description du lieu"
          value={mission.addressDescription}
          change={updateMission}
          error={errors}
        />
        <button className="btn btn-secondary w-100" onClick={getPosFromAddress}>
          <i className="fa fa-search mr-2" />
          Localiser sur la carte
        </button>

        <Map
          center={missionLocation}
          zoom={zoom}
          style={{ height: 300 }}
          ref={mapRef}
          onClick={mapClick}
          className="bx-outset"
          scrollWheelZoom={false}
        >
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={missionLocation}>
            <Popup>{mission.address}</Popup>
          </Marker>
          <Circle
            center={missionLocation}
            fillColor={"blue"}
            radius={mission.radius * 1000}
          />
        </Map>
        <Control
          className=" mt-2"
          label={"Rayon : " + mission.radius + "km"}
          type="range"
          min="0"
          max="100"
          step="1"
          value={mission.radius}
          change={updateMission}
          name="radius"
          error={errors}
        />
      </div>
      <div className="col-12 mt-3 animated fadeInUp">
        <h4>Images</h4>
        {mission.id ? (
          <ImagesUploader
            mission={mission}
            setMission={setMission}
            getMission={getMission}
          />
        ) : (
          <div className="alert alert-warning">
            <i className="fa fa-exclamation-triangle mr-2" /> Enregistrez la
            mission pour pouvoir envoyer des images
          </div>
        )}
        <div className="row">
          <div className="col-12 col-md-6">
            <h4>Description</h4>
            {
              <Editor
                apiKey={process.env.REACT_APP_TINY_MCE}
                value={mission.description}
                init={{
                  plugins: ["autolink link lists print preview"],
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright",
                  height: 482,
                }}
                onEditorChange={changeDescription}
              />
            }
            <span className="form-text text-danger">
              {errors["description"]}
            </span>
          </div>
          <div className="col-12 col-md-6">
            <h4>Aide</h4>
            {
              <Editor
                apiKey={process.env.REACT_APP_TINY_MCE}
                value={mission.help}
                init={{
                  plugins: ["autolink link lists print preview"],
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright",
                  height: 482,
                }}
                onEditorChange={changeHelp}
              />
            }
            <span className="form-text text-danger">{errors["help"]}</span>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end w-100 col-12 mt-4">
        {isEdit && (
          <>
            {" "}
            <SaveBtn
              type="primary"
              text="Créer un template depuis ce constat"
              save={createTemplateFromMission}
              isSaving={isTemplating}
            />
            <SaveBtn
              type="secondary"
              text="Dupliquer le constat"
              save={duplicateMission}
              isSaving={isDuplicating}
            />
            {!mission.isDeleted ? (
              <SaveBtn
                type="danger"
                text="Annuler le constat"
                save={cancelMission}
                isSaving={isCancelling}
              />
            ) : (
              <SaveBtn
                type="success"
                text="Republier le constat"
                save={republishMission}
                isSaving={isPublishing}
              />
            )}
          </>
        )}
        <SaveBtn
          save={save}
          text={
            "Enregistrer " + (mission.isTemplate ? "le template" : "le constat")
          }
          isSaving={isSaving}
        />
      </div>
    </div>
  );
};

export default MissionParameters;
