import React from "react";
import Control from "./Controls/Control";

const AnswerList = ({
  type,
  answers,
  k = 1,
  languages,
  addAnswer,
  removeAnswer,
  updateDataAnswerLabel,
  updateDataAnswerValue,
  updateDataAnswerScore,
  inputParams,
  updateInputParams,
  presetAnswer,
  errors,
}) => {
  var configInputParams = [];
  for (let key in inputParams) {
    configInputParams.push(
      <Control
        type="text"
        name={"inparams" + key}
        key={key}
        label={key}
        value={inputParams[key]}
        change={(e) => updateInputParams(e, key)}
      />
    );
  }

  return (
    <div className="row">
      {(type == 2 || type == 3 || type == 4) && (
        <>
          <div className="col-12 my-3">
            <label>
              <span className="d-block py-2">Questions pré-construites</span>
              <button
                className="btn btn-secondary"
                onClick={() => presetAnswer("yesno")}
              >
                Oui/Non
              </button>
            </label>
          </div>

          {answers.map((ans, ka) => (
            <div key={"anscont" + ka + k} className="col-12 col-md-6 my-2">
              <div className="card border-0 bg-transparent">
                <div className="card-body p-0">
                  <div className="card-title">
                    <h4 className="">
                      {"Réponse " + (ka + 1)}
                      <button
                        className="btn btn-danger float-right"
                        onClick={() => removeAnswer(ans._id)}
                      >
                        <i className="fa fa-times mr-2" />
                        Retirer
                      </button>
                    </h4>
                  </div>
                  <div className="bg-white">
                    <Control
                      className="col px-0"
                      label="valeur"
                      name="value"
                      type="number"
                      value={ans.value}
                      k={ka}
                      change={updateDataAnswerValue}
                      error={errors.answers ? errors.answers[ka] : {}}
                    />
                    <Control
                      className="col px-0"
                      label="Score par défaut sur l'échelle"
                      type="btnList"
                      btnInline={true}
                      name="score"
                      datas={[
                        { id: 1, name: "Très mauvais" },
                        { id: 2, name: "Mauvais" },
                        { id: 3, name: "Correct" },
                        { id: 4, name: "Très bien" },
                        { id: 5, name: "Excellent" },
                      ]}
                      dataLabel="name"
                      dataIndex="id"
                      value={ans.score}
                      k={ka}
                      change={updateDataAnswerScore}
                      error={errors.answers ? errors.answers[ka] : {}}
                    />
                    {languages.map((lang, kl) => (
                      <Control
                        key={"lang" + kl}
                        className="col px-0"
                        label={"Texte " + lang}
                        type="text"
                        value={ans.label[lang]}
                        k={kl}
                        change={(e, k) => updateDataAnswerLabel(e, ka, lang)}
                        error={errors.answers ? errors.answers[ka] : {}}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="col-12">
            <button className="btn btn-primary mb-2" onClick={addAnswer}>
              <i className="fa fa-plus mr-2"></i>Ajouter une réponse
            </button>
          </div>
        </>
      )}

      <div className="col-12">{configInputParams}</div>
    </div>
  );
};

export default AnswerList;
