import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import Axios from "axios";
import { API_URL } from "../../../config";
import useStore from "../../../context/useStore";

import Loader from "../../common/Loader";

import { modalActions } from "../../../context/actions/modalActions";
import Table from "../../common/Table/Table";
import { Link } from "react-router-dom";
import CategoryForm from './CategoryForm';
import FaqForm from './FaqForm';
import { toast } from "react-toastify";
import Control from "../../common/Controls/Control";

var strTimeout = null;

const Faqs = () => {
    const [state, dispatch] = useStore();
    const modal_actions = modalActions(state, dispatch);
    const [isLoading, setIsLoading] = useState(false);
    const [faqs, setFaqs] = useState([]);
    const [filteredFaqs, setFilteredFaqs] = useState([]);
    const [search, setSearch] = useState('');
    const [categories, setCategories] = useState([]);
    const [openCategories, setOpenCategories] = useState([]);
    const [errors, setErrors] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        clearTimeout(strTimeout);
        strTimeout = setTimeout(() => {
            loadFaqs();
            loadCategories();
            setIsLoading(false);
        }, 1500);
    }, []);

    function loadFaqs() {
        Axios.get(API_URL + "faqs")
            .then((res) => {
                let orderedFaq = sortByKey(res.data, 'sort_order');
                setFaqs(orderedFaq);
                setFilteredFaqs(orderedFaq);
            })
            .catch((err) => {
                setErrors(err.response.data);
                setFaqs([]);
                setFilteredFaqs([]);
            });
    }

    function sortByKey(array, key) {
        return array.sort(function(a, b) {
            var x = a[key]; var y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }

    function loadCategories() {
        Axios.get(API_URL + "categories")
            .then((res) => {
                setCategories(sortByKey(res.data, 'sort_order'));
            })
            .catch((err) => {
                setErrors(err.response.data);
                setCategories([]);
            });
    }

    const deleteFaq = (id) => {
        if (!window.confirm("Êtes-vous sûr de vouloir supprimer cette entrée ?")) return false;

        setIsLoading(true);

        Axios.delete(API_URL + "faqs/" + id)
            .then(res => {
                setFaqs(faqs.filter(faq => faq.id !== res.data.id))
            }).catch(err => {
                setErrors(err.response.data);
            })
        setIsLoading(false);
    };

    const deleteCategory = (id) => {
        if (!window.confirm("Êtes-vous sûr de vouloir supprimer cette catégorie ?")) return false;

        setIsLoading(true);

        Axios.delete(API_URL + "categories/" + id)
            .then(res => {
                setCategories(categories.filter(cat => cat.id !== res.data.id))
            }).catch(err => {
                setErrors(err.response.data);
            })
        setIsLoading(false);
    };

    const toggleCategory = (id) => {
        let cats = [];
        if (openCategories.includes(id)) {
            cats = openCategories.filter(openCatId => openCatId !== id);
        } else {
            cats = [
                ...cats,
                id,
            ]
        }
        setOpenCategories(cats);
    }

    const categoryForm = (category = null) => {
        let form = {
            name: '',
            ...category,
        }
        modal_actions.updateModal({
            isOpen: true,
            content: (
                <CategoryForm saveCategory={saveCategory} form={form}></CategoryForm>
            ),
        });
    }

    const saveCategory = (category) => {
        let method = category.id ? 'patch' : 'post';
        setIsLoading(true);

        Axios[method](API_URL + 'categories' + (category.id ? '/' + category.id : ''), category)
            .then(response => {
                loadCategories();
                modal_actions.updateModal({ isOpen: false, content: null });
                toast.success("La catégorie a bien été enregistré");
            }).catch(err => {
                setErrors(err.response.data);
                modal_actions.updateModal({ isOpen: false, content: null });
                toast.error(err.response.data.other);
            });
        setIsLoading(false);
    }

    const saveCategoriesSortOrder = (categories) => {
        setIsLoading(true);

        Axios.patch(API_URL + 'categories/sort', categories)
            .then(response => {
                setCategories(sortByKey(response.data, 'sort_order'));
                toast.success("L'ordre des catégories a bien été sauvegardé");
            }).catch(err => {
                setErrors(err.response.data);
                // reload categories original order
                loadCategories();
                toast.error(err.response.data.other);
            });
        setIsLoading(false);
    }

    const saveFaqsSortOrder = (faqs) => {
        setIsLoading(true);

        Axios.patch(API_URL + 'faqs/sort', faqs)
            .then(res => {
                let orderedFaq = sortByKey(res.data, 'sort_order');
                setFaqs(orderedFaq);
                setFilteredFaqs(orderedFaq);
                toast.success("L'ordre des FAQs a bien été sauvegardé");
            }).catch(err => {
                setErrors(err.response.data);
                // reload faqs original order
                loadFaqs();
                toast.error(err.response.data.other);
            });
        setIsLoading(false);
    }

    const faqForm = (faq) => {
        let form = {
            title: '',
            description: '',
            ...faq,
        }

        modal_actions.updateModal({
            isOpen: true,
            content: (
                <FaqForm categories={categories} saveFaq={saveFaq} form={form}></FaqForm>
            ),
        });
    }

    const saveFaq = (faq) => {
        let method = faq.id ? 'patch' : 'post';
        setIsLoading(true);

        Axios[method](API_URL + 'faqs' + (faq.id ? '/' + faq.id : ''), faq)
            .then(response => {
                loadFaqs();
                modal_actions.updateModal({ isOpen: false, content: null });
                toast.success("La FAQ a bien été enregistré");
            }).catch(err => {
                setErrors(err.response.data);
                modal_actions.updateModal({ isOpen: false, content: null });
                toast.error(err.response.data.other);
            });
        setIsLoading(false);
    }

    const sortCategories = (category, newIndex) => {
        // ensure categories are sorted
        let orderedCategories = sortByKey([...categories], 'sort_order');

        // move category to new index position
        orderedCategories.splice(newIndex, 0, orderedCategories.splice(orderedCategories.indexOf(category), 1)[0]);

        // reset sort_order keys
        let newOrder = [...orderedCategories].map((cat, index) => ({
            id: cat.id,
            name: cat.name,
            sort_order: index+1,
        }))

        saveCategoriesSortOrder(newOrder);
    }

    const sortFaqs = (faqList, faq, newIndex) => {
        // ensure faqs are sorted
        let orderedFaqs = sortByKey([...faqList], 'sort_order');
        let faqToReplace = orderedFaqs.filter(f => f.id === faq);

        let faqIndexToReplace = null;
        orderedFaqs.forEach((f, index) => {
            if (f.id === faq) {
                faqIndexToReplace = index;
            }
        });

        // move faq to new index position
        orderedFaqs.splice(newIndex, 0, orderedFaqs.splice(faqIndexToReplace, 1)[0]);

        // reset sort_order keys
        let newOrder = [...orderedFaqs].map((faq, index) => ({
            id: faq.id,
            title: faq.title,
            description: faq.description,
            sort_order: index+1,
        }))

        saveFaqsSortOrder(newOrder);
    }

    function updateSearch(e) {
        var value = e.target.value;

        if (!value) {
            setFilteredFaqs(faqs);
        } else {
            let filtered = faqs.filter(faq => faq.title.indexOf(value) !== -1 || faq.description.indexOf(value) !== -1);
            setFilteredFaqs(filtered);
        }

        setSearch(value);
    }

    return (
        <Page
            container="container"
            errors={errors}
            title={"Liste des FAQs"}
        >
            <div className="row mb-4">
                <div className="col">
                    <Control
                        type="text"
                        label="Chercher une FAQ"
                        name="title"
                        value={search}
                        change={updateSearch}
                        dataIndex="id"
                        dataLabel="title"
                        suffix={
                            <i
                                className="fa fa-search ml-2"
                                style={{ lineHeight: "40px" }}
                            ></i>
                        }
                        btnInline
                    />
                </div>
                <div className="col d-flex align-items-end flex-column">
                    {filteredFaqs.length === faqs.length ? (
                        <span>{categories.length} Catégorie(s) trouvée(s)</span>
                    ) : ''
                    }
                    <span className={faqs.length !== filteredFaqs.length ? 'text-danger' : ''}>{filteredFaqs.length} FAQ(s) trouvée(s)</span>
                </div>
            </div>
            <div className="row">
                <div className="col-12 p-4">
                    {errors && errors.other ? (
                        <div className="text-danger m-auto text-center">{errors.other}</div>
                    ) : isLoading ? (
                        <Loader />
                    ) :
                            <>
                                <div>
                                    {categories && categories.map((category, catIndex) => {
                                        let groupedFaq = filteredFaqs.filter(faq => faq.category === category.id);

                                        // hide category if no faq is found from search field
                                        if (groupedFaq.length === 0) {
                                            return <></>;
                                        }

                                        return <>
                                            <h4 className="mt-5 d-flex space-between">
                                                <button className="btn btn-default my-auto mr-2" onClick={e => toggleCategory(category.id)}>
                                                    <i className={openCategories.includes(category.id) ? "my-auto fa fa-caret-down" : "my-auto fa fa-caret-right"} />
                                                </button>
                                                <span className="my-auto">{catIndex+1}. {category.name}</span>
                                                <button
                                                    disabled={catIndex === 0}
                                                    title="Changer l'ordre d'affichage"
                                                    className={search !== '' ? 'd-none' : "btn btn-default d-inline-flex ml-auto"}
                                                    onClick={() => sortCategories(category, catIndex-1)}
                                                >
                                                    <i className="fa fa-arrow-up" />
                                                </button>
                                                <button
                                                    disabled={catIndex === (categories.length-1)}
                                                    title="Changer l'ordre d'affichage"
                                                    className={search !== '' ? 'd-none' : "btn btn-default d-inline-flex ml-1"}
                                                    onClick={() => sortCategories(category, catIndex+1)}
                                                >
                                                    <i className="fa fa-arrow-down" />
                                                </button>
                                                <button
                                                    className="btn btn-default d-inline-flex ml-2"
                                                    onClick={() => categoryForm(category)}
                                                >
                                                    Modifier
                                                </button>
                                                <button
                                                    disabled={groupedFaq.length > 0}
                                                    className="btn btn-danger d-inline-flex ml-1"
                                                    onClick={() => deleteCategory(category.id)}
                                                >
                                                    Supprimer
                                                </button>
                                            </h4>
                                            {faqs.length !== filteredFaqs.length ? <span className="text-danger">Recherche : {groupedFaq.length} FAQ(s) trouvée(s)</span> : ''}
                                            <hr />

                                            {openCategories.includes(category.id) ? (
                                                <>
                                                    {groupedFaq.length > 0 ? (
                                                        <Table
                                                            noExport
                                                            datas={filteredFaqs.filter(faq => faq.category === category.id)}
                                                            fields={[
                                                                { name: "Ordre", path: 'sort_order', render: (el, faqIndex) => <><p className="mx-4">{faqIndex+1}.</p></> },
                                                                { name: "Titre", path: 'title' },
                                                                { name: "Description", path: 'description' },
                                                                {
                                                                    name: "Actions",
                                                                    path: "id",
                                                                    render: (el, faqIndex) => {
                                                                        return (
                                                                            <>

                                                                                <button
                                                                                    disabled={faqIndex === 0}
                                                                                    title="Changer l'ordre d'affichage"
                                                                                    className={search !== '' ? 'd-none' : "btn btn-default d-inline-flex ml-auto"}
                                                                                    onClick={() => sortFaqs(groupedFaq, el, faqIndex-1)}
                                                                                >
                                                                                    <i className="fa fa-arrow-up" />
                                                                                </button>
                                                                                <button
                                                                                    disabled={faqIndex === (groupedFaq.length-1)}
                                                                                    title="Changer l'ordre d'affichage"
                                                                                    className={search !== '' ? 'd-none' : "btn btn-default d-inline-flex ml-1 mr-2"}
                                                                                    onClick={() => sortFaqs(groupedFaq, el, faqIndex+1)}
                                                                                >
                                                                                    <i className="fa fa-arrow-down" />
                                                                                </button>

                                                                                <button
                                                                                    className="btn btn-default d-center d-inline-flex"
                                                                                    onClick={() => faqForm({ category: category.id, ...faqs.filter(f => f.id === el) }[0])}
                                                                                >
                                                                                    <i className="fa fa-edit" />
                                                                                </button>
                                                                                <button
                                                                                    className="btn btn-danger d-inline-flex"
                                                                                    onClick={() => deleteFaq(el)}
                                                                                >
                                                                                    <i className="fa fa-times" />
                                                                                </button>
                                                                            </>
                                                                        );
                                                                    },
                                                                },
                                                            ]}
                                                        >
                                                        </Table>
                                                    ) : <>Vide</>
                                                    }

                                                    <div className="row my-2 mx-auto">
                                                        <button
                                                            className="btn btn-default d-center d-inline-flex"
                                                            onClick={() => faqForm({ category: category.id })}
                                                        >
                                                            Ajouter une FAQ
                                                        </button>
                                                    </div>
                                                </>
                                            ) : ''}
                                        </>;
                                    })
                                    }

                                    <button
                                        className="btn btn-success d-inline-flex ml-auto"
                                        onClick={() => categoryForm()}
                                    >
                                        Ajouter une catégorie
                                    </button>
                                </div>
                            </>
                    }
                </div>
            </div>
        </Page>
    );
};

export default Faqs;
