import React from "react";
import ReactApexChart from "react-apexcharts";
import Loader from "../../common/Loader";
import SaveBtn from "../../common/SaveBtn";
import Colors from "../../../theme/variables.scss";
import useProspectorStats from "./useProspectorStats";

const EvoAndChart = ({ url, year, displayTotal }) => {
  const {
    isLoading,
    chartState,
    getDatas,
    hasDatas,
    total,
    rankings,
  } = useProspectorStats(url, year);

  return (
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-end">
        <SaveBtn
          type={hasDatas ? "btn-default d-center" : "btn-primary d-center"}
          loaderColor={hasDatas ? Colors.primary : Colors.default}
          margin="m-0 get-stat-btn"
          save={getDatas}
          isSaving={isLoading}
          text={
            hasDatas ? (
              <>
                <i className="fa fa-sync mr-2" />
                Actualiser les données
              </>
            ) : (
              <>
                <i className="fa fa-cloud-download-alt mr-2" />
                Charger les données
              </>
            )
          }
        />
      </div>
      {!isLoading && hasDatas ? (
        <>
          <div className="col-12 col-md-6">
            <ReactApexChart
              options={chartState.pie.options}
              series={chartState.pie.series}
              type="pie"
              height={350}
            />
          </div>
          <div className="col-12 col-md-6">
            <ul className="list-group">
              <li className="list-group-item d-flex justify-content-between">
                Rang 1<span>{rankings["1"]}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                Rang 2<span>{rankings["2"]}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                Rang 3<span>{rankings["3"]}</span>
              </li>
            </ul>
          </div>

          <div className="col-12 text-center font-weight-bold">
            TOTAL : {total}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default EvoAndChart;
