import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import Axios from "axios";
import { API_URL, image_url } from "../../../config";
import { toast } from "react-toastify";
import Loader from "../../common/Loader";

const QuestionImagesUploader = ({ question, setQuestion }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isRemoveLoading, setIsRemoveLoading] = useState(false);
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    console.log(acceptedFiles);
    const formData = new FormData();
    formData.append("file", acceptedFiles[0]);

    uploadImages(formData);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  function uploadImages(formData) {
    setIsLoading(true);
    Axios({
      method: "POST",
      url: API_URL + "questions/" + question.id + "/image",
      data: formData,
      headers: {
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    })
      .then((res) => {
        var miss = JSON.parse(JSON.stringify(question));
        miss.image = res.data;
        setQuestion(miss);
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Une erreur d'envoi s'est produite, veuillez réessayer");
        setIsLoading(false);
      });
  }

  function removeImage(name) {
    if (isRemoveLoading) return;
    setIsRemoveLoading(name);
    Axios.delete(API_URL + "questions/" + question.id + "/image/")
      .then((res) => {
        setIsRemoveLoading(false);
        var miss = JSON.parse(JSON.stringify(question));
        miss.image = res.data.image;
        setQuestion(miss);
      })
      .catch((err) => {
        toast.error(
          "Erreur de suppression de l'image, rechargez la page et réessayez"
        );
        setIsRemoveLoading(false);
      });
  }

  return (
    <div className="row p-3 mb-3">
      {question.image ? (
        <div className="col-4 p-2 animated fadeInUp">
          <div
            className="bx-outset p-2 d-flex align-items-end img-block-preview position-relative"
            style={{
              backgroundImage: `url(${image_url + question.image.fileName})`,
              height: 200,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            {isRemoveLoading ? (
              <Loader style={{ position: "absolute", top: "25%" }} />
            ) : (
              <button
                className="btn btn-danger btn-sm position-absolute bxnone"
                style={{
                  top: "4%",
                  right: "4%",
                  boxShadow: "none" + "!important",
                }}
                onClick={() => removeImage(question.image.id)}
              >
                <i className="fa fa-times" />
              </button>
            )}
          </div>
        </div>
      ) : (
        <div
          {...getRootProps()}
          className="d-flex align-items-center justify-content-center p-3 text-center flex-column col-4 bx-inset"
          style={{
            border: "1px dashed #d0d0d0",
            borderRadius: 20,
            height: 200,
          }}
        >
          <input {...getInputProps()} />
          {isLoading ? (
            <Loader />
          ) : isDragActive ? (
            <p>Déposez une image ici ...</p>
          ) : (
            <>
              <p>Glissez-déposez une image</p>
              <p>ou</p>
              <p>cliquez pour parcourir vos fichiers</p>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default QuestionImagesUploader;
