import React, { useContext, useState, useEffect } from "react";
import useStore from "../../../context/useStore";
import { authActions } from "../../../context/actions/authActions";
import ForgotPassword from "./ForgotPassword";
import Control from "../../common/Controls/Control";
import SaveBtn from "../../common/SaveBtn";
import Timer from "../../common/Timer/Timer";
import { ReCaptcha } from "react-recaptcha-v3";
import { recaptcha_token, APP_NAME } from "../../../config";
import { NavLink } from "react-router-dom";
import FlipCard from "../../common/FlipCard/FlipCard";
import Register from "../Register/Register";
import tools from "../../../helpers/tools";
import LoginErrorBox from "./LoginErrorBox";

var timerInterval = null;
var recaptcha;

const Login = () => {
  const [state, dispatch] = useStore();
  var actions = authActions(state, dispatch);
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [forgotPassword, setForgotPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState(0);
  const [captchaToken, setCaptchaToken] = useState(false);
  const [register, setRegister] = useState(false);

  const [canLoggin, setCanLoggin] = useState(true);

  useEffect(() => {
    if (Object.values(state.errors).length > 0) {
      setIsLoading(false);
      if (state.errors.password) {
        setPassword("");
      }
      if (state.errors.timer) {
        clearInterval(timerInterval);
        setTimer(parseInt(state.errors.timer));
      }
    }
  }, [state.errors]);

  useEffect(() => {
    setTimer(false);
    setCanLoggin(true);

    setCaptchaToken(false);
    updateToken();
  }, [forgotPassword]);

  function loginHandle(e) {
    e.preventDefault();

    setIsLoading(true);
    localStorage.clear();
    console.log("cleared LS")
  }

  useEffect(() => {
    if (timer > 0) {
      setCanLoggin(false);
    }
  }, [timer]);

  function timerFinish() {
    setCanLoggin(true);
  }

  const verifyCallback = (recaptchaToken) => {
    // Here you will get the final recaptchaToken!!!
    if (!forgotPassword) {
      actions.loginUser({ login, password, captchaToken: recaptchaToken });
    }
    setCaptchaToken(recaptchaToken);
  };

  const updateToken = () => {
    // you will get a new token in verifyCallback
    if (recaptcha) {
      recaptcha.execute();
    }
  };

  return (
    <div
      className="container-fluid "
      style={{
        height: "110%",
      }}
    >
      <div
        className="row "
        style={{
          height: "110%",
        }}
      >
        <div className="col-12 mx-auto" style={{ maxWidth: 600 }}>
          {forgotPassword ? (
            <ForgotPassword
              setForgotPassword={setForgotPassword}
              captchaToken={captchaToken}
            />
          ) : (
            <form
              onSubmit={loginHandle}
              className="d-flex align-items-center justify-content-start flex-column mx-auto  login-form mt-5"
            >
              <div className=" text-center">
                <div className="mb-5">
                  <img src="/images/logo-inverted.svg" />
                </div>
                <h1 className="my-5 pt-5 pb-4 text-primary main-title">
                  Je me connecte sur {APP_NAME}
                  <hr className="w-50 mt-4" />
                </h1>
              </div>
              <Control
                className="w-75"
                autoFocus={true}
                label="Email"
                name="login"
                id="login"
                type="email"
                value={login}
                change={(e) => setLogin(e.target.value)}
                error={state.errors}
                isValid={tools.validateEmail(login)}
              />
              <div className="big-shadow w-75 m-auto">
                <Control
                  className="w-100"
                  label="Mot de passe"
                  name="password"
                  id="password"
                  type="password"
                  value={password}
                  change={(e) => setPassword(e.target.value)}
                  error={state.errors}
                  isValid={password.length > 6}
                  showValidation={false}
                />
              </div>
              <LoginErrorBox
                timer={timer}
                timerFinish={timerFinish}
                canLoggin={canLoggin}
              />
              <div className="w-75 mx-auto d-flex justify-content-end">
                {canLoggin && (
                  <SaveBtn
                    className="animated zoomIn"
                    isSaving={isLoading}
                    text="Je me connecte"
                    type="primary"
                    typeBtn="submit"
                  />
                )}
              </div>
              <div className="w-75 mx-auto d-flex justify-content-end mt-2">
                <button
                  type="button"
                  style={{ fontSize: 12 }}
                  onClick={() => setForgotPassword(true)}
                  className="btn btn-link p-0"
                >
                  mot de passe oublié ?
                </button>
              </div>
            </form>
          )}
        </div>

        {(isLoading || forgotPassword) && (
          <ReCaptcha
            ref={(ref) => (recaptcha = ref)}
            sitekey={recaptcha_token}
            action="login"
            verifyCallback={verifyCallback}
          />
        )}
      </div>
      <div
        className="mt-4 text-center px-3"
        style={{
          fontSize: 9,
          right: 0,
        }}
      >
        This site is protected by reCAPTCHA and the Google{" "}
        <a href="https://policies.google.com/privacy">Privacy Policy</a> and{" "}
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
      </div>
    </div>
  );
};

export default Login;
