import React from "react";
import useStore from "../../../context/useStore";
import Control from "../../common/Controls/Control";

const PaymentsFilters = ({ search, setSearch }) => {
  const [state, dispatch] = useStore();
  const constants = state.constants.items;

  return (
    <>
      <div className="col">
        <Control
          label="Entre le"
          type="date"
          name="beginAt"
          value={search.payment.beginAt}
          change={(e) =>
            setSearch({
              ...search,
              payment: { ...search.payment, beginAt: e.target.value },
            })
          }
        />
      </div>
      <div className="col">
        <Control
          label="Et le"
          type="date"
          name="endAt"
          value={search.payment.endAt}
          change={(e) =>
            setSearch({
              ...search,
              payment: { ...search.payment, endAt: e.target.value },
            })
          }
        />
      </div>
      <div className="col">
        <Control
          label="Statut"
          type="btnList"
          btnInline
          name="status"
          value={search.payment.status}
          datas={constants.PAYMENT_STATUS}
          dataIndex="id"
          dataLabel="name"
          change={(e) =>
            setSearch({
              ...search,
              payment: { ...search.payment, status: e.target.value },
            })
          }
        />
      </div>
      <div className="col-12">
        <Control
          label="Recherche"
          type="text"
          name="searchString"
          value={search.payment.searchString}
          change={(e) =>
            setSearch({
              ...search,
              payment: { ...search.payment, searchString: e.target.value },
            })
          }
          suffix={
            <i className="fa fa-search ml-2" style={{ lineHeight: "40px" }}></i>
          }
        />
      </div>
    </>
  );
};

export default PaymentsFilters;
