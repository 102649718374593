import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import dataService from "../../../helpers/dataService";
import PriceStatementFilters from "./PriceStatementFilters";
import { DateTime } from "luxon";
import useStore from "../../../context/useStore";
import SaveBtn from "../../common/SaveBtn";
import Loader from "../../common/Loader";
import BarChart from "../../common/Charts/BarChart";
import ExportCSV from "../../common/ExportCsv/ExportCsv";

const PriceStatement = () => {
    const [state, dispatch] = useStore()
    const constants = state.constants.items
    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [missions, setMissions] = useState([])
    const [isMissionInit, setIsMissionInit] = useState(false)
    const [reports, setReports] = useState([])
    const [prospects, setProspects] = useState([])
    const [productCategories, setProductCategories] = useState([])
    const [search, setSearch] = useState({
        mission: {
            searchString: "",
            type: "PRICE_STATEMENT",
            isDeleted: false,
            isInternal: false,
            isTemplate: false,
            status: "2",
            time: "",
            store: "",
            productCategory: "",
            productOrigin: "",
            userProspect: "",
            beginAt: DateTime.local().startOf('day').toISO(),
            endAt: DateTime.local().plus({ weeks: 2 }).minus({ days: 1 }).endOf('day').toISO()
        }, page: 1
    })

    useEffect(() => {
        getProspects()
        getProductCategories()
    }, [])

    useEffect(() => {
        setIsMissionInit(false)
    }, [search])

    useEffect(() => {
        if (isMissionInit) getReports(missions)
        setIsLoading(false)
    }, [isMissionInit])

    const getMissions = () => {
        setIsLoading(true)
        setIsMissionInit(false)
        dataService.post(`missions/search`, search, (res) => {
            setMissions(res.datas)
            setIsMissionInit(true)
        }, (err) => {
            setErrors(err.response)
            setMissions([])
        })
    }

    const getProspects = () => {
        dataService.get("prospects", setProspects)
    }

    const getProductCategories = () => {
        dataService.get("product-categories", setProductCategories)
    }

    const getReports = (missions) => {
        let missionIds = missions.map(m => m.id)
        dataService.post("missions/reports", {
            missions: missionIds,
            filters: {
                productCategory: search.mission.productCategory,
                productOrigin: search.mission.productOrigin
            }
        }, setReports, (err) => console.log(err))
    }

    const handleSearch = () => {
        setReports([])
        getMissions()
    }

    return (
        <Page
            container={"container"}
            title="Relevé de prix"
            errors={errors}
        >
            {prospects.length ? (
                <PriceStatementFilters
                    search={search}
                    setSearch={setSearch}
                    prospects={prospects}
                    stores={constants.MISSION_STORES}
                    productCategories={productCategories}
                    productOrigin={constants.PRODUCT_ORIGIN}
                />
            ) : null}
            <div className="row">
                <div className="col-12 d-center">
                    <SaveBtn
                        text="Rechercher"
                        isSaving={isLoading}
                        save={handleSearch}
                    />
                </div>
            </div>
            <div className="row mb-4">
                {reports &&
                    <ExportCSV
                        datas={reports}
                        fields={[
                            {
                                name: "Produit",
                                path: "product"
                            },
                            ...(constants.MISSION_STORES.sort((a, b) => a.id - b.id).map(x => (
                                {
                                    name: x.name,
                                    path: x.name
                                }
                            )))
                        ]}
                        customFileName={`Relevé prix ${DateTime.fromISO(search.mission.beginAt).toLocaleString()} - ${DateTime.fromISO(search.mission.endAt).toLocaleString()}`}
                        exportOptions={{
                            formatDatasFn: data =>
                                data.map(d => ({
                                    ...d,
                                    product: `${d.product.name} (${constants.PRODUCT_ORIGIN.find(x => x.id === d.product.origin)?.name})`,
                                    LECLERC: d.answers.find(a => a.store === "1")?.value || null,
                                    'HYPER U': d.answers.find(a => a.store === "2")?.value || null,
                                    INTERMARK: d.answers.find(a => a.store === "3")?.value || null,
                                    AUCHAN: d.answers.find(a => a.store === "4")?.value || null,
                                    'RUN MARKET': d.answers.find(a => a.store === "5")?.value || null,
                                    'LEADER PRICE': d.answers.find(a => a.store === "6")?.value || null,
                                    'CARREFOUR': d.answers.find(a => a.store === "7")?.value || null,
                                })),
                            excludedFields: []
                        }}
                    />
                }
            </div>
            <div className="row">
                {isLoading ? <Loader/> : reports.map((r, rk) => {
                    let data = constants.MISSION_STORES.map(x => r.answers.find(a => a.store === x.id.toString())?.value || null)
                    let amp = (((Math.max(...data.filter(x => x !== null && x !== "0")) / Math.min(...data.filter(x => x !== null && x !== "0.00")) - 1) * 100).toFixed(2))
                    return (
                        <div className="col border m-2 pt-4" key={rk}>
                            <p className="text-center mb-0">
                                {`${r.product?.name} 
                                (${constants.PRODUCT_ORIGIN.find(x => x.id === r.product?.origin)?.name})
                                du ${DateTime.fromISO(search.mission.beginAt).toLocaleString()} 
                                au ${DateTime.fromISO(search.mission.endAt).toLocaleString()}`}
                            </p>
                            <p className="text-center">
                                {`${amp}% d'amplitude de prix entre les enseignes`}
                            </p>
                            <BarChart
                                categories={constants.MISSION_STORES.sort((a, b) => a.id - b.id).map(x => x.name)}
                                data={data}
                                name={r.product?.name}
                            />
                        </div>
                    )
                })}
            </div>
        </Page>
    )
}

export default PriceStatement
