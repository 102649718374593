import React, { useState, useEffect } from "react";
import "./Table.scss";
import ExportCSV from "../ExportCsv/ExportCsv";

const Table = ({
  datas,
  fields,
  exportOptions,
  noExport = false,
  noMargin = false,
  headerContent = null,
}) => {
  return (
    <div className={!noMargin ? "mt-4" : ""}>
      {!noExport && (
        <div className="d-flex justify-content-end">
          {headerContent}
          <ExportCSV
            fields={[...fields, ...(exportOptions?.additionalFields || [])]}
            datas={datas}
            exportOptions={exportOptions}
          />
        </div>
      )}

      <table className="table table-striped table-borderless shadow-sm custom-table">
        <thead>
          <tr>
            {fields.map((h, hk) => (
              <th key={"th" + hk + h.name}>{h.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {datas &&
            datas.map((d, dk) => (
              <tr
                key={"dk" + dk}
                className="animated fadeInUp faster"
                style={{
                  animationDelay: dk * 30 + "ms",
                }}
              >
                {fields.map((f, fk) => (
                  <td
                    className={fk == fields.length - 1 ? "text-right" : ""}
                    style={{ verticalAlign: "middle" }}
                    key={"df" + dk + "" + fk}
                  >
                    {f.render
                      ? f.render(f.path == "*" ? d : d[f.path], dk)
                      : d[f.path]}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
