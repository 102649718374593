import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Page from "../../common/layout/Page";
import Axios from "axios";
import { API_URL } from "../../../config";
import Control from "../../common/Controls/Control";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import tools from "../../../helpers/tools";
import Table from "../../common/Table/Table";
import { DateTime } from "luxon";
import { modalActions } from "../../../context/actions/modalActions";
import dataService from "../../../helpers/dataService";
import PaymentsFilters from "./PaymentsFilters";
import SaveBtn from "../../common/SaveBtn";
import StatusChoice from "./StatusChoice";
import ProspectorDetail from "../ProspectorDetail/ProspectorDetail";

var strTimeout = null;

const Payments = () => {
  const [state, dispatch] = useStore();
  const modal_actions = modalActions(state, dispatch);
  const paymentStatuses = state.constants.items.PAYMENT_STATUS;
  const [payments, setPayments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const [search, setSearch] = useState({
    payment: {
      beginAt: DateTime.local().minus({ weeks: 1 }).toISO(),
      endAt: DateTime.local().plus({ days: 1 }).toISO(),
      status: "WAITING",
      searchString: "",
    },
    page: 1,
  });
  const [selectedPayments, setSelectedPayments] = useState([]);
  const [isLsLoaded, setIsLsLoaded] = useState(false);

  useEffect(() => {
    async function getLS() {
      let lsState = new Promise((resolve, reject) => {
        resolve(tools.getState("Payments", { search: search }));
      });
      let result = await lsState;
      setSearch(result.search);
      setIsLsLoaded(true);
    }

    getLS();
  }, []);

  useEffect(() => {
    if (isLsLoaded) {
      searchPayments();
    }
  }, [isLsLoaded]);

  const searchPayments = () => {
    setIsLoading(true);
    setSelectedPayments([]);
    tools.saveState("Payments", "search", search);
    dataService.post("payments/search", search, setPayments, setErrors, () =>
      setIsLoading(false)
    );
  };

  const toggleSelected = (elem) => {
    if (selectedPayments.find((s) => s.id == elem.id)) {
      setSelectedPayments(selectedPayments.filter((s) => s.id != elem.id));
    } else {
      setSelectedPayments([...selectedPayments, { ...elem }]);
    }
  };

  const updateStatus = () => {
    modal_actions.updateModal({
      isOpen: true,
      content: (
        <StatusChoice
          selectedPayments={selectedPayments}
          saveStatus={saveStatus}
        />
      ),
    });
  };

  const saveStatus = (status) => {
    dataService.patch(
      "payments/updatemany",
      {
        ids: selectedPayments.map((s) => s.id),
        status,
      },
      (datas) => {
        modal_actions.updateModal({
          isOpen: false,
          content: null,
        });
        setSelectedPayments(
          selectedPayments.map((s) => ({
            ...s,
            toStatus: status,
            status,
          }))
        );
      },
      setErrors,
      () => setIsLoading(false)
    );
  };

  useEffect(() => {
    var hasStatusChange = false;
    selectedPayments.forEach((s) => {
      if (s.toStatus) {
        hasStatusChange = true;
      }
    });
    if (hasStatusChange) {
      document.querySelector(".export-csv").click();

      setTimeout(() => {
        document.querySelector(".download-csv").click();
        setTimeout(() => {
          searchPayments();
        }, 1000);
      }, 1000);
    }
  }, [selectedPayments]);

  const viewProspector = (id) => {
    modal_actions.updateModal({
      isOpen: true,
      content: <ProspectorDetail prospectorId={id} />,
    });
  };

  return (
    <Page
      container="container"
      // action={{ to: "/prospectors/create", text: "Créer un prospect" }}
      errors={errors}
      title={"Liste des paiements"}
    >
      <div className="row mb-4">
        <PaymentsFilters search={search} setSearch={setSearch} />
        <div className="col-12 d-center">
          {payments.length} paiements trouvés
        </div>
        <div className="col-12 mt-2">
          <SaveBtn
            className="w-100"
            save={searchPayments}
            isSaving={isLoading}
            text="Rechercher"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 p-4">
          {errors && errors.other ? (
            <div className="text-danger m-auto text-center">{errors.other}</div>
          ) : isLoading ? (
            <Loader />
          ) : (
            <Table
              datas={payments}
              fields={[
                {
                  name: "Statut",
                  path: "status",
                  render: (el) => {
                    let status = paymentStatuses.find((s) => s.id == el);
                    return (
                      <div
                        className={`bg-${status.color} text-center text-white d-center`}
                      >
                        {status.name}
                      </div>
                    );
                  },
                },
                {
                  name: "Prospecteur",
                  path: "userProspector",
                  render: (el) => (
                    <button
                      className="btn btn-link"
                      onClick={() => viewProspector(el.id)}
                    >
                      {tools.getFullName(el)}
                    </button>
                  ),
                },
                {
                  name: "Email",
                  path: "userProspector",
                  render: (el) => el.email,
                },
                {
                  name: "IBAN",
                  path: "*",
                  exportPath: "iban",
                  render: (el) =>
                    tools.chunk(el.userProspector.bankAccount?.iban, 4),
                },
                {
                  name: "Mission",
                  path: "missionName",
                },
                {
                  name: "Montant",
                  path: "amount",
                  render: (el) => Math.round(parseInt(el) * 100) / 10000 + " €",
                },
                {
                  name: "Créé le",
                  path: "createdAt",
                  render: (el) => DateTime.fromISO(el).toFormat("dd/MM/yyyy"),
                },
                {
                  name: "Sélect.",
                  path: "*",
                  render: (el) => {
                    let isSelected = selectedPayments.find(
                      (s) => s.id == el.id
                    );
                    return el.userProspector.bankAccount?.status != 4 ? (
                      <button
                        className="btn btn-danger d-center"
                        style={{
                          width: 40,
                          height: 40,
                        }}
                        onClick={() => alert("L'IBAN n'a pas été validé")}
                      >
                        <i className="fa fa-times" />
                      </button>
                    ) : (
                      <button
                        style={{
                          width: 40,
                          height: 40,
                        }}
                        className={`btn d-center ${
                          isSelected ? "btn-primary" : "btn-default border"
                        }`}
                        onClick={(e) => toggleSelected(el)}
                      >
                        {isSelected ? (
                          <i className="fa fa-check animated bounceIn" />
                        ) : (
                          <i className="fa fa-plus text-muted" />
                        )}
                      </button>
                    );
                  },
                },
              ]}
              exportOptions={{
                overrideDatas: payments,
                formatDatasFn: (datas) =>
                  datas.map((r) => ({
                    ...r,
                    status: paymentStatuses.find((s) => s.id == r.status).name,
                    userProspector: {
                      name: tools.getFullName(r.userProspector),
                    },
                    amount: Math.round(parseInt(r.amount) * 100) / 10000,
                    iban: tools.chunk(r.userProspector.bankAccount?.iban, 4),
                    updatedAt: DateTime.fromISO(r.updatedAt).toFormat(
                      "dd/MM/yyyy HH'h'mm"
                    ),
                  })),

                excludedFields: ["Sélect."],
                objectFields: {
                  userProspector: {
                    dataIndexes: ["name"],
                  },
                },
              }}
              headerContent={
                selectedPayments.length ? (
                  <button className="btn btn-secondary" onClick={updateStatus}>
                    Exporter et mettre à jour le statut
                  </button>
                ) : null
              }
            />
          )}
        </div>
      </div>
    </Page>
  );
};

export default Payments;
