import React, { useState } from "react";
import dataService from "../../../helpers/dataService";

const useParticipationChart = (url, year) => {
  const [isLoading, setIsLoading] = useState(false);
  const [chartState, setChartState] = useState(false);
  const [total, setTotal] = useState(0);
  const [top5, setTop5] = useState([]);

  const months = [
    "Jan",
    "Fev",
    "Mar",
    "Avr",
    "Mai",
    "Juin",
    "Juil",
    "Aou",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const getDatas = () => {
    setIsLoading(true);

    dataService.post(
      `${url}`,
      { year },
      (datas) => {
        setChartState({
          pie1: {
            series: datas.status.map((s) => Object.values(s)[0]),
            options: {
              labels: datas.status.map((s) => Object.keys(s)[0]),
              legend: { position: "bottom" },
            },
          },
        });
        setTop5(datas.top5);
        setTotal(datas.total);
      },
      (e) => {},
      () => setIsLoading(false)
    );
  };

  var hasDatas = chartState;

  return {
    isLoading,
    getDatas,
    chartState,
    hasDatas,
    total,
    top5,
  };
};

export default useParticipationChart;
