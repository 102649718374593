import React, { useEffect, useState } from "react";
import Page from "../../../common/layout/Page";
import Loader from "../../../common/Loader";
import useStore from "../../../../context/useStore";
import AnswerInterpretor from "./AnswerInterpretor";
import "./form-generator.css";
import Axios from "axios";
import { API_URL } from "../../../../config";

const FormGenerator = ({ missionId }) => {
  const [state, dispatch] = useStore();
  const [mission, setMission] = useState(false);
  const [answers, setAnswers] = useState(false);
  const constants = state.constants.items;
  const [step, setStep] = useState(0);
  const [oldStep, setOldStep] = useState(0);

  useEffect(() => {
    if (missionId) {
      getForm();
    }
  }, [missionId]);

  function getForm() {
    Axios.get(API_URL + "missions/" + missionId + "/form")
      .then((res) => {
        setMission(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (mission) {
      var ans = [];
      mission.questions.forEach((q) => {
        ans.push({
          question: q._id,
          value: "",
        });
      });

      setAnswers(ans);
    }
  }, [mission]);

  function updateAnswer(e) {
    var ans = JSON.parse(JSON.stringify(answers));
    ans.find((a) => a.question == e.target.name).value = e.target.value;
    setAnswers(ans);
  }

  function prevStep() {
    setOldStep(step);
    setStep(step - 1);
  }
  function nextStep() {
    setOldStep(step);
    setStep(step + 1);
  }

  useEffect(() => {
    if (mission && answers) {
      var cond = mission.questions[step].condition;

      if (cond != "") {
        cond = cond.split("&&");
        cond.forEach((c) => {
          var obj = c.split("==");
          var targetValue = obj[1];
          var qsInitialOrder = parseInt(obj[0].split("-")[1]);
          var targetQuestion = mission.questions.find(
            (q) => q.initialOrder == qsInitialOrder
          )._id;
          if (
            answers.find((a) => a.question == targetQuestion).value !=
            targetValue
          ) {
            if (oldStep > step) {
              setStep(step - 1);
            } else {
              setStep(step + 1);
            }
          }
        });
      }
    }
  }, [step]);

  return (
    <div id="mission_container">
      {!mission || !answers ? (
        <Loader />
      ) : (
        mission.sections.map((s, sk) => (
          <div key={"misssk" + sk}>
            <h2 id="mission_section_title">{s.name}</h2>
            <p id="mission_counter">
              Question{" "}
              <b>
                {step + 1}/{mission.questions.length}
              </b>
            </p>
            {mission.questions
              .filter((q) => q.section == sk)
              .map((q, qk) => (
                <div
                  key={"q" + qk}
                  className={
                    "question_container animated " +
                    (step == qk
                      ? oldStep < step
                        ? "fadeInRight"
                        : "fadeInLeft"
                      : "hidden")
                  }
                >
                  <div className="question_header">
                    <h4 className="question_title">{q.question}</h4>
                    <p className="question_description">{q.description}</p>
                    <div className="question_answer_container">
                      <AnswerInterpretor
                        question={q}
                        k={qk}
                        inputTypes={constants.INPUT_TYPES}
                        updateAnswer={updateAnswer}
                        answers={answers}
                      />
                    </div>
                  </div>
                  <div className="question_navigation">
                    {step > 0 && (
                      <button
                        className="btn_nav btn_nav_prev"
                        onClick={prevStep}
                      >
                        <i className="fa fa-chevron-left"></i>
                      </button>
                    )}
                    {step < mission.questions.length - 1 && (
                      <button
                        className="btn_nav btn_nav_next"
                        onClick={nextStep}
                      >
                        Valider
                      </button>
                    )}
                  </div>
                </div>
              ))}
          </div>
        ))
      )}
    </div>
  );
};

export default FormGenerator;
