import { toast } from "react-toastify";
import { ERROR_TYPES } from "../context/reducers/errorReducers";
import axios from "axios";

const tools = {
  setAuthToken: (token) => {
    if (token) {
      // Apply to every request
      axios.defaults.headers.common["Authorization"] = token;
    } else {
      // Delete Auth header
      delete axios.defaults.headers.common["Authorization"];
    }
  },
  isEmpty: (value) => {
    return (
      value === undefined ||
      value === null ||
      !value ||
      (typeof value === "object" && Object.keys(value).length === 0) ||
      (typeof value === "string" && value.trim().length === 0)
    );
  },
  copyText: (ref) => {
    ref.current.select();
    ref.current.setSelectionRange(0, 99999);
    document.execCommand("copy");
  },
  getSubThemeFromId: (THEMES, id) => {
    var subTheme = "";
    THEMES.forEach((th) => {
      th.subThemes.forEach((sth) => {
        if (sth._id == id) {
          subTheme = sth.name;
        }
      });
    });
    return subTheme;
  },
  truncate: (text, n) => {
    return text.length > n ? text.substr(0, n - 1) + "..." : text;
  },

  filterError: (errors, dispatch = null, type) => {
    var errorData = {};
    if (errors.response) {
      var status = errors.response.status;

      if (status == 404) {
        toast.error("La route n'a pas été trouvée");
      }

      if (errors.response.data) {
        if (errors.response.data.other) {
          toast.warning("Erreur : " + errors.response.data.other);
          errorData = errors.response.data;
        } else {
          errorData = errors.response.data;
        }
      }
    } else {
      toast.error("Erreur interne : " + errors);
    }

    if (dispatch) {
      dispatch({ type: type, payload: false });
      dispatch({ type: ERROR_TYPES.GET_ERRORS, payload: errorData });
    }
    return errorData;
  },
  filterAlert: (type, message) => {
    switch (type) {
      case "success":
        toast.success(message);
        break;
      case "info":
        toast.info(message);
        break;

      default:
        break;
    }
  },
  getState: (page, initial) => {
    var ls = localStorage.getItem(page);
    console.log(ls);
    if (ls) {
      return JSON.parse(ls);
    }
    return initial;
  },

  saveState: (page, name, value) => {
    var ls = localStorage.getItem(page);
    if (ls) {
      ls = JSON.parse(ls);
    } else {
      ls = {};
    }
    ls[name] = value;

    localStorage.setItem(page, JSON.stringify(ls));
    console.log(localStorage.getItem(page));
  },

  isParentRoleActive(roleKey, roleConfig, dependsOn) {
    if (!dependsOn) {
      return true;
    }
    var isActive = true;
    dependsOn.forEach((d) => {
      if (!roleConfig[d][roleKey]) {
        isActive = false;
      }
    });
    return isActive;
  },
  validateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }

    return false;
  },
  validateTel(tel) {
    return tel.match(/^\d{10}$/);
  },
  getFullAddress(addr) {
    return (
      addr.address +
      " - " +
      addr.location.postalCode +
      ", " +
      addr.location.name
    );
  },
  getFullName: (elem) => {
    return !elem
      ? ""
      : (elem.gender == 1 ? "Mr " : "Mme ") +
          elem.firstname +
          " " +
          elem.lastname;
  },
  arrayMove: (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  },
  calculateNewConditions: (qs) => {
    var quests = JSON.parse(JSON.stringify(qs));
    var noError = true;
    quests.forEach((q, k) => {
      var conds = q.condition.split("&&");
      var temp = JSON.parse(JSON.stringify(conds));

      conds.forEach((c, ck) => {
        if (c != "") {
          var sp = c.split("==");
          var initialOrder = sp[0].split("-")[1];
          console.log("cond", c, initialOrder);

          quests.forEach((qi, qik) => {
            if (qi.initialOrder == initialOrder) {
              if (qik >= k || parseInt(qi.section) > parseInt(q.section)) {
                var canDeletePos = window.confirm(
                  "Ce déplacement est incompatible avec la condition " +
                    c +
                    " de la question " +
                    (k + 1) +
                    ".\nLa condition incompatible sera donc supprimée.\nConfirmez-vous cette action ?"
                );
                if (canDeletePos) {
                  temp[ck] = false;
                } else {
                  noError = false;
                  console.log("CANCELED !");
                }
              }
            }
          });
        }
      });
      temp = temp.filter((t) => t != false);
      q.condition = temp.join("&&");
    });
    console.log("quests", quests, noError);
    quests = tools.sortQuestions(quests);
    return noError ? quests : false;
  },
  sortQuestions: (qs) => {
    var sqs = qs.sort((a, b) => {
      if (a.section > b.section) return 1;
      if (b.section > a.section) return -1;

      return 0;
    });
    return sqs;
  },
  calcPhotosScore: (photos) => {
    return Math.floor(
      photos.map((p) => p.score).reduce((a, b) => a + b, 0) /
        parseInt(photos.length)
    );
  },
  chunk: (str, n) => {
    var ret = [];
    var i;
    var len;
    if (!str?.length) return "";

    for (i = 0, len = str.length; i < len; i += n) {
      ret.push(str.substr(i, n));
    }

    return ret.join(" ");
  },
  shouldSkipQuestion: (answers, step, questions) => {
    var result = false;
    let question = questions[step];
    if (question.condition != "") {
      var conds = question.condition.split("&&");
      conds.forEach((cond) => {
        var condSplit = cond.split("==");
        var questionNum = condSplit[0].split("-")[1];
        var condValue = condSplit[1];
        var targetAnswerId = questions.find(
          (q) => q.initialOrder == questionNum
        )._id;
        if (!answers[targetAnswerId].includes(condValue)) {
          result = true;
        }
      });
    }

    return result;
  },
};

export default tools;
