import React, { useState, useEffect } from "react";

const PrintReport = ({ missionName, isVisible, setIsVisible, report }) => {
    const [printMenuOpen, setPrintMenuOpen] = useState(false);
    const [printEvent, setPrintEvent] = useState(false);

    const handlePrintMenu = () => {
        setIsVisible(true);
        setPrintMenuOpen(!printMenuOpen);
    };

    const print = () => {
        window.print();
        setIsVisible(false);
        setPrintMenuOpen(false);
        setPrintEvent(false);
    };

    useEffect(() => {
        if (isVisible && printEvent) {
            document.title = missionName;
            print();
        }
    }, [isVisible, printEvent]);

    return (
        <>
            <div className="d-flex justify-content-end mr-3 mt-3">
                <div
                    className={`no-print d-flex flex-row flex-wrap ${
                        printMenuOpen
                            ? "animated fadeInUp faster"
                            : "animated fadeOutDown faster"
                    }`}
                >
                    <button
                        className="no-print btn btn-light rounded-lg mr-2 mb-2"
                        onClick={() => {
                            setIsVisible(true);
                            setPrintEvent(true);
                        }}
                    >
                        Imprimer la page complète
                    </button>
                    {report.sections.map((r, rk) => (
                        <button
                            key={rk}
                            className="no-print btn btn-light rounded-lg mr-2 mb-2"
                            onClick={() => {
                                setIsVisible(r.id);
                                setPrintEvent(true);
                            }}
                        >
                            Imprimer la section {rk + 1}
                        </button>
                    ))}
                </div>
                <button
                    onClick={handlePrintMenu}
                    className="no-print form-control mr-2 mt-1"
                    style={{ width: "auto" }}
                >
                    Imprimer
                    <i className="fas fa-print ml-2"/>
                </button>
            </div>
        </>
    );
};

export default PrintReport;