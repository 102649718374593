import Axios from "axios";
import { data } from "jquery";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { API_URL } from "../../../config";
import useStore from "../../../context/useStore";
import dataService from "../../../helpers/dataService";
import Control from "../../common/Control";
import DynamicList from "../../common/DynamicList";
import Page from "../../common/layout/Page";
import SaveBtn from "../../common/SaveBtn";

const ThemeDetail = (props) => {
  const [state, dispatch] = useStore();
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [theme, setTheme] = useState({
    name: "",
    code: "",
    subThemes: [],
  });
  const itemId = props.match.params.id;
  const isEdit = itemId == "create" ? false : true;

  useEffect(() => {
    if (isEdit) {
      getDatas();
    }
  }, []);

  const getDatas = () => {
    dataService.get("themes/" + itemId, setTheme, setErrors, () =>
      setIsLoading(false)
    );
  };

  const save = () => {
    const onError = (error) => {
      toast.error(error);
      setErrors(error);
    };
    setIsLoading(true);
    if (isEdit) {
      const onSuccess = (datas) => {
        toast.success("Thème enregistré avec succès");
      };
      dataService.patch(
        "themes/" + itemId,
        {
          ...theme,
          subThemes: theme.subThemes.sort((a, b) => (a.name > b.name ? 1 : -1)),
        },
        onSuccess,
        onError,
        () => setIsLoading(false)
      );
    } else {
      const onSuccess = (datas) => {
        toast.success("Thème créé avec succès");
        window.location.href = "/themes/" + datas.id;
      };

      dataService.post("themes", theme, onSuccess, onError, () =>
        setIsLoading(false)
      );
    }
  };

  return (
    <Page
      container="container"
      back="/themes"
      title="Détail du thème"
      errors={errors}
    >
      <div className="row">
        <div className="col-12 p-0">
          <Control
            label="Nom"
            name="name"
            type="text"
            value={theme.name}
            change={(e) => setTheme({ ...theme, name: e.target.value })}
            error={errors}
            setErrors={setErrors}
          />
        </div>
        <div className="col-12 p-0">
          <DynamicList
            label="Sous-thèmes"
            name="subThemes"
            change={(e) =>
              setTheme({
                ...theme,
                subThemes: e.target.value,
              })
            }
            fields={[
              {
                label: "Nom",
                type: "text",
                name: "name",
              },
            ]}
            value={theme.subThemes}
            btnLabel="Ajouter"
            error={errors}
          />
        </div>
        <div className="d-flex justify-content-end w-100">
          <SaveBtn
            save={save}
            isSaving={isLoading}
            text="Enregistrer"
            className="btn btn-success"
          />
        </div>
      </div>
    </Page>
  );
};

export default ThemeDetail;
