import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import useStore from "../../../context/useStore";
import { API_URL } from "../../../config";
import Axios from "axios";
import { DateTime } from "luxon";
import Loader from "../../common/Loader";
import { toast } from "react-toastify";
import Control from "../../common/Controls/Control";
import MissionListFilters from "./MissionListFilters";
import dataService from "../../../helpers/dataService";
import SaveBtn from "../../common/SaveBtn";
import Pagination from "../../common/Pagination";
import tools from "../../../helpers/tools";

const MissionList = (props) => {
  const [state, dispatch] = useStore();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(false);
  const [missions, setMissions] = useState([]);
  const [prospects, setProspects] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [nbPages, setNbPages] = useState(1);
  const [isInit, setIsInit] = useState(false);

  const userRole = state.auth.user.role;
  const ROGUE_ADMIN = state.constants.items.ROLES_MANAGER.find(
    (r) => r.id === "ROGUE_ADMIN"
  );
  
  const [search, setSearch] = useState({
    mission: {
      missionType: -1, //-1 tous | 0 constats | 1 sondages
      // beginAt: DateTime.local().minus({ months: 1 }).toISO(),
      // endAt: DateTime.local().toISO(),
      status: 2, //1 brouillon | 2 actif
      isTemplate: false, //true que les templates
      isDeleted: false, //true que les annulés
      isInternal: userRole === ROGUE_ADMIN.id ? true : false,
      userProspect: props.prospectId || "",
      time: "progress",
      seachString: "",
    },
    page: 1,
  });
  const [isLsLoaded, setIsLsLoaded] = useState(false);


  function changePage(i) {
    setSearch((search) => {
      return { ...search, page: i };
    });
  }

  useEffect(() => {
    async function getLs() {
      let lsState = new Promise((resolve, reject) => {
        resolve(tools.getState("MissionList", { search: search }));
      });
      let result = await lsState;
      setSearch(result.search);
      setIsLsLoaded(true);
    }

    getLs();
  }, []);

  useEffect(() => {
    if (isLsLoaded) {
      searchMissions();
      getProspects();
    }
  }, [isLsLoaded]);

  useEffect(() => {
    tools.saveState("MissionList", "search", search);
  }, [search]);

  useEffect(() => {
    if (isInit) {
      searchMissions();
    }
  }, [search.page]);

  const getProspects = () => {
    dataService.get("prospects", setProspects);
  };

  const searchMissions = () => {
    if (userRole === ROGUE_ADMIN.id) {
      setSearch({
        ...search,
        mission: {
          ...search.mission,
          isInternal: true,
        },
      });
    }
    setIsLoading(true);
    dataService.post(
      `missions/search`,
      search,
      (datas) => {
        setNbPages(Math.ceil(parseInt(datas.count) / 25));

        setMissions(datas.datas);
        setIsInit(true);
      },
      (e) => {},
      () => setIsLoading(false)
    );
  };

  function removeMission(id, isTemplate) {
    dataService.remove(
      `missions/${id}`,
      (datas) => {
        toast.success("Element supprimé");
        searchMissions();
      },
      (e) => {},
      () => {}
    );
  }

  function goToMissionTab(missionId, tab) {
    props.history.push(
      `/constats/${missionId}?${new URLSearchParams({ tab }).toString()}`
    );
  }

  return (
    <Page
      container="container"
      title="Liste des constats"
      action={{ to: "/constats/create", text: "Créer un constat" }}
    >
      {prospects.length ? (
        <MissionListFilters
          search={search}
          setSearch={setSearch}
          prospects={prospects}
          userRole={userRole}
        />
      ) : null}
      <div className="row">
        <div className="col-12 d-center">
          <SaveBtn
            text="Rechercher"
            isSaving={isLoading}
            save={searchMissions}
          />
        </div>
      </div>

      <div className="tab-content mt-4" id="myTabContent">
        {isLoading ? null : (
          <div className="list-group">
            {missions.map((m, k) => (
              <>
                <div
                  key={"mission" + k}
                  className="list-group-item flex-column align-items-start mb-3 animated fadeIn faster   shadow-sm"
                  style={{
                    animationDelay: 100 * k + "ms",
                    background: "#f7f7f7",
                  }}
                >
                  <div className="d-flex w-100 justify-content-between">
                    <div className="col-4">
                      <h5 className="mb-1">{m.name}</h5>

                      <div className="badge badge-primary mr-1">
                        {
                          prospects.find((p) => p.id == m.userProspect)
                            .companyName
                        }
                      </div>

                      <div className="badge badge-info mr-1">
                        {m.isSurvey ? "sondage" : "constat"}
                      </div>
                      {m.isDeleted ? (
                        <div className="badge badge-danger mr-1">Annulé</div>
                      ) : null}
                      <div>
                        <small>{m.address}</small>
                      </div>
                    </div>
                    <div
                      className="col-4 flex-column"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div>
                        Du {DateTime.fromISO(m.beginAt).toFormat("dd/MM/yyyy")}{" "}
                        au{" "}
                        {DateTime.fromISO(m.endAt, { zone: "Indian/Reunion" })
                          .toUTC()
                          .toFormat("dd/MM/yyyy")}
                      </div>
                      <div>
                        <div>
                          Créé le{" "}
                          {DateTime.fromISO(m.createdAt).toFormat(
                            "dd/MM/yyyy à HH'h'mm"
                          )}
                        </div>
                        <div>
                          Modifié le{" "}
                          {DateTime.fromISO(m.updatedAt).toFormat(
                            "dd/MM/yyyy à HH'h'mm"
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-4"
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div>
                        {m.participations &&
                          `${m.participations.WAITING} en cours | ${m.participations.FINISHED} terminés | ${m.nbReport} attendus`}
                        {m.participations &&
                          m.participations.FINISHED == m.nbReport && (
                            <i className="fa ml-2 fa-check-double text-success" />
                          )}
                      </div>
                      <div className=" pt-2">
                        <div className="dropdown show">
                          <a
                            className="btn btn-default btn-sm mb-1 pr-2"
                            href="#"
                            role="button"
                            id="dropdownMenuLink"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Accès rapides
                            <i className="fa fa-ellipsis-v ml-2" />
                          </a>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuLink"
                          >
                            <a
                              className="dropdown-item cursor-pointer"
                              onClick={() => goToMissionTab(m.id, "parameters")}
                            >
                              Paramètres
                            </a>
                            <a
                              className="dropdown-item cursor-pointer"
                              onClick={() => goToMissionTab(m.id, "questions")}
                            >
                              Questions
                            </a>
                            {!m.isInternal ? (
                              <a
                                className="dropdown-item cursor-pointer"
                                onClick={() =>
                                  goToMissionTab(m.id, "postulations")
                                }
                              >
                                Postulations
                              </a>
                            ) : (
                              <a
                                className="dropdown-item cursor-pointer"
                                onClick={() => goToMissionTab(m.id, "rogue")}
                              >
                                Répondre
                              </a>
                            )}
                          </div>
                        </div>
                        <a
                          href={"/constats/" + m.id}
                          className="btn btn-default mb-1 btn-sm"
                        >
                          <i className="fa fa-cog mr-2"></i>
                          Modifier
                        </a>
                        <a
                          href={"/constats/" + m.id + "?tab=report"}
                          className="btn btn-primary mb-1 btn-sm"
                        >
                          <i className="fa fa-flag mr-2"></i>
                          Rapport
                        </a>

                        <button
                          className="btn btn-danger mb-1 btn-sm w-100"
                          onClick={() => removeMission(m.id, false)}
                        >
                          <i className="fa fa-times mr-2" />
                          Supprimer
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        )}
      </div>
      <Pagination
        nbPages={nbPages}
        page={search.page}
        changePage={changePage}
      />
    </Page>
  );
};

export default MissionList;
