import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import Axios from "axios";
import { API_URL, image_url } from "../../../../../config";
import { toast } from "react-toastify";
import Loader from "../../../../common/Loader";

const ReportUploader = ({ missionId, getReport }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isRemoveLoading, setIsRemoveLoading] = useState(false);
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    console.log(acceptedFiles);
    const formData = new FormData();

    formData.append("file", acceptedFiles[0]);

    uploadFile(formData);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  function uploadFile(formData) {
    setIsLoading(true);
    Axios({
      method: "POST",
      url: API_URL + "missions/" + missionId + "/reportfile",
      data: formData,
      headers: {
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    })
      .then((res) => {
        console.log(res.data);
        getReport();
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  return (
    <div className="p-3 mb-3">
      <div
        {...getRootProps()}
        className="d-flex align-items-center justify-content-center p-3 text-center flex-column bx-inset cursor-pointer"
        style={{
          border: "1px dashed #d0d0d0",
          borderRadius: 20,
          height: 200,
        }}
      >
        <input {...getInputProps()} />
        {isLoading ? (
          <Loader />
        ) : isDragActive ? (
          <p>Déposez le fichier PDF ici ...</p>
        ) : (
          <>
            <p>Glissez-déposez un fichier PDF</p>
            <p>ou</p>
            <p>cliquez pour parcourir vos fichiers</p>
          </>
        )}
      </div>
    </div>
  );
};

export default ReportUploader;
