import React, { useState } from "react";

const Collapse = ({ title, type, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <button
        className={`btn btn-${type ? type : "primary"} w-100`}
        onClick={() => setIsOpen(!isOpen)}
      >
        {title} <i className={`fa fa-caret-${isOpen ? "up" : "down"} ml-2`} />
      </button>
      {isOpen && props.children}
    </>
  );
};

export default Collapse;
