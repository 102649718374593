import React, {useState, useEffect} from "react";
import Page from "../../common/layout/Page";
import Table from "../../common/Table/Table";
import Control from "../../common/Controls/Control";
import Loader from "../../common/Loader";
import dataService from "../../../helpers/dataService";
import Axios from 'axios';
import {Link} from "react-router-dom";
import {API_URL} from "../../../config";
import Pagination from "../../common/Pagination";

let strTimeout = null

const ProductList = () => {
    const [isLoading, setIsLoading] = useState({
        product: false, productCategory: false, productSubCategory: false
    });
    const [total, setTotal] = useState({
        product: 0, productCategory: 0, productSubCategory: 0
    });
    const [errors, setErrors] = useState({
        product: "", productCategory: "", productSubCategory: ""
    });
    const [nbPages, setNbPages] = useState({
        product: 1, productCategory: 1, productSubCategory: 1
    })
    const [products, setProducts] = useState([]);
    const [productCategories, setProductCategories] = useState([])
    const [productSubCategories, setProductSubCategories] = useState([])
    const [productSearch, setProductSearch] = useState({
        name: {searchString: ""}, page: 1
    })
    const [productCategorySearch, setProductCategorySearch] = useState({
        name: {searchString: ""}, page: 1
    })
    const [productSubCategorySearch, setProductSubCategorySearch] = useState({
        name: {searchString: ""}, page: 1
    })

    useEffect(() => {
            console.log(total)
        },
        [total])

    useEffect(() => {
            searchProducts();
            searchProductSubCategories()
            searchProductCategories()
        },
        []);

    useEffect(() => {
            setIsLoading({...isLoading, product: true})
            clearTimeout(strTimeout)
            strTimeout = setTimeout(() => {
                    searchProducts()
                },
                1500)
        },
        [productSearch])

    useEffect(() => {
            setIsLoading({...isLoading, productCategory: true})
            clearTimeout(strTimeout)
            strTimeout = setTimeout(() => {
                    searchProductCategories()
                },
                1500)
        },
        [productCategorySearch])

    useEffect(() => {
            setIsLoading({...isLoading, productSubCategory: true})
            clearTimeout(strTimeout)
            strTimeout = setTimeout(() => {
                    searchProductSubCategories()
                },
                1500)
        },
        [productSubCategorySearch])

    const changePage = (c, i) => {
        switch (c) {
            case 'product':
                setProductSearch({...productSearch, page: i})
                return
            case 'productCategory':
                setProductCategorySearch({...productCategorySearch, page: i})
                return
            case 'productSubCategory':
                setProductSubCategorySearch({...productSubCategorySearch, page: i})
                return
            default:
                break
        }
    }

    const searchProducts = () => {
        Axios.post(`${API_URL}products/search`,
            productSearch)
            .then((res) => {
                setProducts(res.data.products);
                setTotal({...total, product: res.data.count});
                setNbPages({...nbPages, product: Math.ceil(parseInt(res.data.count) / 25)})
                setIsLoading({...isLoading, product: false})
            })
            .catch((err) => {
                setIsLoading({...isLoading, product: false})
                setErrors({...errors, product: err.data})
                setProducts([])
                console.log(err.data)
            })
    };

    const searchProductCategories = () => {
        Axios.post(`${API_URL}product-categories/search`,
            productCategorySearch)
            .then((res) => {
                setProductCategories(res.data.productCategories);
                setTotal({...total, productCategory: res.data.count})
                setNbPages({...nbPages, productCategory: Math.ceil(parseInt(res.data.count) / 25)})
                setIsLoading({...isLoading, productCategory: false})
            })
            .catch((err) => {
                setIsLoading({...isLoading, productCategory: false})
                setErrors({...errors, productCategory: err.data})
                setProductCategories([])
                console.log(err.data)
            })
    }

    const searchProductSubCategories = () => {
        Axios.post(`${API_URL}product-subcategories/search`,
            productSubCategorySearch)
            .then((res) => {
                setProductSubCategories(res.data.productSubCategories)
                setTotal({...total, productSubCategory: res.data.count})
                setNbPages({...nbPages, productSubCategory: Math.ceil(parseInt(res.data.count) / 25)})
                setIsLoading({...isLoading, productSubCategory: false})
            })
            .catch((err) => {
                setIsLoading({...isLoading, productSubCategory: false})
                setErrors({...errors, productSubCategory: err.data})
                setProductSubCategories([])
                console.log(err.data)
            })
    }

    const updateProductSearch = (e) => {
        let value = e.target.value
        setProductSearch({...productSearch, name: {searchString: value}})
    }

    const updateProductCategorySearch = (e) => {
        let value = e.target.value
        setProductCategorySearch({...productCategorySearch, name: {searchString: value}})
    }

    const updateProductSubCategorySearch = (e) => {
        let value = e.target.value
        setProductSubCategorySearch({...productSubCategorySearch, name: {searchString: value}})
    }

    const deleteData = (c, id) => {
        if (!window.confirm("Êtes-vous sûr de vouloir supprimer cette entrée ?")) return false;

        const onRemoveSuccess = (c) => {
            let dt = JSON.parse(JSON.stringify(c));
            dt = dt.filter((d) => d.id !== id);

            switch (c) {
                case products:
                    setProducts(dt);
                    return
                case productCategories:
                    setProductCategories(dt)
                    return
                case productSubCategories:
                    setProductSubCategories(dt)
                    return
                default:
                    break
            }
        };

        const onRemoveFinish = (c) => {
            setIsLoading({...isLoading, [c]: false});
        };

        switch (c) {
            case 'product':
                setIsLoading({...isLoading, product: true});
                dataService.remove(`products/${id}`,
                    {},
                    onRemoveSuccess(products),
                    setErrors,
                    onRemoveFinish(products))
                return
            case 'productSubCategory':
                setIsLoading({...isLoading, productSubCategory: true})
                dataService.remove(`product-subcategories/${id}`,
                    {},
                    onRemoveSuccess(productSubCategories),
                    setErrors,
                    onRemoveFinish(productSubCategories))
                return
            case 'productCategory':
                setIsLoading({...isLoading, productCategory: true})
                dataService.remove(`product-categories/${id}`,
                    {},
                    onRemoveSuccess(productCategories),
                    setErrors,
                    onRemoveFinish(productCategories))
                return
            default:
                break
        }
    };

    return (<>
        <Page
            container="container"
            action={{to: "/products/create", text: "Créer un produit"}}
            title={"Liste des produits"}
        >
            <div className="row mb-4">
                <div className="col">
                    <Control
                        type="text"
                        label="Recherche"
                        name="searchString"
                        suffix={<i className="fa fa-search ml-2" style={{lineHeight: "40px"}}/>}
                        value={productSearch.name.searchString}
                        change={updateProductSearch}
                    />
                </div>
                <div className="col align-items-center justify-content-end d-flex">
                    {total.product} produits trouvés
                </div>
            </div>
            <div className="row">
                <div className="col-12 p-4">
                    {errors && errors.other ? (
                        <div className="text-danger m-auto text-center">{errors.product}</div>) : isLoading.product ? (
                        <Loader/>) : (<Table
                        fields={[{
                            name: "Produit", path: "name",
                        }, {
                            name: "Sous-famille", path: "subCategory", render: (el) => el?.name,
                        }, {
                            name: "Famille", path: "category", render: (el) => el?.name,
                        }, {
                            name: "Actions", path: "id", render: (el) => {
                                return (<>
                                    <Link
                                        className="btn btn-default d-center d-inline-flex"
                                        to={`/products/${el}`}
                                    >
                                        <i className="fa fa-edit"/>
                                    </Link>
                                    <Link
                                        className="btn btn-danger d-inline-flex"
                                        onClick={() => deleteData('product',
                                            el)}
                                    >
                                        <i className="fa fa-times"/>
                                    </Link>
                                </>);
                            },
                        },]}
                        datas={products}
                    />)}
                    <Pagination
                        nbPages={nbPages.product}
                        page={productSearch.page}
                        changePage={() => changePage('product')}
                    />
                </div>
            </div>
        </Page>
        <Page
            container={"container"}
            action={{to: "/product-subcategories/create", text: "Créer une sous-famille"}}
            title={"Liste des sous-famille"}
        >
            <div className="row mb-4">
                <div className="col">
                    <Control
                        type="text"
                        label="Recherche"
                        name="searchString"
                        suffix={<i className="fa fa-search ml-2" style={{lineHeight: "40px"}}/>}
                        value={productSubCategorySearch.name.searchString}
                        change={updateProductSubCategorySearch}
                    />
                </div>
                <div className="col align-items-center justify-content-end d-flex">
                    {total.productSubCategory} sous-familles trouvées
                </div>
            </div>
            <div className="row">
                <div className="col-12 p-4">
                    {errors && errors.other ? (<div
                        className="text-danger m-auto text-center">{errors.productSubCategory}</div>) : isLoading.productSubCategory ? (
                        <Loader/>) : (<Table
                        fields={[{
                            name: "Sous-famille", path: "name",
                        }, {
                            name: "Famille", path: "category", render: (el) => el?.name
                        }, {
                            name: "Actions", path: "id", render: (el) => {
                                return (<>
                                    <Link
                                        className="btn btn-default d-center d-inline-flex"
                                        to={`/product-subcategories/${el}`}
                                    >
                                        <i className="fa fa-edit"/>
                                    </Link>
                                    <Link
                                        className="btn btn-danger d-inline-flex"
                                        onClick={() => deleteData('productSubCategory',
                                            el)}
                                    >
                                        <i className="fa fa-times"/>
                                    </Link>
                                </>);
                            },
                        },]}
                        datas={productSubCategories}
                    />)}
                    <Pagination
                        nbPages={nbPages.productSubCategory}
                        page={productSubCategorySearch.page}
                        changePage={() => changePage('productSubCategory')}
                    />
                </div>
            </div>
        </Page>
        <Page
            container={"container"}
            action={{to: "/product-categories/create", text: "Créer une famille"}}
            title={"Liste des famille"}
        >
            <div className="row mb-4">
                <div className="col">
                    <Control
                        type="text"
                        label="Recherche"
                        name="searchString"
                        suffix={<i className="fa fa-search ml-2" style={{lineHeight: "40px"}}/>}
                        value={productCategorySearch.name.searchString}
                        change={updateProductCategorySearch}
                    />
                </div>
                <div className="col align-items-center justify-content-end d-flex">
                    {total.productCategory} familles trouvées
                </div>
            </div>
            <div className="row">
                <div className="col-12 p-4">
                    {errors && errors.other ? (<div
                        className="text-danger m-auto text-center">{errors.productCategory}</div>) : isLoading.productCategory ? (
                        <Loader/>) : (<Table
                        fields={[{
                            name: "Famille", path: "name",
                        }, {
                            name: "Actions", path: "id", render: (el) => {
                                return (<>
                                    <Link
                                        className="btn btn-default d-center d-inline-flex"
                                        to={`/product-categories/${el}`}
                                    >
                                        <i className="fa fa-edit"/>
                                    </Link>
                                    <Link
                                        className="btn btn-danger d-inline-flex"
                                        onClick={() => deleteData('productCategory',
                                            el)}
                                    >
                                        <i className="fa fa-times"/>
                                    </Link>
                                </>);
                            },
                        },]}
                        datas={productCategories}
                    />)}
                    <Pagination
                        nbPages={nbPages.productCategory}
                        page={productCategorySearch.page}
                        changePage={() => changePage('productCategory')}
                    />
                </div>
            </div>
        </Page>
    </>);
};

export default ProductList;
