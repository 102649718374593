import React, { Fragment, useEffect } from "react";
import tools from "../../../../../helpers/tools";
import { image_url as IMAGE_URL } from "../../../../../config";
import Control from "../../../../common/Controls/Control";

const QuestionGenerator = ({
  question,
  step,
  answers,
  setAnswers,
  formErrors,
  uploadedBase64,
  setUploadedBase64,
}) => {
  const change = (e, i) => {
    const { type, name, value, checked } = e.target;
    console.log(type, name, value, checked);
    var val = [];
    switch (type) {
      case "text":
        val = [value];
        break;
      case "longText":
        val = [value];
        break;
      case "checkbox":
        var ans = JSON.parse(JSON.stringify(answers));

        val = ans[name];
        if (checked) {
          val.push(value);
        } else {
          val = answers[name].filter((a) => a != value);
        }
        break;
      case "select-one":
        val = [value];
        break;
      case "radio":
        val = [value];
        break;
      case "range":
        val = [value];
        break;
      case "file":
        if (e.target.files && e.target.files[0]) {
          readUrl(e.target, i);
          val = answers[name];
          val[i] = e.target.files[0];
        }
        break;
      case "float":
        val = [value];
        break;
    }
    setAnswers({ ...answers, [name]: val });
  };

  const readUrl = (el, i) => {
    var reader = new FileReader();
    reader.onload = function (e) {
      var bs = JSON.parse(JSON.stringify(uploadedBase64));
      bs[question._id][i] = e.target.result;
      setUploadedBase64(bs);
      //document.querySelector("#image" + question.id).src = e.target.result;
    };

    reader.readAsDataURL(el.files[0]); // convert to base64 strin
  };

  const renderQuestion = () => {
    var image = question.image ? (
      <div>
        <img
          className="form-image mx-auto"
          src={IMAGE_URL + question.image.fileName}
        />
      </div>
    ) : null;

    switch (question.type) {
      case 1:
        return (
          <div className="mt-0" noHairlines={true}>
            <Control
              type="text"
              label="Écrivez votre réponse ci-dessous"
              change={change}
              name={question._id}
              value={answers[question._id][0]}
              change={(e) =>
                change({
                  target: {
                    type: "text",
                    name: question._id,
                    value: e.target.value,
                  },
                })
              }
            />
            {image}
          </div>
        );
      case 2:
        return (
          <div className="mt-0 " noHairlines={true}>
            {question.answers.map((ans, ansk) => {
              let isActive = answers[question._id].includes(ans.value);
              return (
                <button
                  // onChange={change}
                  className={`btn font-weight-bold animated fadeInRight faster ${
                    isActive ? "btn-primary" : "btn-default"
                  }`}
                  onClick={(e) =>
                    change({
                      target: {
                        type: "checkbox",
                        name: question._id,
                        checked: !isActive,
                        value: ans.value,
                      },
                    })
                  }
                  key={"ans" + step + "" + ansk}
                  name={question._id}
                  value={ans.value}
                  style={{
                    animationDelay: parseInt(ansk) * 50 + "ms",
                    transition: "200ms",
                  }}
                >
                  <div className=" d-flex align-items-center justify-content-between">
                    {ans.label}{" "}
                  </div>
                </button>
              );
            })}
            {image}
          </div>
        );
      case 3:
        return (
          <div className="mt-0 " noHairlines={true}>
            <Control
              label="Choisissez"
              type="select"
              change={change}
              name={question._id}
              value={answers[question._id][0]}
              datas={question.answers}
              dataIndex={"value"}
              dataLabel="label"
            />
            {image}
          </div>
        );
      case 4:
        return (
          <div className="mt-0 " noHairlines={true}>
            {question.answers.map((ans, ansk) => {
              let isActive = answers[question._id].includes(ans.value);
              return (
                <button
                  className={`btn  border rounded font-weight-bold animated fadeInRight faster ${
                    isActive ? "btn-primary" : "btn-default"
                  }`}
                  onClick={(e) =>
                    change({
                      target: {
                        type: "select-one",
                        name: question._id,

                        value: ans.value,
                      },
                    })
                  }
                  key={"ans" + step + "" + ansk}
                  value={ans.value}
                  title={ans.label}
                  name={question._id}
                  style={{
                    animationDelay: parseInt(ansk) * 50 + "ms",
                    transition: "200ms",
                  }}
                >
                  <div className=" d-flex align-items-center justify-content-between">
                    {ans.label}{" "}
                  </div>
                </button>
              );
            })}
            {image}
          </div>
        );
      case 5:
        return (
          <div className="mt-0" noHairlines={true}>
            <Control
              type="longText"
              label="Écrivez votre réponse ci-dessous"
              change={change}
              name={question._id}
              value={answers[question._id][0]}
              change={(e) =>
                change({
                  target: {
                    type: "longText",
                    name: question._id,
                    value: e.target.value,
                  },
                })
              }
            /> 
            {image}
          </div>
        );
      case 6:
        return (
          <div className="mt-0  p-3" noHairlines={true}>
            <Control
              label=""
              type="range"
              inputParams={{
                min: question.inputParams.min,
                max: question.inputParams.max,
                step: question.inputParams.step,
              }}
              value={answers[question._id][0]}
              change={(e) =>
                change({
                  target: {
                    type: "range",
                    name: question._id,
                    value: e.target.value,
                  },
                })
              }
            />
            {/* <Range
              min={question.inputParams.min}
              max={question.inputParams.max}
              label={true}
              step={question.inputParams.step}
              value={answers[question._id][0]}
              onRangeChange={(val) =>
                change({
                  target: { type: "range", name: question._id, value: val },
                })
              }
            /> */}

            {/* <ListInput
              className="mt-2"
              onChange={change}
              type="text"
              name={question._id}
              value={answers[question._id]}
            /> */}
            {image}
          </div>
        );
      case 7:
        var inputsFile = [];
        var entries = [];
        for (let i = 0; i < question.inputParams.count; i++) {
          entries.push(
            <div key={"inpfilenum" + i}>
              <div>
                <img
                  className="form-image mx-auto"
                  id={"image" + question._id + i}
                  src={uploadedBase64[question._id][i] || "#"}
                />
              </div>
              <div className="item-content item-input">
                <div className="item-inner">
                  <div className="item-title item-label">Prendre une photo</div>
                  <div className="item-input-wrap">
                    <input
                      name="6013d3f5cacb805e989a37bf"
                      type="file"
                      accept="image/*"
                      className=""
                      style={{ paddingLeft: 0 }}
                      name={question._id}
                      onChange={(e) => change(e, i)}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        }
        inputsFile.push(
          <div className="mt-0 " noHairlines={true}>
            {entries}
            {image}
          </div>
        );
        return inputsFile;
      case 8:
        return (
          <div className="mt-0" nohairlines="true">
            <Control
              label=""
              type="signature"
              name={question._id}
              uploadedBase64={uploadedBase64}
              setUploadedBase64={setUploadedBase64}
              answers={answers}
              setAnswers={setAnswers}
            />
          </div>
        )
      case 9:
        return (
            <div className="mt-0" noHairlines={true}>
              <Control
                  type="float"
                  label=""
                  name={question._id}
                  value={answers[question._id][0]}
                  change={(e) =>
                      change({
                        target: {
                          type: "float",
                          name: question._id,
                          value: e.target.value,
                        },
                      })
                  }
              />
              {image}
            </div>
        )
      default:
        return "";
    }
  };

  return (
    <div className="animated fadeIn faster quest-gen">
      <h4 className="mb-0">{question.question}</h4>
      <p className="mt-0 text-secondary">{question.description}</p>
      {renderQuestion()}
      <p className="text-danger">{formErrors[question._id]}</p>
    </div>
  );
};

export default QuestionGenerator;
