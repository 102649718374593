import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import Control from "../../common/Controls/Control";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import { DateTime } from "luxon";
import Axios from "axios";
import { API_URL } from "../../../config";
import PostalCode from "../../common/PostalCode";
import DynamicList from "../../common/DynamicList";
import { toast } from "react-toastify";
import SaveBtn from "../../common/SaveBtn";
import dateService from "../../../helpers/dataService";
import dataService from "../../../helpers/dataService";
import MissionList from "../MissionList/MissionList";

const UserDetail = (props) => {
  const [state, dispatch] = useStore();

  const [user, setUser] = useState({
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    password: "",
    companyName: "",
    siret: "",
    legalForm: "",
    contacts: [],
    address: "",
    additionalAddress: "",
    cityId: "",
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPwd, setIsLoadingPwd] = useState(false);
  const [modifyPassword, setModifyPassword] = useState(false);

  useEffect(() => {
    if (props.match.params.id != "create") {
      console.log("update...");
      Axios.get(API_URL + "prospects/" + props.match.params.id)
        .then((res) => {
          setUser(res.data);
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.data) {
            setErrors(err.response.data);
          } else {
            toast.error("Une erreur est survenue");
          }
        });
    }
  }, []);

  useEffect(() => {
    console.log("USER", user);
  }, [user]);
  function updateData(e) {
    console.log(e.target.name, e.target.value);
    var value = e.target.value;
    if (e.target.type == "checkbox") {
      if (e.target.checked) {
        value = true;
      } else {
        value = false;
      }
    }

    setUser({ ...user, [e.target.name]: value });
  }

  function save() {
    setIsLoading(true);

    if (props.match.params.id == "create") {
      //create
      dataService.post(
        `prospects`,
        user,
        (datas) => {
          setUser(datas);
          toast.success("Le prospect a bien été créé");
          setErrors({});
          window.location.href = "/prospects/" + datas.id;
        },
        setErrors,
        () => setIsLoading(false)
      );
    } else {
      //update
      dataService.patch(
        `prospects/${props.match.params.id}`,
        user,
        (datas) => {
          setUser(datas);
          setErrors({});
        },
        setErrors,
        () => setIsLoading(false)
      );
    }
  }

  function savePassword() {
    setIsLoadingPwd(true);
    dataService.patch(
      `prospects/${props.match.params.id}/password`,
      { password: user.password },
      (datas) => toast.success("Le mot de passe a bien été mis à jour"),
      setErrors,
      () => setIsLoadingPwd(false)
    );
  }

  return (
    <Page
      container="container"
      title="Détail prospect"
      errors={errors}
      back={"/prospects"}
    >
      <div className="row">
        <div className="col-12 col-md-6 p-0">
          <Control
            label="Nom"
            name="lastname"
            type="text"
            value={user.lastname}
            change={updateData}
            error={errors}
          />
        </div>
        <div className="col-12 col-md-6 p-0">
          <Control
            label="Prénom"
            name="firstname"
            type="text"
            value={user.firstname}
            change={updateData}
            error={errors}
          />
        </div>
        <div
          className={`col-${
            props.match.params.id == "create" ? "4" : "6"
          }  p-0`}
        >
          <Control
            label="Email"
            name="email"
            type="email"
            value={user.email}
            change={updateData}
            error={errors}
            setErrors={setErrors}
          />
        </div>
        <div className="col-12 col-md-4 p-0">
          <Control
            label="Téléphone"
            name="phone"
            type="tel"
            value={user.phone}
            change={updateData}
            error={errors}
            setErrors={setErrors}
          />
        </div>
        {props.match.params.id == "create" && (
          <div className="col-4 p-0">
            <Control
              label="Mot de passe"
              name="password"
              type="password"
              value={user.password}
              change={updateData}
            />
          </div>
        )}
        <div className="col-4 p-0">
          <Control
            label="Adresse"
            name="address"
            type="text"
            value={user.address}
            change={updateData}
            error={errors}
            setErrors={setErrors}
          />
        </div>
        <div className="col-4 p-0">
          <Control
            label="Adresse complément"
            name="additionalAddress"
            type="text"
            value={user.additionalAddress}
            change={updateData}
            error={errors}
            setErrors={setErrors}
          />
        </div>
        <div className="col-4 p-0">
          <PostalCode
            value={user.postalCode}
            updateData={updateData}
            location={user.location}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-4 p-0">
          <Control
            label="Société"
            type="text"
            value={user.companyName}
            name="companyName"
            change={updateData}
            error={errors}
            setErrors={setErrors}
          />
        </div>
        <div className="col-4 p-0">
          <Control
            label="SIRET"
            type="text"
            name="siret"
            value={user.siret}
            change={updateData}
            error={errors}
            setErrors={setErrors}
          />
        </div>
        <div className="col-4 p-0">
          <Control
            label="Forme juridique"
            name="legalForm"
            type="select"
            value={user.legalForm}
            datas={state.constants.items.LEGAL_FORMS}
            change={updateData}
            error={errors}
            setErrors={setErrors}
          />
        </div>
        <div className="col-12 p-0">
          <DynamicList
            label="Contacts"
            name="contacts"
            change={(e) =>
              setUser({
                ...user,
                contacts: e.target.value,
              })
            }
            fields={[
              {
                label: "Civilité",
                type: "select",
                datas: state.constants.items.GENDERS,
                name: "gender",
              },
              {
                label: "Prénom",
                type: "text",
                name: "firstname",
              },
              {
                label: "Nom",
                type: "text",
                name: "lastname",
              },
              {
                label: "Email",
                type: "email",
                name: "email",
              },
              {
                label: "Téléphone",
                type: "tel",
                name: "phone",
              },
              {
                label: "Commentaire",
                type: "text",
                name: "comment",
              },
            ]}
            value={user.contacts}
            btnLabel="Ajouter"
            error={errors}
          />
        </div>
      </div>
      <div className="row">
        <div className="d-flex justify-content-end w-100">
          <SaveBtn
            save={save}
            isSaving={isLoading}
            text="Enregistrer"
            className="btn btn-success btn-save"
          />
        </div>
      </div>
      {props.match.params.id !== "create" && (
        <div className="row">
          <div className="col-12 p-0">
            <button
              className="btn btn-primary"
              onClick={() => setModifyPassword((p) => !p)}
            >
              Modifier le mot de passe
            </button>
            {modifyPassword && (
              <>
                <Control
                  label="Mot de passe"
                  name="password"
                  type="password"
                  value={user.password}
                  change={updateData}
                  showValidation={true}
                />

                <SaveBtn
                  save={savePassword}
                  isSaving={isLoadingPwd}
                  text="Enregistrer le mot de passe"
                />
              </>
            )}
          </div>
          <div className="col-12 p-0 bg-white shadow-sm mt-4 py-3">
            <MissionList prospectId={props.match.params.id} />
          </div>
        </div>
      )}
    </Page>
  );
};

export default UserDetail;
