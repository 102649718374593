import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import useApiDatas from "../../common/Hooks/useApiDatas";
import Table from "../../common/Table/Table";
import { modalActions } from "../../../context/actions/modalActions";
import ProspectorDetail from "../ProspectorDetail/ProspectorDetail";
import dataService from "../../../helpers/dataService";
import Loader from "../../common/Loader";
import AnswersViewer from "../AnswersViewer/AnswersViewer";
import FormRejectModal from "./FormRejectModal";

const Postulations = ({ mission, tab }) => {
  const {
    state,
    dispatch,
    constants,
    errors,
    setErrors,
    isLoading,
    setIsLoading,
    searchDatas,
    setSearchDatas,
    nbPages,
    setNbPages,
    getSearchDatas,
    deleteData,
    isInit,
    search,
    setSearch,
    otherDatas,
    setOtherDatas,
    getAllDependencies,
  } = useApiDatas({
    dependenciesPath: [`missions/${mission.id}/participations/all`],
  });
  const statuses = constants.PARTICIPATION_STATUS;
  const participations =
    otherDatas[`missions/${mission.id}/participations/all`];
  const modal_actions = modalActions(state, dispatch);
  const [rejectLoading, setRejectLoading] = useState(false);

  useEffect(() => {}, [participations]);

  useEffect(() => {
    if (tab == "postulations" && isInit) {
      getAllDependencies();
    }
  }, [tab]);

  const validParticipation = (id) => {
    if (!window.confirm("Confirmez la validation svp")) return false;
    setIsLoading(id);

    dataService.patch(
      "participations/" + id + "/validate",
      {},
      (datas) => getAllDependencies(),
      setErrors,
      () => setIsLoading(false)
    );
  };
  const deleteParticipation = (id) => {
    if (!window.confirm("Confirmez la suppression svp")) return false;
    setIsLoading(id);

    dataService.remove(
      "participations/" + id,
      {},
      (datas) => getAllDependencies(),
      setErrors,
      () => setIsLoading(false)
    );
  };
  const rejectParticipation = (id, comment) => {
    dataService.patch(
      "participations/" + id + "/reject",
      {
        userManagerComment: comment,
      },
      (datas) => getAllDependencies(),
      () => {},
      () => {
        modal_actions.updateModal({ isOpen: false, content: null });
      }
    );
  };
  const openRejectionModal = (participation) => {
    modal_actions.updateModal({
      isOpen: true,
      content: (
        <FormRejectModal
          rejectParticipation={rejectParticipation}
          participation={participation}
        />
      ),
    });
  };

  const viewProspector = (id) => {
    modal_actions.updateModal({
      isOpen: true,
      content: <ProspectorDetail prospectorId={id} />,
    });
  };
  const viewAnswers = (ans) => {
    modal_actions.updateModal({
      isOpen: true,
      content: <AnswersViewer mission={mission} answers={ans} />,
    });
  };
  const editComment = (el) => {
    modal_actions.updateModal({
      isOpen: true,
      content: (
        <FormRejectModal
          rejectParticipation={rejectParticipation}
          participation={el}
          rejectLoading={rejectLoading}
        />
      ),
    });
  };

  return (
    isInit && (
      <div className="row my-4 position-relative">
        <div className="col-12">
          <h4>Postulations</h4>
        </div>
        {statuses.map((st, stk) => {
          return participations.filter((s) => s.status == st.id).length ? (
            <div className="col-12 mb-4" key={"stat" + stk}>
              <h5
                className={
                  "border-bottom d-flex p-2 justify-content-between align-items-center text-" +
                  st.color
                }
              >
                <div>
                  <i className={"fa fa-" + st.icon + " mr-2"} />
                  {st.name}
                </div>
                <div
                  className={"bg-" + st.color + " text-white d-center"}
                  style={{
                    borderRadius: "50%",
                    height: 40,
                    width: 40,
                    color: "black",
                  }}
                >
                  {participations.filter((s) => s.status == st.id).length}
                </div>
              </h5>

              <Table
                noExport={true}
                noMargin={true}
                fields={[
                  {
                    name: "Prospecteur",
                    path: "userProspector",
                    render: (el) => (
                      <button
                        className="btn btn-link"
                        onClick={() => viewProspector(el.id)}
                      >
                        {(el.gender == 1 ? "Mr" : "Mme") +
                          " " +
                          el.firstname +
                          " " +
                          el.lastname}
                      </button>
                    ),
                  },
                  {
                    name: "Début",
                    path: "beginAt",
                    render: (el) =>
                      el
                        ? DateTime.fromISO(el).toFormat("dd/MM/yyyy HH:mm")
                        : null,
                  },
                  {
                    name: "Fin",
                    path: "endAt",
                    render: (el) =>
                      el
                        ? DateTime.fromISO(el).toFormat("dd/MM/yyyy HH:mm")
                        : null,
                  },
                  {
                    name: "Actions",
                    path: "*",
                    render: (el) => {
                      return isLoading == el.id ? (
                        <Loader />
                      ) : (
                        <div className="d-center">
                          {el.status == 1 && (
                            <>
                              <button
                                className="btn btn-success mx-1"
                                onClick={() => validParticipation(el.id)}
                              >
                                <i className="fa fa-check"></i>
                              </button>
                              <button
                                className="btn btn-warning mx-1"
                                onClick={() => openRejectionModal(el)}
                              >
                                <i className="fa fa-ban"></i>
                              </button>
                            </>
                          )}
                          {el.status == 3 && (
                            <>
                              <button
                                className="btn btn-secondary mx-1"
                                onClick={() => viewAnswers(el.answers)}
                              >
                                Réponses
                              </button>
                              <button
                                title="Rejeter"
                                className="btn btn-warning mx-1"
                                onClick={() => openRejectionModal(el)}
                              >
                                <i className="fa fa-ban"></i>
                              </button>
                            </>
                          )}
                          {el.status == 6 && (
                            <button
                              className="btn btn-success mx-1"
                              onClick={() => editComment(el)}
                            >
                              <i className="fa fa-comment"></i>
                            </button>
                          )}
                          <button
                            className="btn btn-danger mx-1"
                            onClick={() => deleteParticipation(el.id)}
                          >
                            <i className="fa fa-times"></i>
                          </button>
                        </div>
                      );
                    },
                  },
                ]}
                datas={participations.filter((s) => s.status == st.id)}
              />
            </div>
          ) : null;
        })}
        <div className="col-12"></div>
      </div>
    )
  );
};

export default Postulations;
