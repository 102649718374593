import React from "react";
import Table from "../../../common/Table/Table";
import { DateTime } from "luxon";

const ProspectorMissions = ({ missions, constants }) => {
  return (
    <>
      {missions.length > 0 ? (
        <Table
          datas={missions}
          fields={[
            {
              name: "Nom",
              path: "mission",
              render: (el) => <a href={`/constats/${el.id}`}>{el.name}</a>,
            },
            {
              name: "Statut",
              path: "status",
              render: (el) =>
                constants.PARTICIPATION_STATUS.map(
                  (status) =>
                    status.id === el && (
                      <>
                        <i
                          className={`fas fa-${status.icon} text-${status.color} mr-2`}
                        ></i>
                        <span className={`text-${status.color}`}>
                          {status.name}
                        </span>
                      </>
                    )
                ),
            },
            {
              name: "Début",
              path: "beginAt",
              render: (el) =>
                el ? (
                  DateTime.fromISO(el).toFormat("dd/MM/yyyy")
                ) : (
                  <span className="d-block text-center">-</span>
                ),
            },
            {
              name: "Fin",
              path: "endAt",
              render: (el) =>
                el ? (
                  <span className="d-block text-left">
                    {DateTime.fromISO(el).toFormat("dd/MM/yyyy")}
                  </span>
                ) : (
                  <span className="d-block text-center">-</span>
                ),
            },
          ]}
          exportOptions={{
            formatDatasFn: (datas) =>
              datas.map((d) => ({
                ...d,
                mission: d.mission.name,
                status: constants.PARTICIPATION_STATUS.find(
                  (status) => status.id === d.status
                ).name,
                beginAt:
                  d.beginAt &&
                  DateTime.fromISO(d.beginAt).toFormat("dd/MM/yyyy"),
                endAt:
                  d.endAt && DateTime.fromISO(d.endAt).toFormat("dd/MM/yyyy"),
              })),
            excludedFields: [],
          }}
        />
      ) : (
        <p style={{ fontWeight: "600" }}>Aucune mission</p>
      )}
    </>
  );
};

export default ProspectorMissions;
