import React, { useState, useEffect } from "react";
import Validation from "./Validation";
import CheckboxInput from "./Inputs/CheckboxInput";
import TextInput from "./Inputs/TextInput";
import NumberInput from "./Inputs/NumberInput";
import DateInput from "./Inputs/DateInput";
import SelectInput from "./Inputs/SelectInput";
import EmailInput from "./Inputs/EmailInput";
import TelInput from "./Inputs/TelInput";
import FloatInput from "./Inputs/FloatInput";
import PasswordInput from "./Inputs/PasswordInput";
import RangeInput from "./Inputs/RangeInput";
import CheckboxListInput from "./Inputs/CheckboxListInput";
import CheckBtnListInput from "./Inputs/CheckBtnListInput";
import BtnListInput from "./Inputs/BtnListInput";
import SignaturePadInput from "./Inputs/SignaturePadInput/SignaturePadInput";
import LongTextInput from "./Inputs/LongTextInput";
import Autocomplete from "./Inputs/Autocomplete/Autocomplete";

const Control = (props) => {
  const componentTypes = {
    checkbox: CheckboxInput,
    text: TextInput,
    number: NumberInput,
    float: FloatInput,
    date: DateInput,
    email: EmailInput,
    select: SelectInput,
    tel: TelInput,
    password: PasswordInput,
    range: RangeInput,
    checkboxList: CheckboxListInput,
    btnList: BtnListInput,
    checkBtnList: CheckBtnListInput,
    autocomplete: Autocomplete,
    signature: SignaturePadInput,
    longText: LongTextInput,
  };

  var TargetComponent = componentTypes[props.type];
  const [isComplete, setIsComplete] = useState(true);

  useEffect(() => {
    validateInput();
  }, [props.value, props.isValid]);

  useEffect(() => {
    if (isComplete && props.setError) {
      props.setError({ ...props.error, [props.name]: false });
    }
  }, [isComplete]);

  useEffect(() => {
    if (props.error && props.error[props.name]) {
      setIsComplete(false);
    }
  }, [props.error]);

  const validateInput = () => {
    if (typeof props.isValid == "undefined") {
      let isInputValid = Validation.validate(
        props.type,
        props.value,
        props.required
      );

      setIsComplete(isInputValid);
    } else {
      setIsComplete(props.isValid);
    }
  };

  return (
    <div
      style={props.containerStyle || {}}
      className={
        "form-group " + (props.className || "") + " " + (props.margin || "my-0")
      }
    >
      <TargetComponent {...props} isValid={isComplete} />
    </div>
  );
};

export default Control;
