import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import useStore from "../../../context/useStore";
import Table from "../../common/Table/Table";
import { DateTime } from "luxon";
import Stats from "../Stats/Stats";

const Dashboard = () => {
  const [state, dispatch] = useStore();
  const [errors, setErrors] = useState({});

  useEffect(() => {}, []);

  return <Stats />;
};

export default Dashboard;
