import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import Axios from "axios";
import { API_URL, image_url } from "../../../config";
import { toast } from "react-toastify";
import Loader from "../../common/Loader";

const ImagesUploader = ({ mission, setMission, getMission }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isRemoveLoading, setIsRemoveLoading] = useState(false);
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    console.log(acceptedFiles);
    const formData = new FormData();

    formData.append("file", acceptedFiles[0]);

    uploadImages(formData);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  function uploadImages(formData) {
    setIsLoading(true);
    Axios({
      method: "POST",
      url: API_URL + "missions/" + mission.id + "/image",
      data: formData,
      headers: {
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    })
      .then((res) => {
        console.log(res.data);
        toast.success("Image enregistrée !");
        getMission();
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }
  function copyImageTag(i) {
    console.log(i);
    var copyText = document.getElementById(i);

    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/

    /* Copy the text inside the text field */
    document.execCommand("copy");
    toast.success(
      "Le tag a été copié dans le presse papier, collez le (ctrl+v) où vous voulez dans la description"
    );
  }
  function removeImage(id) {
    if (isRemoveLoading) return;
    setIsRemoveLoading(id);
    Axios.delete(API_URL + "missions/" + mission.id + "/images/" + id)
      .then((res) => {
        setIsRemoveLoading(false);
        var miss = JSON.parse(JSON.stringify(mission));
        miss.images = miss.images.filter((i) => i.image.id != id);

        setMission(miss);
      })
      .catch((err) => {
        console.log(err);
        setIsRemoveLoading(false);
      });
  }

  return (
    <div className="row p-3 mb-3">
      {mission.images.map((img, ik) => (
        <div
          className="col-4 p-2 animated fadeInUp"
          key={"imgpreview" + img.image.id}
        >
          <div
            className="bx-outset p-2 d-flex align-items-end img-block-preview position-relative"
            style={{
              backgroundImage: `url(${image_url + img.image.fileName})`,
              height: 200,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            <input
              className="form-control text-center bg-white"
              id={"tag" + ik}
              onClick={() => copyImageTag("tag" + ik)}
              value={"[[" + img.tag + "]]"}
            />

            {isRemoveLoading == img.image.id ? (
              <Loader style={{ position: "absolute", top: "25%" }} />
            ) : (
              <button
                className="btn btn-danger btn-sm position-absolute bxnone"
                style={{
                  top: "4%",
                  right: "4%",
                  boxShadow: "none" + "!important",
                }}
                onClick={() => removeImage(img.image.id)}
              >
                <i className="fa fa-times" />
              </button>
            )}
          </div>
        </div>
      ))}
      <div
        {...getRootProps()}
        className="d-flex align-items-center justify-content-center p-3 text-center flex-column col-4 bx-inset"
        style={{
          border: "1px dashed #d0d0d0",
          borderRadius: 20,
          height: 200,
        }}
      >
        <input {...getInputProps()} />
        {isLoading ? (
          <Loader />
        ) : isDragActive ? (
          <p>Déposez une image ici ...</p>
        ) : (
          <>
            <p>Glissez-déposez une image</p>
            <p>ou</p>
            <p>cliquez pour parcourir vos fichiers</p>
          </>
        )}
      </div>
    </div>
  );
};

export default ImagesUploader;
