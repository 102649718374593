import React from "react";
import Table from "../../../common/Table/Table";

const ProspectorPayback = ({ payback, constants }) => {
  const totalAmount = payback.reduce(
    (acc, cur) => {
      let amount = parseInt(cur.amount);
      let status = cur.status === "DONE";

      if (status) {
        acc["DONE"] += amount;
      } else {
        acc["WAITING"] += amount;
      }
      return acc;
    },
    { DONE: 0, WAITING: 0 }
  );

  return (
    <>
      {payback.length > 0 ? (
        <>
          <div className="d-flex">
            <div
              className="d-center p-2 m-1 animated fadeInUp faster"
              style={{ border: "1px solid #1f0065", borderRadius: "5px" }}
            >
              <p className="text-primary mb-0">
                Montant total reversé : <b>{totalAmount.DONE / 100}€</b>
              </p>
            </div>
            <div
              className="d-center p-2 m-1 animated fadeInUp faster"
              style={{ border: "1px solid #1f0065", borderRadius: "5px" }}
            >
              <p className="text-primary mb-0">
                Montant total en attente : <b>{totalAmount.WAITING / 100}€</b>
              </p>
            </div>
          </div>
          <Table
            datas={payback}
            fields={[
              {
                name: "Mission",
                path: "participation",
                render: (el) => (
                  <a href={`/constats/${el?.mission.id}`}>{el?.mission.name}</a>
                ),
              },
              {
                name: "Montant",
                path: "amount",
                render: (el) => `${el / 100}€`,
              },
              {
                name: "Statut",
                path: "status",
                render: (el) =>
                  constants.PAYMENT_STATUS.map(
                    (status) =>
                      status.id === el && (
                        <span
                          className={`text-${status.color} d-block text-left`}
                        >
                          {status.name}
                        </span>
                      )
                  ),
              },
            ]}
            exportOptions={{
              formatDatasFn: (datas) =>
                datas.map((d) => ({
                  ...d,
                  participation: d.participation?.mission?.name,
                  status: constants.PAYMENT_STATUS.find(
                    (status) => status.id === d.status
                  ).name,
                })),
              excludedFields: [],
            }}
          />
        </>
      ) : (
        <p style={{ fontWeight: "600" }}>Aucune rémunération</p>
      )}
    </>
  );
};

export default ProspectorPayback;
