import React from "react";
import { image_url } from "../../../config";
import Control from "../../common/Controls/Control";

const QuestionPreview = ({ question }) => {
  const change = () => {};

  const renderQuestion = () => {
    var image = question.image ? (
      <div>
        <img
          className="form-image mx-auto"
          src={image_url + question.image.fileName}
        />
      </div>
    ) : null;

    switch (question.type + "") {
      case "1":
        return (
          <div className="mt-0" noHairlines={true}>
            <Control
              type="text"
              label="Écrivez votre réponse ci-dessous"
              change={change}
              name={question._id}
              value={""}
            />
            {image}
          </div>
        );
      case "2":
        return (
          <div className="mt-0 " noHairlines={true}>
            {question.answers.map((ans, ansk) => {
              let isActive = false;
              return (
                <button
                  // onChange={change}
                  className={`btn font-weight-bold animated fadeInRight faster ${
                    isActive ? "btn-primary" : "btn-default"
                  }`}
                  onClick={(e) =>
                    change({
                      target: {
                        type: "checkbox",
                        name: question._id,
                        checked: !isActive,
                        value: ans.value,
                      },
                    })
                  }
                  key={"ans" + ansk}
                  name={question._id}
                  value={ans.value}
                  style={{
                    animationDelay: parseInt(ansk) * 50 + "ms",
                    transition: "200ms",
                  }}
                >
                  <div className=" d-flex align-items-center justify-content-between">
                    {ans.label.FR}{" "}
                  </div>
                </button>
              );
            })}
            {image}
          </div>
        );
      case "3":
        return (
          <div className="mt-0 " noHairlines={true}>
            <Control
              label="Choisissez"
              type="select"
              change={change}
              name={question._id}
              value={""}
              datas={question.answers.map((a) => ({ ...a, label: a.label.FR }))}
              dataIndex={"value"}
              dataLabel="label"
            />

            {image}
          </div>
        );
      case "4":
        return (
          <div className="mt-0 " noHairlines={true}>
            {question.answers.map((ans, ansk) => {
              let isActive = false;
              return (
                <button
                  className={`btn  border rounded font-weight-bold animated fadeInRight faster ${
                    isActive ? "btn-primary" : "btn-default"
                  }`}
                  onClick={(e) =>
                    change({
                      target: {
                        type: "select-one",
                        name: question._id,

                        value: ans.value,
                      },
                    })
                  }
                  key={"ans" + ansk}
                  value={ans.value}
                  name={question._id}
                  style={{
                    animationDelay: parseInt(ansk) * 50 + "ms",
                    transition: "200ms",
                  }}
                >
                  <div className=" d-flex align-items-center justify-content-between">
                    {ans.label.FR}{" "}
                  </div>
                </button>
              );
            })}
            {image}
          </div>
        );
        case 5:
        return (
          <div className="mt-0" noHairlines={true}>
            <Control
              type="longText"
              label="Écrivez votre réponse ci-dessous"
              change={change}
              name={question._id}
              value={""}
              change={(e) =>
                change({
                  target: {
                    type: "longText",
                    name: question._id,
                    value: e.target.value,
                  },
                })
              }
            /> 
            {image}
          </div>
        );
      case "6":
        return (
          <div className="mt-0  p-3" noHairlines={true}>
            <Control
              label=""
              type="range"
              inputParams={{
                min: question.inputParams.min,
                max: question.inputParams.max,
                step: question.inputParams.step,
              }}
              value={""}
              change={(e) =>
                change({
                  target: {
                    type: "range",
                    name: question._id,
                    value: e.target.value,
                  },
                })
              }
            />

            {image}
          </div>
        );
      case "7":
        var inputsFile = [];
        var entries = [];
        for (let i = 0; i < question.inputParams.count; i++) {
          entries.push(
            <div key={"inpfilenum" + i}>
              <div className="item-content item-input">
                <div className="item-inner">
                  <div className="item-title item-label">Prendre une photo</div>
                  <div className="item-input-wrap">
                    <input
                      name="6013d3f5cacb805e989a37bf"
                      type="file"
                      accept="image/*"
                      className=""
                      style={{ paddingLeft: 0 }}
                      name={question._id}
                      onChange={(e) => change(e, i)}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        }
        inputsFile.push(
          <div className="mt-0 " noHairlines={true}>
            {entries}
            {image}
          </div>
        );
        return inputsFile;
      default:
        return "";
    }
  };

  return (
    <div className="animated fadeIn faster quest-gen">
      <strong className="mb-0">{question.question.FR}</strong>
      <p className="mt-0 text-primary">{question.description.FR}</p>
      {renderQuestion()}
    </div>
  );
};

export default QuestionPreview;
