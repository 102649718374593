import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const AnswerChartPie = ({ question, questionNum }) => {
  var scoreColors = {
    1: "#e6261f",
    2: "#eb7532",
    3: "#34bbe6",
    4: "#49da9a",
    5: "#a3e048",
  };

  const [state, setState] = useState({
    series: question.answers.map((a) => a.nb),
    options: {
      labels: question.answers.map((a) => `${a.name}`),
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return Math.round(val * 100) / 100 + "%";
        },
      },

      colors: question.answers.map((a) =>
        a.score ? scoreColors[a.score] : "#34bbe6"
      ),
      responsive: [
        {
          breakpoint: 6000,
          options: {
            legend: {
              position: "bottom",
              horizontalAlign: "left",
            },
          },
        },
      ],
      chart: {
        animations: {
          enabled: false,
        }
      }
    },
  });

  return (
    <div className="border p-2 h-100">
      <div>
        <strong>
          {questionNum}
          <i className="fa fa-circle mx-1" style={{ fontSize: 5 }} />
          {question.name}
        </strong>
      </div>
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="pie"
      />
    </div>
  );
};

export default AnswerChartPie;
