import React, { useState } from "react";
import AnswerChartWrapper from "./AnswerChartWrapper";
import PrintReport from "../PrintReport";
import ExportCSV from "../../../../common/ExportCsv/ExportCsv";
import useStore from "../../../../../context/useStore";
import {DateTime} from "luxon";

const ReportAnswers = ({ mission, report }) => {
    const [state, dispatch] = useStore();
    const constants = state.constants.items;
    const [isVisible, setIsVisible] = useState(false);

    const getScore = (score) => {
        var stars = [];
        for (var i = 0; i < 5; i++) {
            if (i < score) {
                stars.push(<i key={i} className="fa fa-star" />);
            } else {
                stars.push(<i key={i} className="far fa-star" />);
            }
        }
        return stars;
    };

    const formatExportDataStandardMission = (data) => {
        const allQuestions = [];

        data.sections.forEach(section => {
            section.questions.forEach(question => {
                allQuestions.push(question);
            });
        });

        const formattedArray = [[]];

        allQuestions.forEach((question, index) => {
            formattedArray[0].push(question.name);
        });
        formattedArray[0].unshift("");


        allQuestions.forEach((question, qIndex) => {
            // check if question is text or long text field
            let isTextField = question.type === 1 || question.type === 5;

            question.answers.forEach((answer, aIndex) => {
                const answerName = isTextField ? 'Autre (champ texte)' : answer.name;
                const answerValue = isTextField ? answer.name : answer.nb;

                let tempArray = [answerName];
                for (let i = 0; i < qIndex; i++) {
                    tempArray.push('');
                }
                tempArray.push(answerValue)
                formattedArray.push(tempArray);
            });
        });
        return formattedArray
    }

    return (
        <>
            <div className="col-12 d-flex">
                <PrintReport
                    missionName={mission.name}
                    isVisible={isVisible}
                    setIsVisible={setIsVisible}
                    report={report}
                />
                <ExportCSV
                    fields={[
                        {
                            name: "Magasin",
                            path: "store",
                        },
                        {
                            name: "Adresse",
                            path: "address",
                        },
                        {
                            name: "Section",
                            path: "section",
                        },
                        {
                            name: "Question",
                            path: "question",
                        },
                        {
                            name: "Réponse",
                            path: "answer",
                        },
                        {
                            name: "Commentaire",
                            path: "comment"
                        },
                        {
                            name: "Date",
                            path: "date",
                        }
                    ]}
                    datas={mission.type === 'STANDARD' ? formatExportDataStandardMission(report) : {...report, ...mission}}
                    exportOptions={{
                        formatDatasFn: (data) => {
                                let result = [];
                                data.sections.map(section => {
                                    for (let i = 0; i < section.questions.length; i += 2) {
                                        const priceAnswer = section.questions[i].answers[0].name;
                                        const comment = section.questions[i + 1].answers[0].name;

                                        result.push({
                                            store: constants.MISSION_STORES.find(store => store.id === data.store)?.name,
                                            address: data.address,
                                            section: section.name,
                                            question: section.questions[i].name,
                                            answer: priceAnswer,
                                            comment: comment,
                                            date: DateTime.fromISO(data.updatedAt).toFormat("dd/MM/yyyy")
                                        });
                                    }
                                });

                                return result;
                            },
                        excludedFields: []
                    }}
                    isStandardMission={mission.type === 'STANDARD'}
                    />
            </div>
            {report.sections.map((r, rk) => (
                <div key={rk} className={`row mx-0 `}>
                    <div
                        className="col-12 d-flex bg-primary text-white py-3 flex-row d-flex w-100 font-weight-bold justify-content-between"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                            setIsVisible(isVisible == r.id ? false : r.id)
                        }
                    >
                        <div>
                            SECTION : {r.name}
                            <i className="fa fa-caret-down ml-2" />
                        </div>
                        <div>{getScore(r.score)}</div>
                    </div>
                    <div className="print-container">
                    {(isVisible == r.id || isVisible === true) &&
                        r.questions.map(function (q, qk) {
                            if ([2, 3, 4, 6, 9].includes(q.type) || (q.type === 1 && q.name.match(/\[prix]/gi))) {
                                return (
                                    <AnswerChartWrapper
                                        key={`report${rk}${qk}`}
                                        question={q}
                                        questionNum={qk + 1}
                                        finished={report.participations.finished}
                                    />
                                )
                            }
                        })}
                    </div>
                </div>
            ))}
            <div className={`row mx-0 `}>
                <div className="col-12 d-flex bg-secondary text-white py-3 flex-row d-flex w-100 font-weight-bold justify-content-between">
                    <div>NOTE GLOBALE :</div>
                    <div>{getScore(report.score)}</div>
                </div>
            </div>
        </>
    );
};

export default ReportAnswers;
