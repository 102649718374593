import React, { useState, useEffect } from "react";
import Loader from "./Loader";
import Axios from "axios";
import { API_URL } from "../../config";

var cpto = null;

const PostalCode = ({ value, updateData, location }) => {
  const [cpInp, setCpInp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [selectedText, setSelectedText] = useState("Code postal");

  useEffect(() => {
    if (location) {
      setSelectedText(location.postalCode + " - " + location.name);
    }
  }, [location]);

  useEffect(() => {
    clearTimeout(cpto);
    if (cpInp.length == 5) {
      cpto = setTimeout(() => {
        getLocation();
      }, 800);
    }
  }, [cpInp]);

  function getLocation() {
    setIsLoading(true);
    setResults([]);
    Axios.get(API_URL + "cities?postalCode=" + cpInp)
      .then((res) => {
        setResults(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  function changeData(res) {
    updateData({ target: { name: "cityId", value: res.id } });
    setSelectedText(
      res.postalCode +
        " - " +
        res.townName +
        (res.locationName ? " - " + res.locationName : "")
    );
    document.getElementById("closeCityModal").click();
  }

  return (
    <>
      <div className="form-group mb-0">
        <label className="d-block position-relative">
          <span className="label-text">Localisation</span>
          <button
            id="cityBtn"
            type="button"
            className="btn text-left w-100"
            data-toggle="modal"
            data-target="#cityModal"
          >
            <span>{selectedText}</span>
            <i className="fa fa-search ml-2"></i>
          </button>
        </label>
      </div>
      <div
        className="modal fade"
        id="cityModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="cityModal"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Entrez un code postal (5 caractères)
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <input
                autofocus
                type="text"
                className="form-control"
                id="postalCodeSearch"
                placeholder="Code postal"
                value={cpInp}
                onChange={(e) => setCpInp(e.target.value)}
              />
              {isLoading && <Loader />}
              <ul className="list-group mt-3" id="cityList">
                {results.map((res) => (
                  <li
                    style={{ cursor: "pointer" }}
                    className="list-group-item"
                    onClick={() => changeData(res)}
                  >
                    {res.postalCode} - {res.townName}{" "}
                    {res.locationName ? res.locationName : ""}
                  </li>
                ))}
              </ul>
            </div>
            <div className="modal-footer">
              <button
                id="closeCityModal"
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Fermer
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostalCode;
