import React, { useEffect, useState } from "react";
import Control from "../../common/Controls/Control";
import SaveBtn from "../../common/SaveBtn";

const RejectIbanComment = ({ updateStatus, params }) => {
  const [selectedComments, setSelectedComments] = useState([]);
  const texts = ["L'IBAN est incorrect.", "L'IBAN a été refusé par la banque."];
  const comments = texts.map((t) => ({ id: t, name: t }));
  const [textComment, setTextComment] = useState("");
  useEffect(() => {
    console.log("selectedComments", selectedComments);
  }, [selectedComments]);
  console.log(comments);

  const save = () => {
    let comment = selectedComments.join(" ") + " " + textComment;

    updateStatus(params.id, params.status, comment);
  };

  return (
    <div>
      <Control
        label="Choisissez un motif pour le refus de cet IBAN"
        type="checkBtnList"
        value={selectedComments}
        change={(e) => setSelectedComments(e.target.value)}
        datas={comments}
        dataIndex="id"
        dataLabel="name"
      />
      <Control
        label="Ajoutez des précisions à ce refus"
        type="text"
        value={textComment}
        change={(e) => setTextComment(e.target.value)}
      />

      <SaveBtn save={save} isSaving={false} />
    </div>
  );
};

export default RejectIbanComment;
