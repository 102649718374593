import React from "react";

const ConditionDisplay = ({ condition, questions }) => {
  var conds = condition.split("&&");

  return condition != "" ? (
    <div
      className="d-flex justify-content-end position-absolute"
      style={{ right: "12%", top: 9 }}
    >
      {conds.map((c, ck) => (
        <div
          key={"condisp" + ck}
          className="indicator bg-danger text-white mr-1  px-2 "
        >
          q
          {questions.map((q, k) => {
            if (q.initialOrder.toString() == c.split("==")[0].split("q-")[1]) {
              return k + 1;
            }
          })}
        </div>
      ))}
    </div>
  ) : (
    <></>
  );
};

export default ConditionDisplay;
