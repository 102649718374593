import React, {useEffect, useState} from "react";
import Page from "../../common/layout/Page";
import Control from "../../common/Controls/Control";
import SaveBtn from "../../common/SaveBtn";
import dataService from "../../../helpers/dataService";
import {toast} from "react-toastify";

const ProductCategoryDetail = (props) => {
    const [errors, setErrors] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [category, setCategory] = useState({name: "", updatedAt: new Date()})
    const itemId = props.match.params.id
    const isEdit = itemId !== "create"

    useEffect(() => {
            if (isEdit) {
                getCategory(itemId)
            }
        },
        [])

    const getCategory = (id) => {
        dataService.get(`product-categories/${id}`,
            setCategory,
            setErrors,
            () => setIsLoading(false))
    }

    const save = () => {
        const onError = (error) => {
            toast.error(error)
            setErrors(error)
        }

        setIsLoading(true)
        if (isEdit) {
            const onSuccess = () => {
                toast.success("Famille enregistrée avec succès")
            }

            dataService.patch(`product-categories/${itemId}`,
                category,
                onSuccess,
                onError,
                () => setIsLoading(false))
        } else {
            const onSuccess = (data) => {
                toast.success("Famille créée avec succès")
                window.location.href = `${data.id}`
            }

            dataService.post("product-categories",
                category,
                onSuccess,
                onError,
                () => setIsLoading(false))
        }
    }

    return (<Page
            container={"container"}
            back={"/products"}
            title={"Détail de la famille"}
            errors={errors}
        >
            <div className={"row"}>
                <div className={"col-12 p-0"}>
                    <Control
                        label={"Nom"}
                        name={"name"}
                        type={"text"}
                        value={category.name}
                        change={(e) => setCategory({...category, name: e.target.value})}
                        required={true}
                    />
                </div>
                <div className={"d-flex justify-content-end w-100"}>
                    <SaveBtn
                        save={save}
                        isSaving={isLoading}
                        text={isEdit ? "Enregistrer" : "Créer"}
                        className={"btn btn-success btn-save"}
                    />
                </div>
            </div>
        </Page>)
}

export default ProductCategoryDetail