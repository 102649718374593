import React from "react";
import useStore from "../../../context/useStore";

const SubThemeSelectInput = ({
  updateData,
  value,
  name,
  errors = {},
  k,
  style = {},
}) => {
  const [state, dispatch] = useStore();
  const constants = state.constants.items;

  const valueChange = (e) => {
    var filteredValue = e.target.value;
    let changeEvent = {
      target: {
        name: name,
        value: filteredValue,
        k: k,
      },
    };

    updateData(changeEvent);
  };
  return (
    <div className="form-group my-0" style={style}>
      <label className="position-relative">
        <span className="label-text">Sous-thème</span>
        <div className="input-group">
          <select
            className="form-control"
            name={name}
            value={value}
            onChange={valueChange}
          >
            <option value="">Aucun</option>
            {constants.THEMES.map((th, thk) => (
              <optgroup key={"sthgrp" + thk} label={th.name}>
                {th.subThemes.map((sth, sthk) => (
                  <option key={"sthopt" + thk + sthk} value={sth._id}>
                    {sth.name}
                  </option>
                ))}
              </optgroup>
            ))}
          </select>
        </div>
        {errors[name] && (
          <small
            className={
              "form-text input-error-msg text-danger " +
              (errors[name] ? "animated flash" : "")
            }
            style={{
              top: 6,
              right: 8,
              fontWeight: "bold",
              fontSize: 12,
            }}
          >
            {errors[name]}
          </small>
        )}
      </label>
    </div>
  );
};

export default SubThemeSelectInput;
