import Axios from "axios";
import { DateTime } from "luxon";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { API_URL } from "../../../config";
import dataService from "../../../helpers/dataService";
import tools from "../../../helpers/tools";
import Control from "../../common/Controls/Control";
import Page from "../../common/layout/Page";
import Loader from "../../common/Loader";
import Pagination from "../../common/Pagination";
import Table from "../../common/Table/Table";
import AspiringProspectorListFilter from "./AspiringProspectorListFilters";

let strTimeout = null;

const AspiringProspectorList = () => {
    const [users, setUsers] = useState([]);
    const [total, setTotal] = useState(0);
    const [allUsers, setAllUsers] = useState([]);
    const [search, setSearch] = useState({
        user: { searchString: "", isDeleted: false, status: "", isFormationPaid: "" },
        page: 1,
    });
    const [errors, setErrors] = useState(false);
    const [nbPages, setNbPages] = useState(1);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let lsState = tools.getState("AspiringList", { search: search });
        setSearch(lsState.search);
        dataService.get("formation", setAllUsers);
    }, []);

    useEffect(() => {
        setIsLoading(true);
        clearTimeout(strTimeout);
        strTimeout = setTimeout(() => {
            searchUsers();
        }, 1500);
        tools.saveState("AspiringList", "search", search);
    }, [search]);

    const searchUsers = () => {
        setErrors(false);
        Axios.post(API_URL + "formation/search", search)
            .then((res) => {
                setUsers(res.data.users);
                setTotal(res.data.count);
                setNbPages(Math.ceil(parseInt(res.data.count) / 25));
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                setErrors(err.response.data);
                setUsers([]);
                console.log(err.response.data);
            });
    };

    const updateUserSearch = (e) => {
        let value = e.target.value;
        var su = { ...search };

        if (e.target.type == "checkbox") {
            su.user[e.target.name] = e.target.checked;
        } else {
            su.user[e.target.name] = value;
        }

        console.log(su);
        setSearch(su);
    };

    const changePage = (i) => {
        setSearch((search) => {
            return { ...search, page: i };
        });
    };

    const removeUser = (id) => {
        setIsLoading(true);
        dataService.remove(
            `formation/${id}`,
            {},
            searchUsers(),
            setErrors,
            () => setIsLoading(false)
        );
    };

    return (
        <Page
            container='container'
            title={"Demande de formation"}
            action={{ to: "/formation/create", text: "Ajouter" }}>
            <AspiringProspectorListFilter
                search={search}
                setSearch={setSearch}
            />
            <div className='row mb-4'>
                <div className='col'>
                    <Control
                        type='text'
                        label='Recherche'
                        name='searchString'
                        value={search.user.searchString}
                        change={updateUserSearch}
                        suffix={
                            <i
                                className='fa fa-search ml-2'
                                style={{ lineHeight: "40px" }}></i>
                        }
                    />
                </div>
                <div className='col align-items-center justify-content-end d-flex'>
                    {total} utilisateurs trouvés
                </div>
            </div>
            <div className='row'>
                <div className='col-12 p-4'>
                    {errors && errors.other ? (
                        <div className='text-danger m-auto text-center'>
                            {errors.other}
                        </div>
                    ) : isLoading || !allUsers.length ? (
                        <Loader />
                    ) : (
                        <Table
                            datas={users}
                            fields={[
                                {
                                    name: "Prénom",
                                    path: "firstname",
                                },
                                {
                                    name: "Nom",
                                    path: "lastname",
                                },
                                {
                                    name: "Email",
                                    path: "email",
                                },
                                {
                                    name: "Téléphone",
                                    path: "phone",
                                },
                                {
                                    name: "Ville",
                                    path: "location",
                                    render: (el) => {
                                        return el.cityName;
                                    },
                                },
                                {
                                    name: "Statut",
                                    path: "status",
                                    render: (el) => {
                                        return el == "finished" ? (
                                            <span>
                                                <i className='fa fa-check-double text-success mr-2' />
                                                Formation terminée
                                            </span>
                                        ) : el === "progress" ? (
                                            <span>
                                                <i className='fa fa-check text-primary mr-2' />
                                                Date attribuée
                                            </span>
                                        ) : (
                                            <span>
                                                <i className='fa fa-pause text-warning mr-2' />
                                                En attente
                                            </span>
                                        );
                                    },
                                },
                                {
                                    name: "Paiement",
                                    path: "formation",
                                    render: (el) => {
                                        return el.isFormationPaid ? (
                                            <span>
                                                <i className="fa fa-check-double text-sucess mr-2" />
                                                Paiement effectué
                                            </span>
                                        ) : (
                                            <span>
                                                <i className="fa fa-pause text-warning mr-2" />
                                                Paiement en attente
                                            </span>
                                        )
                                    }
                                },
                                {
                                    name: "Actions",
                                    path: "id",
                                    render: (el) => {
                                        return search.user.isDeleted ? null : (
                                            <div className='d-center flex-row'>
                                                <Link
                                                    className='btn btn-primary'
                                                    to={`/formation/${el}`}>
                                                    <i className='fa fa-edit' />
                                                </Link>
                                                <button
                                                    className='btn btn-danger'
                                                    onClick={() =>
                                                        removeUser(el)
                                                    }>
                                                    <i className='fa fa-times' />
                                                </button>
                                            </div>
                                        );
                                    },
                                },
                            ]}
                            exportOptions={{
                                overrideDatas: users,
                                formatDatasFn: (data) =>
                                    data.map((d) => ({
                                        ...d,
                                        location: `${
                                            d.location.cityName || ""
                                        } ${d.location.postalCode || ""}`,
                                        birthdate: DateTime.fromISO(
                                            d.birthdate
                                        ).toFormat("dd/MM/yyyy"),
                                        isNewsletter: d.isNewsletter
                                            ? "Oui"
                                            : "Non",
                                        formation: d.formation.formationDate
                                            ? DateTime.fromISO(
                                                  d.formation.formationDate
                                              ).toFormat("dd/MM/yyyy")
                                            : "",
                                        status:
                                            d.status === "finished"
                                                ? "Formation terminée"
                                                : d.status === "progress"
                                                ? "Date attribuée"
                                                : "En attente",
                                    })),
                                excludedFields: ["Actions", "Statut"],
                                additionalFields: [
                                    {
                                        name: "Date formation",
                                        path: "formation",
                                    },
                                    {
                                        name: "Statut formation",
                                        path: "status",
                                    },
                                    {
                                        name: "Date naiss",
                                        path: "birthdate",
                                    },
                                    {
                                        name: "Newsletter ?",
                                        path: "isNewsletter",
                                    },
                                ],
                            }}
                        />
                    )}
                    <Pagination
                        nbPages={nbPages}
                        page={search.page}
                        changePage={changePage}
                    />
                </div>
            </div>
        </Page>
    );
};

export default AspiringProspectorList;
