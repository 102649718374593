import React, { Component, useState } from "react";
import Control from "../../common/Controls/Control";
import Loader from "../../common/Loader";

const FormRejectModal = ({ rejectParticipation, participation }) => {
  const [comment, setComment] = useState(
    participation.userManagerComment || ""
  );
  const [isLoading, setIsLoading] = useState(false);
  const isRejected = participation.status === 6;

  const reject = () => {
    setIsLoading(true);
    rejectParticipation(participation.id, comment);
  };

  return (
    <>
      <h3>
        {isRejected ? "Changer le commentaire" : "Rejeter la participation"}
      </h3>
      <Control
        className="w-100 my-5"
        label="Commentaire pour le rejet"
        name="comment"
        id="comment"
        type="text"
        value={comment}
        change={(e) => setComment(e.target.value)}
        showValidation={false}
      />
      <div className="my-3 container">
        {isLoading ? (
          <Loader />
        ) : (
          <button
            className="btn-large btn btn-danger text-center shadow-sm my-1 ml-auto mr-1 flex-column justify-content-center flex-wrap"
            onClick={reject}
          >
            {isRejected ? "Mettre à jour" : "Confirmer"}
          </button>
        )}
      </div>
    </>
  );
};

export default FormRejectModal;
