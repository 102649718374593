import React, { useRef, useEffect, useState } from "react";
import LabelContainer from "../../LabelContainer";
import Colors from "../../../../../theme/variables.scss"
import $ from "jquery"

const Autocomplete = ({
    k = -1,
    autoFocus,
    autocomplete = "on",
    inputStyle = {},
    inputClassName = "",
    type,
    disabled = false,
    id = null,
    value,
    change = () => {},
    placeholder = "",
    datas = [],
    autoCompleteDisplayIndex = false,
    dataIndex = "id",
    dataLabel = "name",
    dataLabel2 = false,
    dataLabel3 = false,
    dataLabelRight = false,
    dataLabelBtn1 = false,
    dataLabelBtn2 = false,
    hideAutocompleteOnFocusOut = false,
    absoluteAutocomplete = false, // buggé
    isLoading = false,
    noResults = false,
    autoCompleteHasApiDatas = false,
    dataLabelIndex = false,
    ...props
}) => {
    const inputRef = useRef(null)
    const fieldRef = useRef(null)
    const name = props.name
    const [autoCompleteEntrySelected, setAutoCompleteEntrySelected] = useState(false)
    const [searchStr, setSearchStr] = useState("")
    const [acResults, setAcResults] = useState([])

    let selector = k !== -1 ? name + "-ac" + k : id ? id : name

    useEffect(() => {
        if (selectedEntry) {
            setAutoCompleteEntrySelected(true)
        }
    }, [])

    useEffect(() => {
        seekAutocomplete()
    }, [searchStr])

    useEffect(() => {
        seekAutocomplete()
        showAutocomplete()
    }, [datas])

    const valueChange = (e, value) => {
        let filteredValue = value

        setAutoCompleteEntrySelected(value !== "")
        let changeEvent = {
            target: {
                name: props.name,
                value: filteredValue,
                k: k
            }
        }

        change(changeEvent)
    }
    const seekAutocomplete = () => {
        let acs = []
        datas.forEach(d => {
            if (
                autoCompleteHasApiDatas ||
                (d[dataLabel] &&
                    d[dataLabel].toLowerCase().includes(searchStr.toLowerCase())) ||
                (d[dataLabel2] &&
                    d[dataLabel2].toLowerCase().includes(searchStr.toLowerCase())) ||
                (d[dataLabel3] &&
                    d[dataLabel3].toLowerCase().includes(searchStr.toLowerCase()))
            ) {
                acs.push(d)
            }
        })
        setAcResults(acs)
    }

    const hideAutocomplete = () => {
        inputRef.current.style.display = "none"
    }
    const showAutocomplete = () => {
        inputRef.current.style.display = "block"
    }
    const getCompositeData = (obj, key) => {
        let comp = key ? key.split(".") : [""]
        if (comp.length > 1) {
            return obj[comp[0]][comp[1]]
        }
        return obj[comp[0]]
    }

    const selectedEntry = datas.find(d => d[dataIndex] === value)

    const onFocus = () => {
        showAutocomplete()
    }

    const onBlur = () => {
        if (inputRef.current && hideAutocompleteOnFocusOut) {
            hideAutocomplete()
        }
    }

    return (
        <LabelContainer {...props} value={value}>
            <div className="position-relative w-100 mt-2">
                <input
                    ref={fieldRef}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    className={`form-control ${inputClassName}`}
                    autoFocus={autoFocus}
                    value={searchStr}
                    name={name}
                    id={selector}
                    onChange={(e) => setSearchStr(e.target.value)}
                    placeholder={placeholder}
                    autoComplete={autocomplete}
                />
                <button
                    className="btn text-light position-absolute p-0"
                    onClick={(e) => {
                        fieldRef.current.focus()
                        setSearchStr("")
                        valueChange(e, "")
                    }}
                    style={{
                        opacity: searchStr !== "" || value !== "" ? "1" : "0",
                        borderRadius: "5px",
                        width: 30,
                        height: 30,
                        right: 8,
                        minHeight: "auto",
                        top: "50%",
                        transform: "translateY(-50%)",
                        zIndex: 99,
                    }}
                    >
                    <i className="fa fa-times" />
                </button>
            </div>
            {autoCompleteEntrySelected && (
                <button
                    id={`btn-over-${selector}`}
                    className="btn btn-default text-left position-absolute w-100 h-100 p-0 mt-2"
                    style={{
                        padding: "0 10px",
                        color: "black",
                        borderRadius: 3,
                        background: "white",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        minHeight: 0,
                        maxHeight: 30
                    }}
                    onClick={(e) => {
                        setAutoCompleteEntrySelected(false)
                        valueChange(e, "")
                        $(`#btn-over-${selector}`).hide()
                        fieldRef.current.focus()
                    }}
                >
                    {autoCompleteDisplayIndex && selectedEntry ? (
                        <>
                            <b className="mr-2">
                                {selectedEntry[autoCompleteDisplayIndex]}
                            </b>
                            {dataLabel2 && (
                                `(${getCompositeData(selectedEntry, dataLabel2)})`
                            )}
                            <span className="ml-2" style={{ fontSize: 12 }}>
                                {getCompositeData(selectedEntry, dataLabelBtn1)}{" "}
                                {getCompositeData(selectedEntry, dataLabelBtn2)}
                            </span>
                        </>
                    ) : value ? (
                        value
                    ) : (
                        ""
                    )}
                </button>
            )}
            {(acResults.length > 0 || isLoading) && !autoCompleteEntrySelected && (
                <div className="w-100 position-relative embeded-forminfo">
                    {isLoading
                        ? "Recherche en cours..."
                        : autoCompleteEntrySelected
                        ? ""
                        : "Sélectionnez parmi les résultats"}
                </div>
            )}
            {noResults && (
                <>
                <div className="w-100 position-relative"
                     style={{
                         margin: "60px 0 30px 0",
                         left: -60
                     }}
                >
                    Aucun résultat trouvé
                </div>
                <button
                    className="btn btn-default"
                    onClick={(e) => {
                        valueChange(e, "")
                        let bid = selector
                        $(`#${bid}`).focus()
                    }}
                >
                    Modifier ma recherche
                </button>
                </>
            )}
            <div
                ref={inputRef}
                className={`ac-results fast mb-3 ${absoluteAutocomplete ? "position-absolute" : ""}`}
                id={`acres${selector}`}
                style={{
                    top: 41,
                    padding: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 9,
                    display: "none",
                    borderRadius: window.borderRadius,
                    overflow: "hidden"
                }}
            >
                {acResults.map((a, ak) => {
                    return (
                        <div
                            className="ac-res d-flex align-items-center flex-row justify-content-between"
                            style={{
                                padding: autoCompleteEntrySelected ? 0 : "9.5px 25px",
                                height: autoCompleteEntrySelected ? "0px" : "58px",
                                opacity: autoCompleteEntrySelected ? 0 : 1,
                                borderBottom: `1px solid ${Colors.default}`,
                                width: "100%",
                                cursor: "pointer",
                                animationDelay: 800 + ak * 80 + "ms",
                                overflow: "hidden",
                                transition: "400ms"
                            }}
                            onClick={(e) => valueChange(e, a[dataIndex] + "")}
                            key={`acres${ak}${k}`}
                        >
                            <div className="d-flex flex-column">
                                <div>
                                    <b>{getCompositeData(a, dataLabel)}</b>{" "}
                                    {dataLabel2 && " " + getCompositeData(a, dataLabel2)}
                                    {dataLabel3 && " " + getCompositeData(a, dataLabel3)}
                                </div>
                                <div
                                    style={{
                                        fontSize: 12
                                    }}
                                >
                                    {dataLabelBtn1 && " " + getCompositeData(a, dataLabelBtn1)}
                                    {dataLabelBtn2 && " " + getCompositeData(a, dataLabelBtn2)}
                                </div>
                            </div>
                            <div
                                style={{
                                    fontSize: 12
                                }}
                            >
                                {dataLabelRight ? getCompositeData(a, dataLabelRight) : ""}
                            </div>
                        </div>
                    )
                })}
            </div>
        </LabelContainer>
    )
}

export default Autocomplete