import React from "react";
import ReactApexChart from "react-apexcharts";
import Loader from "../../common/Loader";
import SaveBtn from "../../common/SaveBtn";
import Colors from "../../../theme/variables.scss";
import useParticipationChart from "./useParticipationChart";
import { Link } from "react-router-dom";

const ParticipationChart = ({ url, year, displayTotal }) => {
  const { isLoading, chartState, getDatas, hasDatas, total, top5 } =
    useParticipationChart(url, year);

  return (
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-end">
        <SaveBtn
          type={hasDatas ? "btn-default d-center" : "btn-primary d-center"}
          loaderColor={hasDatas ? Colors.primary : Colors.default}
          margin="m-0 get-stat-btn"
          save={getDatas}
          isSaving={isLoading}
          text={
            hasDatas ? (
              <>
                <i className="fa fa-sync mr-2" />
                Actualiser les données
              </>
            ) : (
              <>
                <i className="fa fa-cloud-download-alt mr-2" />
                Charger les données
              </>
            )
          }
        />
      </div>
      {!isLoading && hasDatas ? (
        <>
          <div className="col-12 col-md-6">
            <ReactApexChart
              options={chartState.pie1.options}
              series={chartState.pie1.series}
              type="pie"
              height={350}
            />
          </div>
          <div className="col-12 col-md-6">
            <strong>TOP 5</strong>
            <ol className="list-group">
              {top5.map((t, tk) => (
                <li key={`top5${tk}`}>
                  <div className="d-inline-block">
                    <Link
                      to={`/prospectors/${t.id}`}
                      className="font-weight-bold"
                    >
                      {t.pseudo} - {t.firstname} {t.lastname} - {t.email}
                    </Link>
                  </div>
                  <div className="d-inline-block float-right">
                    Rang {t.rank}
                  </div>
                </li>
              ))}
            </ol>
          </div>

          <div className="col-12 text-center font-weight-bold">
            TOTAL : {total}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default ParticipationChart;
