import React, { useEffect, useState } from "react";
import Page from "../../common/layout/Page";
import FormGenerator from "./FormGenerator/FormGenerator";

const MissionPreview = (props) => {
  return (
    <Page container="container" title="Aperçu de la mission" back={"/missions"}>
      <div id="preview_container">
        <FormGenerator missionId={props.match.params.id} />
      </div>
    </Page>
  );
};

export default MissionPreview;
