import React, { useLayoutEffect, useState } from "react";
import dataService from "../../../../helpers/dataService";
import Loader from "../../../common/Loader";
import Page from "../../../common/layout/Page";
import FormRogue from "./FormRogue";
import tools from "../../../../helpers/tools";
import { toast } from "react-toastify";

const MissionFormRogue = ({ missionId }) => {
  const [mission, setMission] = useState(false);
  const [report, setReport] = useState(false);
  const [participation, setParticipation] = useState(false);
  const [errors, setErrors] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const [formComplete, setFormComplete] = useState(false);

  useLayoutEffect(() => {
    getMission();
  }, []);

  useLayoutEffect(() => {
    if (mission) {
      getReport();
    }
  }, [mission]);

  const getMission = () => {
    setIsLoading(true);
    dataService.get(`missions/${missionId}/form`, setMission, setErrors, () =>
      setIsLoading(false)
    );
  };

  const getReport = () => {
    setIsLoading(true);
    dataService.get(
      `missions/${missionId}/report/rogue`,
      setReport,
      setErrors,
      () => setIsLoading(false)
    );
  };

  const createNewParticipation = () => {
    setFormComplete(false);
    setIsLoading(true);

    dataService.post(
      `rogueparticipations/mines`,
      {
        rogueParticipation: {
          status: "progress",
          mission: missionId,
        },
      },
      (res) => {
        if (tools.isEmpty(res.data)) {
          dataService.post(
            `rogueparticipations`,
            { mission: missionId },
            setParticipation,
            (err) => console.log(err),
            () => setIsLoading(false)
          );
        } else {
          setParticipation(res.data[0]);
          toast.info("Reprise de la postulation en cours");
        }
      },
      (err) => console.log(err),
      () => setIsLoading(false)
    );
  };

  const isMissionAvailable = () => {
    if (report) {
      if (
        report.participations.finished < report.participations.totalExpected
      ) {
        return (
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-primary"
              onClick={() => createNewParticipation()}
            >
              Commencer la mission
            </button>
          </div>
        );
      } else {
        return (
          <h5 className="mb-0 text-center">
            Le nombre attendu de rapport à été atteint pour ce constat
          </h5>
        );
      }
    }
  };

  return (
    <Page container="container">
      {isLoading ? (
        <Loader />
      ) : participation ? (
        <>
          {isSaving ? (
            <Loader text="Veuillez patienter pendant l'enregistrement de votre réponse. Cela peut prendre quelques minutes." />
          ) : formComplete ? (
            isMissionAvailable()
          ) : (
            <FormRogue
              mission={mission}
              questions={mission.questions}
              formErrors={formErrors}
              isSaving={isSaving}
              setIsSaving={setIsSaving}
              setFormComplete={setFormComplete}
              setFormErrors={setFormErrors}
              participation={participation}
              report={report}
              setReport={setReport}
            />
          )}
        </>
      ) : (
        isMissionAvailable()
      )}
    </Page>
  );
};

export default MissionFormRogue;
