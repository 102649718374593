import React from "react";
import Control from "../../common/Controls/Control"

const PriceStatementFilters = ({ search, setSearch, prospects, stores, productCategories, productOrigin }) => {
    return (
        <div className="row">
            <div className="col-6">
                <Control
                    label="Magasin"
                    type="select"
                    value={search.mission.store}
                    datas={stores}
                    name="store"
                    dataIndex="id"
                    dataLabel="name"
                    change={e => setSearch({
                        ...search,
                        mission: { ...search.mission, store: e.target.value }
                    })}
                />
            </div>
            <div className="col-6">
                <Control
                    label="Famille de produit"
                    type="select"
                    value={search.mission.productCategory}
                    datas={productCategories}
                    name="productCategory"
                    dataIndex="id"
                    dataLabel="name"
                    change={e => setSearch({
                        ...search,
                        mission: { ...search.mission, productCategory: e.target.value }
                    })}
                />
            </div>
            <div className="col-6">
                <Control
                    label="Origine"
                    type="select"
                    value={search.mission.productOrigin}
                    datas={productOrigin}
                    name="productOrigin"
                    dataIndex="id"
                    dataLabel="name"
                    change={e => setSearch({
                        ...search,
                        mission: { ...search.mission, productOrigin: e.target.value }
                    })}
                />
            </div>
            <div className="col-6">
                <Control
                    label="Entre le"
                    type="date"
                    name="beginAt"
                    value={search.mission.beginAt}
                    change={(e) => setSearch({
                        ...search,
                        mission: { ...search.mission, beginAt: e.target.value }
                    })}
                />
            </div>
            <div className="col-6">
                <Control
                    label="Et le"
                    type="date"
                    name="endAt"
                    value={search.mission.endAt}
                    change={(e) => setSearch({
                        ...search,
                        mission: { ...search.mission, endAt: e.target.value }
                    })}
                />
            </div>
        </div>
    )
}


export default PriceStatementFilters