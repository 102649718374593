const toolsFormGen = {
  formatForApi: (questions) => {
    questions.map((q) => {
      if (q.image && typeof q.image !== "string") {
        q.image = q.image.id;
      }
      return q;
    });

    return questions;
  },
};

export default toolsFormGen;
