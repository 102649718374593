import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const MissionAnswer = ({ question, k }) => {
  console.log("question", question);
  const [chartOptions, setChartOptions] = useState({});
  const [chartSeries, setChartSeries] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);
  useEffect(() => {
    if (["btnList", "checkList"].includes(question.type))
      renderAnswer(question, k);
  }, [question]);

  function renderAnswer(question, k) {
    var totalAnswers = 0;
    var labels = [];
    var series = [];
    for (var key in question.answers) {
      totalAnswers += parseInt(question.answers[key]);
    }
    for (var key in question.answers) {
      labels.push(question.options.find((v) => v.value == key).name);
      series.push(
        Math.round(
          (parseInt(question.answers[key]) / totalAnswers) * 100 * 100
        ) / 100
      );
    }
    console.log("labels", labels);
    setChartSeries(series);
    setChartOptions({
      labels,
    });
  }

  var content = "";

  if (["btnList", "checkList"].includes(question.type)) {
    content = (
      <Chart
        type="donut"
        options={chartOptions}
        labels={chartLabels}
        series={chartSeries}
      />
    );
  } else if (["range"].includes(question.type)) {
    content = (
      <div className="btn-group w-100">
        <button className="btn btn-default">
          <div>Min</div>
          {question.answers.min}
        </button>
        <button className="btn btn-success">
          <div>Moyenne</div>
          {question.answers.average}
        </button>
        <button className="btn btn-default">
          <div>Max</div>
          {question.answers.max}
        </button>
      </div>
    );
  }
  // else if (["textarea"].includes(question.type)) {
  //   var ansList = [];
  //   question.answers.forEach(a =>
  //     ansList.push(
  //       <div className="list-item">
  //         <p className="font-weight-light"> {a}</p>
  //       </div>
  //     )
  //   );
  //   content = <div className="list-group">{ansList}</div>;
  // }

  return question.type != "textarea" ? (
    <div key={"question-" + k} className="col-6 mb-4">
      <div className="card" style={{ minHeight: "100%" }}>
        <div className="card-body">
          <div className="card-title">{question.question}</div>
          {content}
        </div>
      </div>
    </div>
  ) : (
    ""
  );
};

export default MissionAnswer;
