import React, { useState } from "react";
import Page from "../../common/layout/Page";
import StatContainer from "./StatContainer";
import StatsFilters from "./StatsFilters";
import ProspectorChart from "./ProspectorChart";
import ProspectChart from "./ProspectChart";
import MissionChart from "./MissionChart";
import ParticipationChart from "./ParticipationChart";

const Stats = () => {
  const [year, setYear] = useState(new Date().getFullYear());

  return (
    <Page title="Statistiques" container="container-fluid" bgTransparent>
      <div className="row mx-2">
        {/* <StatsFilters year={year} setYear={setYear} /> */}

        <StatContainer title="Total agents" index={0}>
          <ProspectorChart url="statistics/prospectors" year={year} />
        </StatContainer>
        <StatContainer title="Total prospects" index={0}>
          <ProspectChart url="statistics/prospects" year={year} />
        </StatContainer>
        <StatContainer title="Total missions" index={0}>
          <MissionChart url="statistics/missions" year={year} />
        </StatContainer>
        <StatContainer title="Total participations" index={0}>
          <ParticipationChart url="statistics/participations" year={year} />
        </StatContainer>
      </div>
    </Page>
  );
};

export default Stats;
