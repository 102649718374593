import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Page from "../../common/layout/Page";
import Axios from "axios";
import { API_URL } from "../../../config";
import Control from "../../common/Controls/Control";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import Pagination from "../../common/Pagination";
import tools from "../../../helpers/tools";
import Table from "../../common/Table/Table";
import { DateTime } from "luxon";
import dataService from "../../../helpers/dataService";

var strTimeout = null;

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [state, dispatch] = useStore();
  const constants = state.constants.items;
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [errors, setError] = useState(false);
  const [search, setSearch] = useState({
    user: { searchString: "", isDeleted: false, bankAccountStatus: "" },
    page: 1,
  });
  const [nbPages, setNbPages] = useState(1);
  const [allProspectors, setAllProspectors] = useState([]);

  useEffect(() => {
    let lsState = tools.getState("ProspectorList", { search: search });
    setSearch(lsState.search);
    dataService.get("prospectors", setAllProspectors);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    clearTimeout(strTimeout);
    strTimeout = setTimeout(() => {
      searchUsers();
    }, 1500);
    tools.saveState("ProspectorList", "search", search);
  }, [search]);

  function searchUsers() {
    setError(false);
    Axios.post(API_URL + "prospectors/search", search)
      .then((res) => {
        setUsers(res.data.datas);
        setTotal(res.data.count);
        setNbPages(Math.ceil(parseInt(res.data.count) / 25));
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.response?.data);
        setUsers([]);
        console.log(err.response?.data);
      });
  }
  function updateUserSearch(e) {
    var value = e.target.value;
    var su = { ...search };
    if (e.target.type == "checkbox") {
      su.user[e.target.name] = e.target.checked;
    } else {
      su.user[e.target.name] = value;
    }

    console.log(su);

    setSearch(su);
  }

  function changePage(i) {
    setSearch((search) => {
      return { ...search, page: i };
    });
  }

  const removeProspector = (id) => {
    setIsLoading(true);
    dataService.remove(`prospectors/${id}`, {}, searchUsers(), setError, () =>
      setIsLoading(false)
    );
  };

  console.log("search", search);

  return (
    <Page
      container="container"
      // action={{ to: "/prospectors/create", text: "Créer un prospect" }}
      title={"Liste des agents"}
    >
      <div className="row">
        <div className="col">
          <Control
            label="Status IBAN"
            value={search.user.bankAccountStatus}
            type="btnList"
            btnInline
            datas={[
              { id: 1, name: "Absent" },
              { id: 2, name: "À valider" },
              { id: 3, name: "Refusé" },
              { id: 4, name: "Validé" },
            ]}
            change={(e) =>
              setSearch({
                ...search,
                user: { ...search.user, bankAccountStatus: e.target.value },
              })
            }
          />
        </div>
      </div>
      <div className="row mb-4">
        <div className="col">
          <Control
            type="text"
            label="Recherche"
            name="searchString"
            value={search.user.searchString}
            change={updateUserSearch}
            suffix={
              <i
                className="fa fa-search ml-2"
                style={{ lineHeight: "40px" }}
              ></i>
            }
          />
        </div>
        <div className="col">
          <Control
            type="checkbox"
            label="Afficher les supprimés"
            name="isDeleted"
            checked={search.user.isDeleted}
            change={updateUserSearch}
            suffix={
              <i
                className="fa fa-search ml-2"
                style={{ lineHeight: "40px" }}
              ></i>
            }
          />
        </div>
        <div className="col align-items-center justify-content-end d-flex">
          {total} utilisateurs trouvés
        </div>
      </div>
      <div className="row">
        <div className="col-12 p-4">
          {errors && errors.other ? (
            <div className="text-danger m-auto text-center">{errors.other}</div>
          ) : isLoading || !allProspectors.length ? (
            <Loader />
          ) : (
            <Table
              fields={[
                {
                  name: "Pseudo",
                  path: "pseudo",
                },
                {
                  name: "Prénom",
                  path: "firstname",
                },
                {
                  name: "Nom",
                  path: "lastname",
                },
                { name: "Email", path: "email" },
                {
                  name: "Statut IBAN",
                  path: "bankAccount",
                  render: (el) =>
                    constants.BANK_ACCOUNT_STATUSES.map(
                      (status) =>
                        status.id === el?.status && (
                          <span
                            className={`text-${status.color} d-block text-left`}
                          >
                            {status.name}
                          </span>
                        )
                    ),
                },
                {
                  name: "Créé le",
                  path: "createdAt",
                  render: (el) => DateTime.fromISO(el).toFormat("dd/MM/yyyy"),
                },
                {
                  name: "Total reversé",
                  path: "balance",
                  render: (el) =>
                    el
                      .filter((b) => b.paymentStatus === "DONE")
                      .map((p) => p.amount) /
                      100 +
                      "€" || "0€",
                },
                {
                  name: "Actions",
                  path: "id",
                  render: (el) => {
                    return search.user.isDeleted ? null : (
                      <div className="d-center flex-row">
                        <Link
                          className="btn btn-primary"
                          to={`/prospectors/${el}`}
                        >
                          <i className="fa fa-edit" />
                        </Link>
                        <button
                          className="btn btn-danger"
                          onClick={() => removeProspector(el)}
                        >
                          <i className="fa fa-times" />
                        </button>
                      </div>
                    );
                  },
                },
              ]}
              datas={users}
              exportOptions={{
                overrideDatas: allProspectors,
                formatDatasFn: (datas) =>
                  datas.map((d) => ({
                    ...d,
                    location: `${d.address || ""} ${
                      d.location?.postalCode || ""
                    } ${d.location?.name || ""}`,
                    iban: d.bankAccount?.iban,
                    ibanStatus:
                      d.bankAccount?.status && d.bankAccount?.status.length
                        ? constants.BANK_ACCOUNT_STATUSES.find(
                            (s) => s.id == d.bankAccount.status
                          ).name
                        : "",
                    isActivated: d.isActivated ? "Oui" : "Non",
                    birthdate: DateTime.fromISO(d.birthdate).toFormat(
                      "dd/MM/yyyy"
                    ),
                    isAptitudeTest: d.rankConditions.isAptitudeTest
                      ? "Oui"
                      : "Non",
                    isFormation: d.rankConditions.isFormation ? "Oui" : "Non",
                    nbMission: d.rankConditions.nbMissionsFinished,
                    nbSurvey: d.rankConditions.nbSurveysFinished,
                    android: d.devices?.android.length,
                    ios: d.devices?.ios.length,
                    balance: d?.balance.filter(
                      (b) => b.paymentStatus == "DONE"
                    )[0]
                      ? d?.balance.filter((b) => b.paymentStatus == "DONE")[0]
                          ?.amount / 100
                      : 0,
                  })),
                excludedFields: ["Actions", "Statut IBAN"],
                additionalFields: [
                  { name: "Date naiss", path: "birthdate" },
                  { name: "Téléphone", path: "phone" },
                  { name: "Adresse", path: "address" },
                  { name: "IBAN", path: "iban" },
                  { name: "Adresse", path: "location" },
                  { name: "Activé ?", path: "isActivated" },
                  { name: "Test d'aptitude ?", path: "isAptitudeTest" },
                  { name: "Formation ?", path: "isFormation" },
                  { name: "Nb constats", path: "nbMission" },
                  { name: "Nb sondages", path: "nbSurvey" },
                  { name: "Appareils android", path: "android" },
                  { name: "Appareils ios", path: "ios" },
                ],
              }}
            />
          )}
          <Pagination
            nbPages={nbPages}
            page={search.page}
            changePage={changePage}
          />
        </div>
      </div>
    </Page>
  );
};

export default UserList;
