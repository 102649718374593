import React, { useEffect, useState } from "react";
import dataService from "../../../../helpers/dataService";
import Loader from "../../../common/Loader";
import ReportAnswers from "./ReportAnswers/ReportAnswers";
import ReportFile from "./ReportFile/ReportFile";
import ReportParticipations from "./ReportParticipations/ReportParticipations";
import ReportPhotos from "./ReportPhotos/ReportPhotos";

const Report = ({ mission, tab }) => {
  const [report, setReport] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [panelsVisible, setPanelsVisible] = useState([
    "participations",
    "answers",
    "photos",
  ]);

  useEffect(() => {
    getReport();
  }, []);

  useEffect(() => {
    if (tab == "report" && report) {
      getReport();
    }
  }, [tab]);

  const getReport = () => {
    if (mission.isInternal) {
      dataService.get(
        `missions/${mission.id}/report/rogue`,
        setReport,
        setErrors,
        () => setIsLoading(false)
      );
    } else {
      dataService.get(
        `missions/${mission.id}/report`,
        setReport,
        setErrors,
        () => setIsLoading(false)
      );
    }
  };

  const togglePanel = (panel) => {
    if (panelsVisible.includes(panel)) {
      setPanelsVisible(panelsVisible.filter((f) => f !== panel));
    } else {
      setPanelsVisible([...panelsVisible, panel]);
    }
  };

  var errorsArr = Object.values(errors);

  return isLoading ? (
    <Loader />
  ) : (
    <div>
      {errorsArr.length > 0 && (
        <div className="alert alert-danger">{errorsArr}</div>
      )}
      {report.sections?.length > 0 && (
        <>
          <button
            className="btn btn-default w-100 mt-2 font-weight-bold border no-print"
            onClick={() => togglePanel("participations")}
          >
            Participations
            <i className="fa fa-caret-down ml-2" />
          </button>
          {panelsVisible.includes("participations") && (
            <ReportParticipations report={report} />
          )}

          <button
            className="btn btn-default w-100 mt-2 font-weight-bold border"
            onClick={() => togglePanel("answers")}
          >
            Réponses
            <i className="fa fa-caret-down ml-2" />
          </button>
          {panelsVisible.includes("answers") && (
            <ReportAnswers mission={mission} report={report} />
          )}

          <button
            className="btn btn-default w-100 mt-2 font-weight-bold border no-print"
            onClick={() => togglePanel("photos")}
          >
            Photos
            <i className="fa fa-caret-down ml-2" />
          </button>
          {panelsVisible.includes("photos") && (
            <ReportPhotos
              missionId={mission.id}
              isInternal={mission.isInternal}
            />
          )}

          <div className="no-print">
            <ReportFile missionId={mission.id} />
          </div>
        </>
      )}
    </div>
  );
};

export default Report;
