import React from "react";
import Control from "../../../common/Control";

const AnswerInterpretor = ({
  question,
  inputTypes,
  updateAnswer,
  answers,
  k
}) => {
  var content = <></>;

  content = (
    <Control
      label=""
      name={question._id}
      id={k}
      type={inputTypes.find(i => i.id == question.type).type}
      datas={question.answers}
      dataIndex={"value"}
      dataLabel={"label"}
      inputParams={question.inputParams}
      change={updateAnswer}
      value={answers.find(a => a.question == question._id).value}
    />
  );

  return content;
};

export default AnswerInterpretor;
