import React, { useState, useEffect } from "react";
import tools from "../../../helpers/tools";
import Control from "../../common/Controls/Control";
import QuestionImagesUploader from "../QuestionDetail/QuestionImagesUploader";
import FormQuestionImagesUploader from "./FormQuestionImagesUploader";
const QuestionEditor = ({
  THEMES,
  INPUT_TYPES,
  updateQuestion,
  updateQuestionAnswer,
  updateInputParams,
  qk,
  q,
  questions,
  formId,
  updateQuestionAnswerScore,
  products,
}) => {
  const [ands, setAnds] = useState([]);
  const [alreadySelected, setAlreadySelected] = useState([]);
  const [productClicked, setProductClicked] = useState(false);
  let configInputParams = [];

  useEffect(() => {
    if (q.condition != "") {
      setAnds(q.condition.split("&&"));
    } else {
      setAnds([]);
    }
    let al = [];
    ands.forEach((el) => {
      var om = el.split("==");
      var lm = om[0];
      var lq = lm.split("-")[1];
      al.push(lq);
    });
    setAlreadySelected(al);
  }, [q]);

  function addCondition() {
    var ques = JSON.parse(JSON.stringify(q));
    if (ques.condition == "") {
      ques.condition = "q-==";
    } else {
      ques.condition += "&&q-==";
    }

    updateQuestion({
      target: { name: "condition", value: ques.condition, k: qk },
    });
  }
  function removeCondition(andk) {
    var andsl = JSON.parse(JSON.stringify(ands));
    andsl = andsl.filter((a, k) => k != andk);

    andsl = andsl.join("&&");

    if (andsl == "") setAnds([]);

    updateQuestion({ target: { name: "condition", value: andsl, k: qk } });
  }
  function changeQCondition(e, andk) {
    var andsl = JSON.parse(JSON.stringify(ands));

    andsl[andk] = "q-" + e.target.value + "==";

    andsl = andsl.join("&&");

    updateQuestion({ target: { name: "condition", value: andsl, k: qk } });
  }
  function changeVCondition(e, andk) {
    var andsl = JSON.parse(JSON.stringify(ands));

    andsl[andk] = andsl[andk].split("==");
    andsl[andk][1] = e.target.value;
    andsl[andk] = andsl[andk].join("==");
    andsl = andsl.join("&&");
    console.log("andsl", andsl);
    updateQuestion({ target: { name: "condition", value: andsl, k: qk } });
  }
  for (let key in q.inputParams) {
    configInputParams.push(
      <Control
        type="text"
        name={"inparams" + key}
        key={key}
        label={key}
        value={q.inputParams[key]}
        change={(e) => updateInputParams(e, qk, key)}
      />
    );
  }

  const getSelectedConditionalQuestion = (qsel) => {
    var multipleQuestions = questions.filter((qi, qik) => {
      return (
        qi.initialOrder == qsel &&
        qik < qk &&
        parseInt(qi.section) <= parseInt(q.section) &&
        ["2", "3", "4"].includes("" + qi.type)
      );
    });
    console.log("multipleQuestions", multipleQuestions);
    return multipleQuestions[multipleQuestions.length - 1];
  };

  return (
    <>
      <ul className="nav nav-tabs mt-4">
        <li className="nav-item">
          <a
            className="nav-link active"
            id={"eparameters-tab" + qk}
            data-toggle="tab"
            href={"#eparameters" + qk}
            role="tab"
            aria-controls={"eparameters" + qk}
          >
            Paramètres
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link "
            id={"econditions-tab" + qk}
            data-toggle="tab"
            href={"#econditions" + qk}
            role="tab"
            aria-controls={"econditions" + qk}
          >
            Conditions
          </a>
        </li>
      </ul>
      <div className="tab-content" id={"myEditContent" + qk}>
        <div
          className="tab-pane fade  show active"
          id={"eparameters" + qk}
          role="tabpanel"
          aria-labelledby={"eparameters-tab" + qk}
        >
          <>
            <div className="form-group animated fadeInUp fast mb-0 ">
              <label className="border-0 bg-light p-3" style={{ minHeight: 0 }}>
                Thème : {tools.getSubThemeFromId(THEMES, q.subTheme)}
              </label>
            </div>
            <div className="form-group animated fadeInUp fast mb-0">
              <label className="border-0 bg-light p-3" style={{ minHeight: 0 }}>
                Type : {INPUT_TYPES.find((t) => t.id == q.type).name}
              </label>
            </div>
            <Control
              label="Texte de la question"
              type="text"
              className={"animated fadeInUp fast"}
              name="question"
              value={q.question}
              k={qk}
              change={updateQuestion}
            />
            <Control
              className={"animated fadeInUp fast"}
              type="text"
              label="Description"
              name="description"
              value={q.description}
              k={qk}
              change={updateQuestion}
            />
            <Control
              className={"animated fadeInUp fast"}
              label="La question compte pour la note globale du constat"
              type="checkbox"
              name="isScoring"
              checked={q.isScoring}
              k={qk}
              change={updateQuestion}
            />
            {q.isScoring && (
              <Control
                label="Coefficient"
                value={q.coef}
                type="btnList"
                btnInline={true}
                dataLabel="name"
                dataIndex="id"
                k={qk}
                name="coef"
                datas={[
                  { id: 1, name: "1 - Neutre" },
                  { id: 2, name: "2 - Pertinant" },
                  { id: 3, name: "3 - Important" },
                  { id: 4, name: "4 - Très important" },
                  { id: 5, name: "5 - Capital" },
                ]}
                change={updateQuestion}
              />
            )}
            <button
              type="button"
              className="btn btn-default d-inline-flex"
              onClick={() => setProductClicked(!productClicked)}
            >
              <i className="fas fa-link mr-2" />
              Associer un produit
            </button>
            {productClicked && (
              <Control
                type="autocomplete"
                name="product"
                label="Sélectionner un produit"
                datas={products}
                value={q.product}
                k={qk}
                change={updateQuestion}
                autoCompleteDisplayIndex="name"
                dataLabel2="category.name"
              />
            )}
            <h4>Réponses</h4>
            {q.answers.map((a, ak) => (
              <div key={`qans${q.id}${qk}${ak}`}>
                <Control
                  type="text"
                  className={""}
                  name={"anslabel" + ak + qk}
                  k={qk}
                  label={a.value}
                  value={a.label}
                  change={(e) => updateQuestionAnswer(e, ak)}
                />
                <Control
                  label="Score par défaut sur l'échelle"
                  type="btnList"
                  btnInline={true}
                  name={"ansscore" + ak + qk}
                  forceValue={true}
                  datas={[
                    { id: 1, name: "Très mauvais" },
                    { id: 2, name: "Mauvais" },
                    { id: 3, name: "Correct" },
                    { id: 4, name: "Très bien" },
                    { id: 5, name: "Excellent" },
                  ]}
                  dataLabel="name"
                  dataIndex="id"
                  value={a.score}
                  k={qk}
                  change={(e) => updateQuestionAnswerScore(e, ak)}
                />
              </div>
            ))}

            {configInputParams}

            <h4>Image de la question</h4>
            {q._id ? (
              <FormQuestionImagesUploader
                question={q}
                missionFormId={formId}
                updateQuestionImage={(image) =>
                  updateQuestion({
                    target: { name: "image", value: image, k: qk },
                  })
                }
              />
            ) : (
              <p>
                Enregistrez d'abord la question avant de pouvoir envoyer des
                images
              </p>
            )}
          </>
        </div>
        <div
          className="tab-pane fade "
          id={"econditions" + qk}
          role="tabpanel"
          aria-labelledby={"econditions-tab" + qk}
        >
          <p className="py-2 text-primary">
            N'afficher cette question que si les conditions suivantes sont
            remplies :{" "}
          </p>
          {ands.map((and, andk) => {
            var ops = and.split("==");
            var left = ops[0];
            var qsel = left.split("-")[1];
            var right = ops[1];

            return (
              <div className="row mt-2" key={"andsops" + andk}>
                <div className="col-12">
                  <select
                    key={"qcond" + qk + andk}
                    className="form-control"
                    value={qsel}
                    onChange={(e) => changeQCondition(e, andk)}
                  >
                    <option value="">...</option>
                    {questions.map((qi, qik) => {
                      if (
                        qik < qk &&
                        parseInt(qi.section) <= parseInt(q.section) &&
                        ["2", "3", "4"].includes("" + qi.type) &&
                        (!alreadySelected.includes(
                          qi.initialOrder.toString()
                        ) ||
                          qsel == qi.initialOrder.toString())
                      ) {
                        return (
                          <option
                            key={"questionCond" + qk + qik}
                            value={qi.initialOrder}
                          >
                            {qi.question}
                          </option>
                        );
                      }
                    })}
                  </select>
                </div>
                <div className="col-6 mt-2">
                  <span style={{ lineHeight: "40px" }}>est égale à</span>
                </div>
                <div className="col-4 mt-2">
                  <select
                    key={"vcond" + qk + andk}
                    className="form-control"
                    value={right}
                    onChange={(e) => changeVCondition(e, andk)}
                  >
                    <option value="">...</option>
                    {qsel != "" &&
                      getSelectedConditionalQuestion(qsel) &&
                      getSelectedConditionalQuestion(qsel).answers.map(
                        (ans, ansk) => (
                          <option
                            key={"vcond" + qk + andk + ansk}
                            value={ans.value}
                          >
                            {ans.label}
                          </option>
                        )
                      )}
                  </select>
                </div>
                <div className="col-12 border-bottom">
                  <button
                    className="btn btn-outline-danger py-0 px-3 h-100"
                    onClick={() => removeCondition(andk)}
                  >
                    <i className="fa fa-times mr-2" />
                    supprimer la condition
                  </button>
                </div>
              </div>
            );
          })}
          <button className="btn mt-3" onClick={addCondition}>
            <i className="fa fa-plus mr-2"></i>
            Ajouter une condition
          </button>
        </div>
      </div>
    </>
  );
};

export default QuestionEditor;
