import React from "react";
import tools from "../../../helpers/tools";
import Control from "../../common/Controls/Control";

const MissionListFilters = ({ search, setSearch, prospects, userRole }) => {
  console.log(search.mission.missionType);
  return (
    <div className="row ">
      <div className="col-12 p-0">
        <Control
          label="Statut"
          value={search.mission.time}
          type="btnList"
          btnInline
          datas={[
            { id: "progress", name: "En cours" },
            { id: "futur", name: "Programmé" },
            { id: "finished", name: "Terminé" },
          ]}
          change={(e) =>
            setSearch({
              ...search,
              mission: { ...search.mission, time: e.target.value },
            })
          }
        />
      </div>
      {/* <div className="col-12 col-md-6 p-0">
        <Control
          label="Entre le"
          type="date"
          value={search.mission.beginAt}
          change={(e) =>
            setSearch({
              mission: { ...search.mission, beginAt: e.target.value },
            })
          }
        />
      </div>
      <div className="col-12 col-md-6 p-0">
        <Control
          label="Et le"
          type="date"
          value={search.mission.endAt}
          change={(e) =>
            setSearch({
              mission: { ...search.mission, endAt: e.target.value },
            })
          }
        />
      </div> */}
      <div className="col-12 col-md-4 p-0">
        <Control
          label="Type"
          type="btnList"
          value={search.mission.missionType}
          name="missionType"
          datas={[
            { id: -1, name: "Tous" },
            { id: 0, name: "Constats" },
            { id: 1, name: "Sondages" },
          ]}
          dataIndex="id"
          dataLabel="name"
          btnInline
          change={(e) =>
            setSearch({
              ...search,
              mission: {
                ...search.mission,
                missionType: e.target.value,
              },
            })
          }
        />
      </div>
      <div className="col-12 col-md-4 p-0">
        <Control
          label="Statut"
          type="btnList"
          value={search.mission.status}
          datas={[
            { id: 1, name: "Brouillon" },
            { id: 2, name: "Actif" },
          ]}
          btnInline
          change={(e) =>
            setSearch({
              ...search,
              mission: { ...search.mission, status: e.target.value },
            })
          }
        />
      </div>
      <div className="col-12 col-md-4 p-0">
        <Control
          label="Prospect"
          type="select"
          value={search.mission.userProspect}
          datas={prospects}
          name="userProspect"
          dataLabel="companyName"
          change={(e) =>
            setSearch({
              ...search,
              mission: { ...search.mission, userProspect: e.target.value },
            })
          }
          className="h-100"
          labelClassname="h-100"
        />
      </div>
      <div className="col-12 col-md4 p-0">
        <Control
          label="Recherche"
          type="text"
          name="searchString"
          value={search.mission.searchString}
          change={(e) =>
            setSearch({
              ...search,
              mission: { ...search.mission, searchString: e.target.value },
            })
          }
          suffix={
            <i className="fa fa-search ml-2" style={{ lineHeight: "40px" }}></i>
          }
        />
      </div>
      {userRole !== "ROGUE_ADMIN" && (
        <>
          <div className="col-12 col-md-4 p-0">
            <Control
              label="Afficher les templates"
              type="checkbox"
              name="isTemplate"
              checked={search.mission.isTemplate}
              change={(e) =>
                setSearch({
                  ...search,
                  mission: { ...search.mission, isTemplate: e.target.checked },
                })
              }
            />
          </div>
          <div className="col-12 col-md-4 p-0">
            <Control
              label="Afficher les annulés"
              type="checkbox"
              name="isDeleted"
              checked={search.mission.isDeleted}
              change={(e) =>
                setSearch({
                  ...search,
                  mission: { ...search.mission, isDeleted: e.target.checked },
                })
              }
            />
          </div>
          <div className="col-12 col-md-4 p-0">
            <Control
              label="Afficher les missions internes"
              type="checkbox"
              name="isInternal"
              checked={search.mission.isInternal}
              change={(e) =>
                setSearch({
                  ...search,
                  mission: { ...search.mission, isInternal: e.target.checked },
                })
              }
            />
          </div>
        </>
      )}
    </div>
  );
};

export default MissionListFilters;
