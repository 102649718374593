import React, { useEffect, useState } from "react";
import Control from "../../common/Controls/Control";
import SaveBtn from "../../common/SaveBtn";

const FaqForm = ({ saveFaq, form, categories }) => {
  const [category, setCategory] = useState(form.category);
  const [title, setTitle] = useState(form.title);
  const [description, setDescription] = useState(form.description);

  const save = () => {
    saveFaq({
      ...form,
      title,
      description,
      category,
    });
  };

  return (
    <div>
      <Control
        label="Catégorie"
        type="select"
        value={category}
        change={(e) => setCategory(e.target.value)}
        datas={categories}
      />
      <Control
        label="Titre de la FAQ"
        type="text"
        value={title}
        change={(e) => setTitle(e.target.value)}
      />
      <div className="row form-group">
        <label htmlFor={`faq-${title}`} className="form-label">Description</label>
        <textarea
          id={`faq-${title}`}
          className="form-control my-0 mx-auto"
          rows="8"
          onChange={(e) => setDescription(e.target.value)}
          defaultValue={description}
        >
        </textarea>
      </div>

      <SaveBtn save={save} isSaving={false} />
    </div>
  );
};

export default FaqForm;
