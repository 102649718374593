import React from "react";
import { image_url } from "../../../config";
import useApiDatas from "../../common/Hooks/useApiDatas";
import Loader from "../../common/Loader";

const AnswersViewer = ({ mission, answers }) => {
  console.log("miss", mission, "answers", answers);
  const { isInit, otherDatas } = useApiDatas({
    dependenciesPath: [`missions/${mission.id}/form`],
  });

  const form = otherDatas[`missions/${mission.id}/form`];

  const getAnswer = (question) => {
    var ans = answers[question._id];
    console.log(question, ans);
    switch (question.type) {
      case 1:
        return [ans];

      case 2:
        return ans.map((a) =>
          question.answers.find((an) => an.value == a)
            ? question.answers.find((an) => an.value == a).label
            : "N/A"
        );

      case 3:
        return ans.map((a) =>
          question.answers.find((an) => an.value == a)
            ? question.answers.find((an) => an.value == a).label
            : "N/A"
        );

      case 4:
        return ans.map((a) =>
          question.answers.find((an) => an.value == a)
            ? question.answers.find((an) => an.value == a).label
            : "N/A"
        );

      case 5:
        return [ans];

      case 6:
        return ans.map((a) => [ans + " / " + question.inputParams.max]);

      case 7:
        return ans.map((a) => (
          <img src={image_url + a.fileName} className="form-image" />
        ));
      default:
        return [""];
    }
  };

  return !isInit ? (
    <Loader />
  ) : (
    form.questions.map((q, qk) => (
      <div key={"ansfk" + qk}>
        <div className="text-success d-flex flex-row align-items-center mb-0 font-weight-bold">
          <div
            className="bg-success text-white d-center mr-2"
            style={{
              width: 30,
              height: 30,
              borderRadius: "50%",
            }}
          >
            {qk + 1}
          </div>
          {q.question}
        </div>
        <div className="d-flex flex-wrap flex-row">
          {getAnswer(q).map((aq, aqk) => (
            <div className="d-center flex-row p-2" key={"ansfk" + qk + aqk}>
              <i className="fa fa-check mr-2 text-success" />
              {aq}
            </div>
          ))}
        </div>
        <hr />
      </div>
    ))
  );
};

export default AnswersViewer;
